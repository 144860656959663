import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';

import Account from "./components/account";
import Company from "./components/company";
import Authorization from "./components/authorization";
import Interface from "./components/interface";

import {COLORS} from "../../../config/constants";

import './settings.scss'
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ModalRemoveAccount from './components/remove-account';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  title: string;
}

const Settings = () => {

  const { t } = useTranslation()

  const [ openRemoveModal, setOpenRemoveModal ] = useState(false);

  const [ tabValue, setTabValue ] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  const SupportInfo = () => <Card sx={{
    marginTop: 4,
  }} className='support-info'>
    <CardContent sx={{ padding: '24px' }}>
      <Typography
        variant='h6'
        sx={{
          marginBottom: 3,
        }}
      >
        {t('menu__text__support')}
      </Typography>
      <div className='support-info__user'>
        <Avatar sx={{ bgcolor: COLORS.DARK_GRAY }}>ОР</Avatar>
        <div className='support-info__userName'>
          <Typography variant='subtitle1'>
             Иван Константинов
          </Typography>
          <Typography variant='subtitle2' sx={{ color: COLORS.LIGHT_GRAY, fontWeight: 400 }}>
             Менеджер
          </Typography>
        </div>
      </div>
      <Typography variant='subtitle1' sx={{
        marginTop: 3,
      }}>
        <Link href='tel:+79991234567'>+7 (999) 123-45-67</Link>
      </Typography>
      <Typography variant='subtitle1'>
        <Link href='mailto:petr@ivanov.ru'>petr@ivanov.ru</Link>
      </Typography>
    </CardContent>
  </Card>

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, title, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
        className='settings-card'
      >
        {value === index && (
          <Card sx={{
            marginTop: 4,
            marginBottom: 4
          }}>
            <CardContent sx={{ '&:last-child': { paddingBottom: 4 } }}>
              <Typography variant='h5' sx={{
                marginBottom: 4
              }}>
                {title}
              </Typography>
              <div className='settings-card__content'>
                {children}
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }

  return (
    <>
      <div className='settings'>
        <Typography variant='h4' sx={{
          marginBottom: 3.5
        }}>
          {t('menu__text__settings')}
        </Typography>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: `1px solid ${COLORS.SLIGHTLY_GRAY}`}}>
          <Tab label={t('common__text__account')} />
          <Tab label={t('settings__text__companyProfile')} />
          <Tab label={t('common__text__authorization')} />
          <Tab label={t('common__text__interface')} />
        </Tabs>
        <div className='settings__wrapper'>
          <div>
            <CustomTabPanel value={tabValue} index={0} title={t('common__text__account')}>
              <Account />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1} title={t('settings__text__companyProfile')}>
              <Company />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2} title={t('common__text__authorization')}>
              <Authorization />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3} title={t('common__text__interface')}>
              <Interface />
            </CustomTabPanel>
            <IconButton sx={{ marginBottom: 3, padding: 0, '&:hover, &:focus': { background: 'none', borderRadius: '8px'}  }} onClick={() => setOpenRemoveModal(true)}>
              <DeleteIcon />
              <Typography variant='subtitle2' sx={{ color: COLORS.LIGHT_GRAY, fontWeight: 400, paddingLeft: '4px' }}>
                {t('actions__text__removeAccount')}
              </Typography>
            </IconButton>
          </div>
          <SupportInfo />
        </div>
      </div>
      <ModalRemoveAccount onClose={() => setOpenRemoveModal(false)} isOpen={openRemoveModal}/>
    </>
  )
}

export default Settings
