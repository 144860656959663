import React from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

import {COLORS} from "../../../../../../../config/constants";
import { PersonFilled } from "../../../../../../../assets/svg";

import Completed from "../../../../../../common/completed-new";
import {useUserBrokerService} from "../../../../../../../api/calc";

import './broker-commission.scss';

const BrokerCommission = () => {

  const { t } = useTranslation()

  const userBrokerService = useUserBrokerService()

  const getBrokerCommission = () => {

    if (!userBrokerService?.commission){
      return null;
    }

    const completedInfo = [
      {
        label: t('calc__text__bankCommissions__commission'),
        value: `${userBrokerService?.commission} USD`
      }
    ]
    return <Completed
      info={completedInfo}
      isFullRow
      caption={t('calc__text__customs__brokerCommission')}
    />
  }

  return (
    <div className='customs'>
      <Accordion sx={{
        marginBottom: 0,
        border: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
        boxShadow: 'none',
      }}
      defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          sx={{padding: '10px 28px 12px'}}
        >
          <div className='customs-title'>
            <div className='customs-title__left'>
              <PersonFilled color={COLORS.SLIGHTLY_GRAY}/>
              <Typography variant='h6' className='customs-title__name'>
                {t('calc__text__customs__customsBrokerCommission')}
              </Typography>
            </div>
            <div className='customs-title__right'>
              <Typography variant='h6'>
                {userBrokerService?.commission ? `${userBrokerService?.commission} USD` : ''}
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='additional__details'>
          {getBrokerCommission()}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default BrokerCommission
