import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import sortBy from "lodash/sortBy";

import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {
  useDeclarationsByOrderIdQuery,
  useDeclarationSummaryByOrderIdQuery, useGetAdditionalServicesByOrderIdQuery, useGetOrderSummaryInvoicesByIdQuery,
  useInvoicesByOrderIdQuery, useRemoveProductMutation, useSummary, useSummaryByOrderIdQuery, useProductsByInvoiceIdQuery,
} from "../../../../../../../../../../../api/calc";

import ModalBig from "../../../../../../../../../../common/modalBig";
import ModalAction from "../../../../../../../../../../common/modalAction";
import Completed from "../../../../../../../../../../common/completed-new";

import {useGetCalcIdFromRoute} from "../../../../../../../../../../../utils/hooks";
import {getFlag} from "../../../../../../../delivery/helpers";

import AddForm from "./components/add_form";

import './products.scss';

type Props = {
    openByDefault?: boolean
    invoice?: InvoiceParamsOutput
    productCount: number
}

const Products = ({openByDefault, invoice, productCount} : Props) => {

  const {t} = useTranslation()

  const {data: products} = useProductsByInvoiceIdQuery(invoice?.id || 0, {skip: !invoice?.id})

  const [ showAddForm, setShowAddFrom ] = useState<boolean | undefined>(false);

  const [ openEditModal, setOpenEditModal ] = useState(false);

  const [ currentProductId, setCurrentProductId ] = useState<number>();

  const [ shouldOpenRemoveModal, setShouldOpenRemoveModal ] = useState(false)

  const [ shouldRefetchCustoms, setShouldRefetchCustoms ] = useState(true);

  const [ removeProduct ] = useRemoveProductMutation()

  const orderId = useGetCalcIdFromRoute()

  const summaryOrder = useSummary();

  const isPackagingCompleted = Boolean(summaryOrder?.delivery_type);

  const {data: invoices, isSuccess} = useInvoicesByOrderIdQuery(orderId, {skip: !orderId})
  const {refetch: refetchInvoices} = useInvoicesByOrderIdQuery(orderId, {skip: !orderId})
  const {refetch: refetchDeclaration} = useDeclarationsByOrderIdQuery(orderId, {skip: !shouldRefetchCustoms})
  const {refetch: refetchSummaryDeclaration} = useDeclarationSummaryByOrderIdQuery(orderId, {skip: !shouldRefetchCustoms})
  const {refetch: refetchSummaryPackaging} = useSummaryByOrderIdQuery(orderId, {skip: !shouldRefetchCustoms})
  const {refetch: refetchOrderSummary} = useGetOrderSummaryInvoicesByIdQuery(orderId, {skip: !orderId})
  const {refetch: refetchAdditionalServices} = useGetAdditionalServicesByOrderIdQuery(orderId, {skip: !orderId})
  const {refetch: refetchProductsByInvoiceId} = useProductsByInvoiceIdQuery(invoice?.id || 0, {skip: !invoice?.id})

  useEffect(() => {
    if (!openByDefault) {
      setShowAddFrom(!invoices);
    }
  }, [ isSuccess ])

  useEffect(() => {
    setShowAddFrom(!products?.length && Boolean(invoice?.id));
  }, [ invoice, products ])

  const getCompletedInfoProduct = (product: ProductParamsOutput) => {

    return [
      {
        label: t('common__text__quantity'),
        value: `${product.pieces} ${t('units__text__items')}`
      },
      {
        label: t('common__text__netWeightPackaging'),
        value: `${product.net_weight_cartoon} ${t('units__text__kg')}`
      },
      {
        label: t('common__text__pricePerPiece'),
        value: `${product.unit_price} USD`
      },
      {
        label: t('common__text__grossPackagingWeight'),
        value: `${product.gross_weight_cartoon} ${t('units__text__kg')}`
      },
      {
        label: t('common__text__totalCost'),
        value: `${product.price_total} USD`
      },
    ]

  }

  const onEdit = (id: number) => {
    setCurrentProductId(id);
    setOpenEditModal(true)
  }

  const removeItem = (productId?: number | string) => {
    if (productId && invoice?.id) {
      removeProduct({
        invoiceId: invoice?.id,
        productId: Number(productId)
      }).then(async () => {
        setShouldOpenRemoveModal(false)
        refetchProductsByInvoiceId()
        refetchInvoices()
        setShouldRefetchCustoms(true);

        // if (productsCount === 1) {
        //   await saveSummary({
        //     orderId,
        //     delivery_type: null,
        //     isEmpty: true,
        //     containers_manual: {},
        //     containers_user: {}
        //   })
        //
        //   await addServices({
        //     orderId,
        //     route: null,
        //     services: [],
        //   });
        //
        //   setCompleted({
        //     orderId,
        //     packaging: false,
        //     delivery: false,
        //     customs: false,
        //     additional_services: false
        //   })
        // }


        if (isPackagingCompleted) {
          refetchDeclaration()
          refetchSummaryDeclaration()
          refetchSummaryPackaging()
          refetchOrderSummary()
          refetchAdditionalServices()
        } else {
          refetchSummaryDeclaration()
        }
      })

    }
  }

  const onRemove = (id: number) => {
    setCurrentProductId(id)
    setShouldOpenRemoveModal(true)
  }

  const handleCloseAddForm = () => {
    setShowAddFrom(false)
    setOpenEditModal(false)
  }

  const handleSuccessAddedProduct = () => {
    refetchInvoices()
    refetchProductsByInvoiceId();
    setShouldRefetchCustoms(true);
    setShowAddFrom(false);

    if (isPackagingCompleted) {
      refetchDeclaration()
      refetchSummaryDeclaration()
      refetchSummaryPackaging()
      refetchOrderSummary()
    }
  }

  const sortedProducts = sortBy(products, 'id');

  const findProductNameById = (productId?: number) => {

    if (!products || !productId) {
      return;
    }

    // @ts-ignore
    return products?.find((product) => product.id === Number(productId)).name;
  }

  const handleRemove = () => {
    setOpenEditModal(false);
    setShouldOpenRemoveModal(true);
  }

  const getCompletedCaption = (product: ProductParamsOutput, index: number) =>
    <span className='invoice__flag-info'>
      {getFlag({code: product.country_data.code})} {index + 1}. {product.name}
    </span>

  const shouldShowAddButton = Boolean(invoice?.id) && !showAddForm;

  return (
    <div className='products'>
      {(productCount > 0 || showAddForm) && (
        <Typography variant='h6' sx={{marginTop: 3, marginBottom: 3}}>
          {t('common__text__products')}
          {productCount > 0 && <span className='invoice__product-count'>{productCount}</span>}
        </Typography>
      )}
      {sortedProducts?.map((product, index) => (
        <div className='products__item-completed' key={product.id}>
          <Completed
            info={getCompletedInfoProduct(product)}
            onEdit={() => onEdit(product.id)}
            onRemove={() => onRemove(product.id)}
            caption={getCompletedCaption(product, index)}
          />
        </div>
      ))}
      {showAddForm && (<div className='products__add-form'>
        <AddForm
          handleRemove={() => setShowAddFrom(false)}
          invoice={invoice}
          handleSuccess={handleSuccessAddedProduct}
        />
      </div>)}
      {shouldShowAddButton && (
        <div className='products__add-button'>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => setShowAddFrom(true)}
          >
            <AddIcon sx={{marginRight: 1}}/>
            {t('actions__text__addItem')}
          </Button>
        </div>
      )}
      {openEditModal && <ModalBig
        isOpen
        onClose={() => setOpenEditModal(false)}
        title={t('actions__text__editItem')}
      >
        <AddForm
          invoice={invoice}
          handleSuccess={handleCloseAddForm}
          id={currentProductId}
          handleRemove={handleRemove}
          insideModal
        />
      </ModalBig>}
      {shouldOpenRemoveModal && <ModalAction
        isOpen={shouldOpenRemoveModal}
        onContinue={removeItem}
        index={currentProductId}
        onClose={() => setShouldOpenRemoveModal(false)}
        title={t('common__text__deleteItem')}
        description={<>
          {t('actions__message__removeConfirm')}&nbsp;
          <span className='text_bold'>{`«${findProductNameById(currentProductId)}»?`}</span>
        </>}
        buttonYesText={t('actions__text__yesDelete')}
      />}
    </div>
  )
}

export default Products
