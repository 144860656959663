import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from 'config/constants'
import {getToken} from "./auth";

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const token = getToken()
    headers.set('authorization', `Bearer ${token}`)
    headers.set('Accept-Language', 'ru-RU,ru,en-US;q=0.8,en;q=0.7')
    return headers
  }
})
