import React, {useState, useEffect} from "react";

import { useForm, FormProvider } from "react-hook-form";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {
  checkDecimal, checkEmail,
  checkOnlyEnglishCharacters,
  checkOnlyRussianCharacters, RUSSIA_PHONE_NUMBER_REGEXP, RUSSIA_COUNTRY_NAME
} from '../../../../../../utils/validation';
import {
  useTransportCompaniesById,
  useChangeTransportCompanyMutation,
  useCreateTransportCompanyMutation,
  useLazyGetTransportCompanyByIdQuery
} from "../../../../../../api/dictionary";
import ModalAdd from "../../../components/modal_add";
import Remove from './../remove';

import './add.scss';
import PhoneInput, {CountryData} from 'react-phone-input-2'
import ru from 'react-phone-input-2/lang/ru.json'
import FileUpload from "./components/file-upload";
import {ROUTES} from "../../../../../../config/constants";


type Props = {
    isOpen: boolean
    isEdit: boolean
    id?: string
    onClose(): void
    onRemoved(isSuccess: boolean): void
    onAddedSuccess(): void
    handleTransportCompaniesRequest(data?: DictionaryParamsInput): Promise<any>
}

type PropsSendData = {
    isClosed?: boolean
}

export enum MODAL_ADD_FORM {
    NAME = 'name',
    NAME_EN = 'name_en',
    CODE = 'code',
    INN = 'inn',
    ADDRESS = 'address',
    EMAIL = 'email',
    PHONE = 'phone',
}

export type ModalAddParamsTransportCompany = {
    [MODAL_ADD_FORM.NAME]: string
    [MODAL_ADD_FORM.NAME_EN]: string
    [MODAL_ADD_FORM.CODE]: string
    [MODAL_ADD_FORM.INN]: string,
    [MODAL_ADD_FORM.ADDRESS]: string,
    [MODAL_ADD_FORM.EMAIL]: string,
    [MODAL_ADD_FORM.PHONE]: string
}

const Add = ({isOpen, onClose, isEdit, handleTransportCompaniesRequest, id, onAddedSuccess, onRemoved }: Props) => {

  const { t } = useTranslation()

  const [ transportCompaniesByIdRequest, {data: transportCompany} ] = useLazyGetTransportCompanyByIdQuery()

  useEffect(() => {
    if (id){
      transportCompaniesByIdRequest(id);
    }
  }, [ ])

  const transportCompanyInfo = useTransportCompaniesById(id);

  const [ errorNotification, setErrorNotification ] = useState(false);

  const [ logoId, setLogoId ] = useState<number | undefined>();

  const [ createTransportCompany ] = useCreateTransportCompanyMutation()
  const [ changeTransportCompanyRequest ] = useChangeTransportCompanyMutation()

  const methods = useForm<ModalAddParamsTransportCompany>({
    defaultValues: {
      [MODAL_ADD_FORM.NAME]: isEdit ? transportCompanyInfo?.[MODAL_ADD_FORM.NAME] : undefined,
      [MODAL_ADD_FORM.NAME_EN]: isEdit ? transportCompanyInfo?.[MODAL_ADD_FORM.NAME_EN] : undefined,
      [MODAL_ADD_FORM.CODE]: isEdit ? transportCompanyInfo?.[MODAL_ADD_FORM.CODE] : undefined,
      [MODAL_ADD_FORM.INN]: isEdit ? transportCompanyInfo?.[MODAL_ADD_FORM.INN] : undefined,
      [MODAL_ADD_FORM.ADDRESS]: isEdit ? transportCompanyInfo?.[MODAL_ADD_FORM.ADDRESS] : undefined,
      [MODAL_ADD_FORM.EMAIL]: isEdit ? transportCompanyInfo?.[MODAL_ADD_FORM.EMAIL] : undefined,
      [MODAL_ADD_FORM.PHONE]: isEdit ? transportCompanyInfo?.[MODAL_ADD_FORM.PHONE] : undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    getValues,
    formState: {errors, isValid},
  } = methods;

  const errorDecimal = t('errors__message__incorrectValue');
  const errorRussianCharacters = t('errors__message__russianCharactersOnly');
  const errorEnglishCharacters = t('errors__message__englishCharactersOnly');
  const errorEmail = t('errors__message__incorrectEmail');

  useEffect(() => {
  }, [ isOpen ])


  const handleSendData = ({isClosed}: PropsSendData) => {

    const onSubmit = async (values: TransportCompanyDictionary) => {

      let response: any;

      if (isEdit) {

        if (!id) {
          return;
        }

        // @ts-ignore
        response = await changeTransportCompanyRequest({
          name: values.name,
          name_en: values.name_en,
          code: values.code,
          inn: values.inn,
          address: values.address,
          email: values.email,
          phone: values.phone,
          logo: logoId,
          id: id,
        })

      } else {

        // @ts-ignore
        response = await createTransportCompany({
          name: values.name,
          name_en: values.name_en,
          code: values.code,
          inn: values.inn,
          address: values.address,
          email: values.email,
          phone: values.phone,
          logo: logoId,
        })
      }

      // @ts-ignore
      if (response?.error) {
        // @ts-ignore
        const errorList = Object.keys(response?.error?.data)

        errorList.map((error) => {
          // @ts-ignore
          setError(error, { type: "custom",  message: response?.error?.data[error][0]})
        })

        setErrorNotification(true)
      } else {
        onAddedSuccess();
        setErrorNotification(false);
        isClosed && await handleClose();
        reset()
      }

    }

    // @ts-ignore
    handleSubmit(onSubmit)()

  }

  const handleClose = async () => {
    clearErrors();
    await handleTransportCompaniesRequest();
    onClose();
    setValue(MODAL_ADD_FORM.NAME, '');
    setValue(MODAL_ADD_FORM.NAME_EN, '');
    setValue(MODAL_ADD_FORM.CODE, '');
    setValue(MODAL_ADD_FORM.INN, '');
    setValue(MODAL_ADD_FORM.ADDRESS, '');
    setValue(MODAL_ADD_FORM.EMAIL, '');
    setValue(MODAL_ADD_FORM.PHONE, '');
  }

  const handleValidateEmail = (event: React.FocusEvent<HTMLInputElement>) => {

    const {value} = event.target;
    const emailIsCorrect = checkEmail(value, errorEmail);

    if (emailIsCorrect) {
      clearErrors(MODAL_ADD_FORM.EMAIL)
    } else {
      setError(MODAL_ADD_FORM.EMAIL, { type: "custom",  message: errorEmail })
    }

  }

  // @ts-ignore
  const handlePhoneChange = (value: string, country, e, formattedValue: string) => {
    setValue(MODAL_ADD_FORM.PHONE, formattedValue)
  }

  const handlePhoneBlur = (event: React.FocusEvent<HTMLInputElement>, data: CountryData) => {
    let {value} = event.target;
    value = value.replace(/[+()-/ ]/g, "");

    if (data.name === RUSSIA_COUNTRY_NAME) {
      value.match(RUSSIA_PHONE_NUMBER_REGEXP) ? clearErrors(MODAL_ADD_FORM.PHONE) : setError(MODAL_ADD_FORM.PHONE, { type: "custom",  message: t('errors__message__incorrectPhoneError') });
    }
  }

  const handleAddTransportCompanyAndClose = () => handleSendData({isClosed: true});

  return (
    <ModalAdd
      isOpen={isOpen}
      isEdit={isEdit}
      handleSendData={handleSendData}
      handleAddAndClose={handleAddTransportCompanyAndClose}
      handleClose={handleClose}
      isValid={isValid}
      title={isEdit ? t('dictionaries__text__transportCompany__editTransportCompanyModal') : t('dictionaries__text__transportCompany__addTransportCompanyModal')}
      saveAndCloseButtonText={t('dictionaries__text__transportCompany__addTransportCompanyButton')}
      removeButtonText={t('dictionaries__text__transportCompany__removeTransportCompany')}
      errorNotification={errorNotification}
      noSaveAndContinueButton
      modalRemove={(handleClose) => id && <Remove
        isOpen
        ids={[ id ]}
        onClose={handleClose}
        handleTransportCompaniesRequest={handleTransportCompaniesRequest}
        onRemoved={onRemoved}
      />}
    >
      <div>
        <FormProvider {...methods}>
          <TextField
            {...register(MODAL_ADD_FORM.NAME,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkOnlyRussianCharacters(value, errorRussianCharacters)
              })}
            error={Boolean(errors[MODAL_ADD_FORM.NAME])}
            helperText={errors[MODAL_ADD_FORM.NAME]?.message}
            variant='outlined'
            label={t('dictionaries__text__transportCompany__addTransportCompanyNameRussian')}
            InputLabelProps={{shrink: true}}
            fullWidth
            sx={{marginBottom: 4}}
          />
          <TextField
            {...register(MODAL_ADD_FORM.NAME_EN,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkOnlyEnglishCharacters(value, errorEnglishCharacters)
              })}
            error={Boolean(errors[MODAL_ADD_FORM.NAME_EN])}
            helperText={errors[MODAL_ADD_FORM.NAME_EN]?.message}
            variant='outlined'
            label={t('dictionaries__text__transportCompany__addTransportCompanyNameEnglish')}
            InputLabelProps={{shrink: true}}
            fullWidth
            sx={{marginBottom: 4}}
          />
          <TextField
            {...register(MODAL_ADD_FORM.CODE,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkOnlyEnglishCharacters(value, errorEnglishCharacters)
              })}
            error={Boolean(errors[MODAL_ADD_FORM.CODE])}
            helperText={errors[MODAL_ADD_FORM.CODE]?.message}
            variant='outlined'
            label={t('dictionaries__text__transportCompany__addCode')}
            InputLabelProps={{shrink: true}}
            fullWidth
            sx={{marginBottom: 4}}
          />
          <div className='dictionary__modal-add-container'>
            <TextField
              {...register(MODAL_ADD_FORM.INN,
                {
                  required: t('common__text__isRequired'),
                  validate: (value: string) => checkDecimal(Number(value), errorDecimal)
                })}
              error={Boolean(errors[MODAL_ADD_FORM.INN])}
              helperText={errors[MODAL_ADD_FORM.INN]?.message}
              variant='outlined'
              label={t('dictionaries__text__transportCompany__addInn')}
              InputLabelProps={{shrink: true}}
              fullWidth
              sx={{marginBottom: 4}}
            />
          </div>
          <TextField
            {...register(MODAL_ADD_FORM.ADDRESS,
              {
                required: t('common__text__isRequired'),
              })}
            error={Boolean(errors[MODAL_ADD_FORM.ADDRESS])}
            helperText={errors[MODAL_ADD_FORM.ADDRESS]?.message}
            variant='outlined'
            label={t('dictionaries__text__transportCompany__addAddress')}
            InputLabelProps={{shrink: true}}
            fullWidth
            sx={{marginBottom: 4}}
          />
          <div className='dictionary__modal-add-container'>
            <TextField
              {...register(MODAL_ADD_FORM.EMAIL,
                {
                  required: t('common__text__isRequired'),
                })}
              error={Boolean(errors[MODAL_ADD_FORM.EMAIL])}
              helperText={errors[MODAL_ADD_FORM.EMAIL]?.message}
              variant='outlined'
              label={t('dictionaries__text__transportCompany__addEmail')}
              InputLabelProps={{shrink: true}}
              onBlur={handleValidateEmail}
            />
            <div className='main-form__phone'>
              <PhoneInput
                country={'ru'}
                value={getValues(MODAL_ADD_FORM.PHONE)}
                onChange={handlePhoneChange}
                onBlur={handlePhoneBlur}
                // @ts-ignore
                isValid={!errors[MODAL_ADD_FORM.PHONE]}
                localization={ru}
                specialLabel={t('common__text__phone')}
                inputClass='main-form__phone-input'
              />
              <span className='main-form__phone-error'>{errors[MODAL_ADD_FORM.PHONE]?.message}</span>
            </div>
          </div>
          <FileUpload onHandle={(id: number) => setLogoId(id)}/>
        </FormProvider>
      </div>
    </ModalAdd>
  );
}

export default Add
