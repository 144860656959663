import React, {useState} from "react";

import './interface.scss'
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

enum INTERFACE_FORM {
    LANGUAGE = 'language',
    TIMEZONE = 'timezone',
}

type InterfaceForm = {
    language: string
    timezone: string
}

const Interface = () => {

  const { t } = useTranslation()

  const { register, handleSubmit, formState: { errors, isValid } } = useForm<InterfaceForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const optionsLanguage = [
    { label: 'Русский', id: 'ru' },
  ];

  const optionsTimezone = [
    { label: 'Россия, Москва, GMT+3', id: '+3' },
  ];

  return (
    <div className='settings-interface'>
      <Typography
        variant='h6'
        className='settings-interface__title'
      >
        {t('settings__text__interfaceLanguageTimezone')}
      </Typography>
      <Autocomplete
        className={`settings-interface__${INTERFACE_FORM.LANGUAGE}`}
        options={optionsLanguage}
        noOptionsText=''
        filterOptions={(options) => options}
        {...register(INTERFACE_FORM.LANGUAGE, { required: true })}
        defaultValue={optionsLanguage[0]}
        // @ts-ignore
        onChange={(_, value: { id: string } | null ) => setValue(INTERFACE_FORM.LANGUAGE, value?.id)}
        loadingText={t('common__message__loadingText')}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(errors[INTERFACE_FORM.LANGUAGE])}
            variant='outlined'
            label={t('settings__text__interfaceLanguage')}
            InputProps={{
              ...params.InputProps,
            }}
            InputLabelProps={{ shrink: true }}
          />)}/>
      <Autocomplete
        className={`settings-interface__${INTERFACE_FORM.TIMEZONE}`}
        options={optionsTimezone}
        noOptionsText=''
        filterOptions={(options) => options}
        {...register(INTERFACE_FORM.TIMEZONE, { required: true })}
        defaultValue={optionsTimezone[0]}
        // @ts-ignore
        onChange={(_, value: { id: string } | null ) => setValue(INTERFACE_FORM.TIMEZONE, value?.id)}
        loadingText={t('common__message__loadingText')}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(errors[INTERFACE_FORM.TIMEZONE])}
            variant='outlined'
            label={t('settings__text__timezone')}
            InputProps={{
              ...params.InputProps,
            }}
            InputLabelProps={{ shrink: true }}
          />)}/>
      <Button
        className='settings-auth__button'
        variant='contained'
        fullWidth
        type='submit'
        startIcon={<CheckIcon />}
        disabled={!isValid}
      >{t('actions__text__saveInterfaceSettings')}</Button>
    </div>
  )
}

export default Interface
