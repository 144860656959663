import { useAuthContext } from "../../context/AuthContext";
import { Login } from "../index";
import Main from "../cabinet/main";
import Preloader from "../common/preloader";

const Auth = () => {
  const { user, isSuccess, isNewUser } = useAuthContext();

  if (isSuccess) {
    return user ? <Main/> : <Login/>
  }

  if (isNewUser) {
    return <Login/>
  }

  return <Preloader/>

};

export default Auth;
