import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

import {useTranslation} from "react-i18next";

type Props = {
    open: boolean;
    isError?: boolean
    onClose(): void;
    message: string
}

const AlertBottom = ( { open, onClose, message, isError } : Props ) => {
  const { t } = useTranslation()

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right' }}
      autoHideDuration={2000}
    >
      <Alert onClose={onClose} severity={isError ? 'error' : 'info' } sx={{ width: '100%' }}>
        <AlertTitle>{isError ? t('errors__message__commonError') : t('common__text__done')}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default AlertBottom


