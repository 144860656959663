const isCanvas = (obj: HTMLCanvasElement | HTMLElement): obj is HTMLCanvasElement =>
  obj.tagName === 'CANVAS';

type EyeType = {
  x: number
  y: number
  radius: number
}

type CustomCoords = {
  leftEye: {
    x: number,
    y: number
  },
  rightEye: {
    x: number,
    y: number
  }
}

export const drawEyesCanvasRemove = (
  width: number,
  height: number,
  leftEye: EyeType,
  rightEye: EyeType,
  canvasId: string,
  customCoords?: CustomCoords,
) => {
  const moleImage = document.getElementById("moleImage");

  if (!moleImage) {
    return
  }

  moleImage.removeEventListener("load", () => loadEyesLogic(width, height, leftEye, rightEye, canvasId, customCoords))

}

export const drawEyesCanvasInit = (
  width: number,
  height: number,
  leftEye: EyeType,
  rightEye: EyeType,
  canvasId: string,
  customCoords?: CustomCoords,
) => {

  const moleImage = document.getElementById("moleImage");

  if (!moleImage){
    return;
  }

  moleImage.addEventListener("load", () => loadEyesLogic(width, height, leftEye, rightEye, canvasId, customCoords))

}

const loadEyesLogic = (
  width: number,
  height: number,
  leftEye: EyeType,
  rightEye: EyeType,
  canvasId: string,
  customCoords?: CustomCoords
) => {

  const canvas = document.getElementById(canvasId);

  if (!canvas || !isCanvas(canvas)){
    return null;
  }

  const ctx = canvas.getContext("2d");

  canvas.width = width;
  canvas.height = height;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;

  let eyes: any = [];
  let theta;

  const mouse = {
    x: 0,
    y: 0
  };

  window.addEventListener("mousemove", function (e) {
    mouse.x = e.clientX - canvas.getBoundingClientRect().left;
    mouse.y = e.clientY - canvas.getBoundingClientRect().top;
  });

  class Eye {
    public x: number;
    public y: number;
    public radius: number;

    constructor(x: number, y: number, radius: number) {
      this.x = x;
      this.y = y;
      this.radius = radius;
    }

    draw() {
      const dx = mouse.x - this.x;
      const dy = mouse.y - this.y;
      theta = Math.atan2(dy, dx);

      if (!ctx){
        return;
      }

      // радужная оболочка
      const iris_x = this.x + Math.cos(theta) * this.radius / 10;
      const iris_y = this.y + Math.sin(theta) * this.radius / 10;
      const irisRadius = this.radius / 1.2;
      ctx.beginPath();
      ctx.arc(iris_x, iris_y, irisRadius, 0, Math.PI * 2, true);
      ctx.fillStyle = "white";
      ctx.fill();
      ctx.closePath();

      // зрачок
      const pupilRadius = this.radius / 6;
      const pupil_x = this.x + Math.cos(theta) * this.radius / 1.9;
      const pupil_y = this.y + Math.sin(theta) * this.radius / 1.9;
      ctx.beginPath();
      ctx.arc(pupil_x, pupil_y, pupilRadius, 0, Math.PI * 2, true);
      ctx.fillStyle = "black";
      ctx.fill();
      ctx.closePath();

    }
  }

  function init() {

    if (!canvas || !isCanvas(canvas)){
      return null;
    }

    eyes = [];

    eyes.push(new Eye(leftEye.x, leftEye.y, leftEye.radius));

    eyes.push(new Eye(rightEye.x, rightEye.y, rightEye.radius));

  }

  function animate() {

    if (!ctx || !canvas || !isCanvas(canvas)){
      return null;
    }

    requestAnimationFrame(animate);

    ctx.fillStyle = "black";

    if (customCoords) {
      ctx.fillRect(customCoords.leftEye.x, customCoords.leftEye.y, 30, 30);
      ctx.fillRect(customCoords.rightEye.x, customCoords.rightEye.y, 21, 20);
    } else {
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    for (let i = 0; i < eyes.length; i++) {
      eyes[i].draw();
    }
  }

  init();
  animate();

}






