import React, {ReactNode, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDropzone} from 'react-dropzone';

import {useDispatch} from "react-redux";

import {Upload} from "../../../../../../../assets/svg";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import COLORS from "../../../../../../../styles/colors.scss";
import PlaceholderImage from './_placeholderImage.png';
import './file-upload.scss';

import { useLazyUploadImageQuery } from "../../../../../../../api/media_storage";
import Preloader from "../../../../../../common/preloader";

type FileUploadProps = {
  onHandle(id: number): void
}

const FileUpload = ({onHandle} : FileUploadProps) => {

  const { t } = useTranslation()

  const [ file, setFile ] = useState<TransportCompanyLogo>();
  const [ isLoading, setIsLoading ] = useState(false);

  const [ uploadImageRequest ] = useLazyUploadImageQuery()

  const handleLoadFile = (file: File[]) => {

    setIsLoading(true);

    if (file?.length){
      const formData = new FormData();
      formData.append('title', file[0].name)
      formData.append('image', file[0])
      uploadImageRequest(formData).then((data) => {
        setFile(data.data);
        onHandle(data.data.id);
        setIsLoading(false);
      });
    }
  }

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    accept: {
      "image/*": [ ".png", ".gif", ".jpeg", ".jpg" ],
    },
    onDrop: handleLoadFile,
  });

  const handleRemove = () => {
    acceptedFiles.splice(0, 1);
    setFile(undefined);
  }

  return (
    <>
      {file ? <div className='file-upload__file'>
        <span className='file-upload__label'>
          {t('common__text__logo')}
        </span>
        <div className='file-upload__image-container'>
          <div className='file-upload__icons'>
            <IconButton onClick={() => handleRemove()} sx={{marginTop: '-2px', width: '32px', height: '32px'}}>
              <DeleteIcon sx={{color: COLORS.LIGHT_GRAY}}/>
            </IconButton>
          </div>
          <img src={file.image} alt='' className='file-upload__image'/>
          <div className='file-upload__text'>
            <Typography variant='caption'>
              {t('common__text__uploadedFile')}
            </Typography>
            <Typography variant='subtitle1' className='file-upload__names'>
              {/* @ts-ignore */}
              {file?.title}
            </Typography>
          </div>
        </div>
      </div> :
        <div className='file-upload file-upload__container'>
          <span className='file-upload__label'>
            {t('common__text__logo')}
          </span>
          {isLoading ? <Preloader /> : (
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <Upload/>
              <Typography variant='subtitle1' sx={{marginTop: 0.5}}>
                <span className='file-upload__link'>
                  {t('common__text__clickToDownload')}&nbsp;
                </span>
                {t('common__text__dragTheFileToTheSelectedArea')}
              </Typography>
              <Typography variant='body2' sx={{marginTop: 1}}>
                {t('common__text__imageFileFormats')}
              </Typography>
            </div>
          )}
        </div>}
    </>
  )
};

export default FileUpload
