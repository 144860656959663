import React from "react";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

type PropsMenuItem = {
  title: string;
  onClick?(): void,
}

type Props = {
    menuItems: PropsMenuItem[]
}

const ShowMoreMenu = ({menuItems} : Props) => {

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChooseMenuItem = (item: PropsMenuItem) => {
    item.onClick && item.onClick();
    handleClose();
  }

  return <>
    <IconButton
      id='basic-button'
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup='true'
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {menuItems.map((item, index) => <MenuItem key={index} onClick={() => handleChooseMenuItem(item)}>{item.title}</MenuItem>)}
    </Menu>
  </>
};

export default ShowMoreMenu;
