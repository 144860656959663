import React from "react";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Table from '../../../components/table';
import TableRowActions from '../../../components/table_row_actions';

import isArray from 'lodash/isArray'

import './table.scss';

import { useIncoterms } from "../../../../../../api/dictionary";

import { ACTIONS } from "../../../constants";

type Props = {
  selectedRows?: string[];
  currentIncoterms?: string[]
  handleEdit(): void
  handleRemove(): void
  handleIncotermsRequest(data?: DictionaryParamsInput): void
  changeIncoterm(id: string[]): void
}

const IncotermsTable = ({ handleEdit, handleRemove, handleIncotermsRequest, changeIncoterm, currentIncoterms }: Props) => {

  const { t } = useTranslation()

  const incoterms = useIncoterms()?.data;

  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: t('common__text__code'),
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('menu__text__incoterms'),
    },
  ];

  const preferences = [
    {
      id: 'code',
      width: 290,
    },
    {
      id: 'name',
      width: 342,
    },
  ];

  if (!isArray(incoterms.results)){
    return null;
  }

  return (
    <div className='currency-table'>
      <Table
        rows={incoterms.results}
        count={incoterms.count}
        headCells={headCells}
        preferences={preferences}
        chooseItem={changeIncoterm}
        primaryField='code'
        handleSendRequest={handleIncotermsRequest}
        hasEmptySelection={!currentIncoterms?.length}
        actionsAfterTable={(selected: string[]) =>
          <TableRowActions hasEmptySelection={!selected.length}>
            {selected.length === 1 && <MenuItem
              value={ACTIONS.EDIT}
              onClick={handleEdit}
            >{t('actions__text__edit')}</MenuItem>}
            <MenuItem
              value={ACTIONS.REMOVE}
              onClick={handleRemove}
            >{t('actions__text__delete')}</MenuItem>
          </TableRowActions>}
      />
    </div>
  )
};

export default IncotermsTable
