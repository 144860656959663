import React, { useEffect } from "react";

import {useTranslation} from "react-i18next";
import ModalRemove from '../../../components/modal_remove';
import {useCurrencies, useCurrencyById, useRemoveCurrencyMutation} from "../../../../../../api/dictionary";
import {NOT_FOUND_STATUS} from "../../../../../../utils/constants";

type Props = {
    isOpen: boolean
    ids: string[]
    onClose(): void
    handleCurrenciesRequest(params?: DictionaryParamsInput): Promise<any>
    onRemoved(isSuccess: boolean): void
}

const Remove = ({isOpen, onClose, ids, handleCurrenciesRequest, onRemoved}: Props) => {

  const { t } = useTranslation()

  const [ removeCurrencyRequest ] = useRemoveCurrencyMutation()

  const currencyInfoByIds = ids.map((id: string) => useCurrencyById(id))

  const currenciesParams = useCurrencies()?.params;

  useEffect(() => {
  }, [ isOpen ])

  if (!currencyInfoByIds.length){
    return null;
  }

  const handleRemove = async () => {

    const response = await removeCurrencyRequest({currency_codes: ids});

    // @ts-ignore
    if (response?.error) {
      onRemoved(false);
    } else {
      onRemoved(true);
      handleCurrenciesRequest().then((data) => {
        if (data?.error?.status === NOT_FOUND_STATUS) {
          // @ts-ignore
          handleCurrenciesRequest({ page: currenciesParams ? currenciesParams.page - 1 : 1})
        }
      })
      onClose();
    }
  }

  return (<ModalRemove
    isOpen={isOpen}
    onClose={onClose}
    onRemoved={handleRemove}
    title={t('dictionaries__text__currency__removeCurrencyModal')}
    areYouSureText={currencyInfoByIds.length > 1 ? t('dictionaries__text__currency__areYouSureToRemoveSeveral') : t('dictionaries__text__currency__areYouSureToRemoveOne')}
    // @ts-ignore
    items={currencyInfoByIds}
  />
  );
}

export default Remove
