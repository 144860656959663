import { matchRoutes, useLocation } from 'react-router-dom'
import {ROUTES_DEFAULT_ARRAY} from '../components/routes/constants';

export const useGetIdFromRoute = () => {
  const location = useLocation()

  const [ { params } ] = matchRoutes(ROUTES_DEFAULT_ARRAY, location) ?? [ { } ]

  return params?.id ?? ''
}
