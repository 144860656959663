import React from "react";

import {useTranslation} from "react-i18next";

import ModalSmall from "../../../../common/modalAction";

import Typography from "@mui/material/Typography";
import {COLORS} from "../../../../../config/constants";

import './modal_remove.scss';

type Items = {
    code?: string
    id?: string
    country?: string
    name_en?: string
    name: string
}

type Props = {
    isOpen: boolean
    currencyInfo?: CurrencyDictionary
    title: string
    areYouSureText: string
    items: Items[]
    additionalField?: 'code' | 'id' | 'country' | 'name_en' ;
    onClose(): void
    onRemoved(): void
}

const ModalRemove = ({isOpen, onClose, items, onRemoved, title, areYouSureText, additionalField = 'code'}: Props) => {

  const { t } = useTranslation()

  return <ModalSmall
    isOpen={isOpen}
    onContinue={onRemoved}
    index={`${items[0]}.${additionalField}`}
    onClose={onClose}
    title={title}
    description={<>
      {areYouSureText}
      <Typography variant='subtitle1' sx={{ color: COLORS.BLACK }}>
        <ul className='currency-removeModal__list'>
          {items.map((item, index) => (
            <li className='currency-removeModal__list-item' key={index}>
              {`${item?.name} (${item?.[additionalField]})`}
            </li>
          ))}
        </ul>
      </Typography>
    </>}
    buttonYesText={t('actions__text__yesDelete')}
  />
}

export default ModalRemove
