import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormContext, Controller} from "react-hook-form";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import { checkTwoDecimalsAfterDot } from "../../../../../../../../../../../utils/validation";
import { ADD_PAYMENT_FORM, AddParamsPaymentForm } from "../../index";
import {ADORNMENTS} from "../../../../../../../../../../../config/constants";
import MenuButton from "../../../../../../../../../../common/menuButton";

import './payment-item.scss';

type Props = {
    control: any;
    index: number
    invoiceAmount: number
    isFirstItem?: boolean
    remove?(id: number): void
}

const PaymentItem = ({ index, control, isFirstItem, invoiceAmount, remove }: Props) => {

  const { t } = useTranslation()

  const { register, formState, setValue, getValues, watch, setError, clearErrors, trigger } = useFormContext<AddParamsPaymentForm>()

  const [ isCopiedBankCommissionForTheTransferData, setIsCopiedBankCommissionForTheTransferData ] = useState(false);
  const [ isCopiedCurrencyControlCommissionData, setIsCopiedCurrencyControlCommissionData ] = useState(false);

  // @ts-ignore
  const [ currentPercent, setCurrentPercent ] = useState(getValues(`payments.${index-1}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`));
  // @ts-ignore
  const [ currentAmount, setCurrentAmount ] = useState(getValues(`payments.${index-1}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`));

  const { errors } = formState;

  const watchedFieldsBankCommissionForTheTransfer = [ ADD_PAYMENT_FORM.BANK_TRANSFER_FEE, ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE, ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE ];

  const watchedFieldsCurrencyControlCommission = [ ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE, ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE, ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE ];

  watch((_, { name }) => {
    const shouldChangeCopiedBankCommissionForTheTransfer =
        name === `payments.${index-1}.${ADD_PAYMENT_FORM.BANK_TRANSFER_FEE}` ||
        name === `payments.${index-1}.${ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE}` ||
        name === `payments.${index-1}.${ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE}`

    const shouldChangeCopiedCurrencyControlCommission =
        name === `payments.${index-1}.${ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE}` ||
        name === `payments.${index-1}.${ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE}` ||
        name === `payments.${index-1}.${ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE}`

    if (
      shouldChangeCopiedBankCommissionForTheTransfer && isCopiedBankCommissionForTheTransferData ||
        shouldChangeCopiedCurrencyControlCommission && isCopiedCurrencyControlCommissionData
    ) {
      const fieldNames = name.split('.');
      const fieldName = fieldNames?.[fieldNames.length - 1];
      // @ts-ignore
      setValue(`payments.${index}.${fieldName}`, getValues(name))
    }
  });

  const errorNumber = t('errors__message__incorrectValue');

  const copyBankCommissionForTheTransferData = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    watchedFieldsBankCommissionForTheTransfer.map((field) => {
      // @ts-ignore
      setValue(`payments.${index}.${field}`, checked ? getValues(`payments.${index-1}.${field}`) : undefined)
    })
    setIsCopiedBankCommissionForTheTransferData(checked);
  }

  const copyCurrencyControlCommissionData = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    watchedFieldsCurrencyControlCommission.map((field) => {
      // @ts-ignore
      setValue(`payments.${index}.${field}`, checked ? getValues(`payments.${index-1}.${field}`) : undefined)
    })
    setIsCopiedCurrencyControlCommissionData(checked);
  }


  const handleChangeCommissionPercentage = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const isErrorValue = value < 0 || value > 100;

    if (isErrorValue){
      // @ts-ignore
      setError(`payments.${index}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`, { type: "custom",  message: t('errors__message__incorrectValue') });
    } else {
      // @ts-ignore
      clearErrors([ `payments.${index}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}` ])
    }

    // @ts-ignore
    setValue(`payments.${index}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`, (invoiceAmount *  value / 100).toFixed(2).toString());

    // @ts-ignore
    trigger(`payments.${index}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`);
  }

  const handleChangePaymentAmount = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const isErrorValue = value < 0 || value > invoiceAmount;

    if (isErrorValue || checkTwoDecimalsAfterDot(value, errorNumber) !== true){
      // @ts-ignore
      setError(`payments.${index}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`, { type: "custom",  message: t('errors__message__incorrectValue') });
    } else {
      // @ts-ignore
      clearErrors([ `payments.${index}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}` ])
    }

    const percentage = (value / invoiceAmount * 100).toFixed(2);

    // @ts-ignore
    setValue(`payments.${index}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`, percentage);

    // @ts-ignore
    setCurrentAmount(value.toString());
    // @ts-ignore
    trigger(`payments.${index}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`);

  }


  const menuOptions = [];

  if (remove){
    menuOptions.push({
      title: t('actions__text__delete'),
      onClick: () => remove(index),
    })
  }

  return <div className='payment-item'>
    {menuOptions.length > 0 && (
      <div className='payment-item__menuButtons'>
        <MenuButton options={menuOptions}/>
      </div>
    )}
    <Typography variant='subtitle1'><b>{t('calc__text__bankCommissions__invoicePayment')}</b></Typography>
    <div className='payment-item__form-row'>
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(`payments.${index}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`,
              {
                required: t('common__text__isRequired'),
                onChange: handleChangeCommissionPercentage,
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              }
            )}
            // @ts-ignore
            error={Boolean(errors['payments']?.[index]?.[ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]?.message)}
            // @ts-ignore
            helperText={errors['payments']?.[index]?.[ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]?.message}
            variant='outlined'
            label={`${t('calc__text__bankCommissions__payment')}, ${t('calc__text__bankCommissions__percentages')}`}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PERCENT,
            }}
          />
        )}
        name={`payments.${index}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`}
        control={control}
      />
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(`payments.${index}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`,
              {
                required: t('common__text__isRequired'),
                onChange: handleChangePaymentAmount,
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors['payments']?.[index]?.[ADD_PAYMENT_FORM.PAYMENT_AMOUNT]?.message)}
            // @ts-ignore
            helperText={errors['payments']?.[index]?.[ADD_PAYMENT_FORM.PAYMENT_AMOUNT]?.message}
            variant='outlined'
            label={`${t('calc__text__bankCommissions__payment')}, ${t('calc__text__bankCommissions__amount')}`}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={`payments.${index}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`}
        control={control}
      />
    </div>
    <Typography variant='subtitle1'
      sx={{marginBottom: isFirstItem ? 2 : 1}}><b>{t('calc__text__bankCommissions__bankCommissionForTheTransfer')}</b></Typography>
    {!isFirstItem && (
      <FormGroup>
        <FormControlLabel
          checked={isCopiedBankCommissionForTheTransferData}
          onChange={copyBankCommissionForTheTransferData}
          control={<Checkbox/>}
          label={t('calc__text__bankCommissions__copyDataFromPreviousPayment')}
        />
      </FormGroup>
    )}

    <div className='payment-item__form-row'>
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(`payments.${index}.${ADD_PAYMENT_FORM.BANK_TRANSFER_FEE}`,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors['payments']?.[index]?.[ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]?.message)}
            // @ts-ignore
            helperText={errors['payments']?.[index]?.[ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__commission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PERCENT,
            }}
          />
        )}
        name={`payments.${index}.${ADD_PAYMENT_FORM.BANK_TRANSFER_FEE}`}
        control={control}
      />
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(`payments.${index}.${ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE}`,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors['payments']?.[index]?.[ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]?.message)}
            // @ts-ignore
            helperText={errors['payments']?.[index]?.[ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__minCommission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={`payments.${index}.${ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE}`}
        control={control}
      />
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(`payments.${index}.${ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE}`,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors['payments']?.[index]?.[ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]?.message)}
            // @ts-ignore
            helperText={errors['payments']?.[index]?.[ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__maxCommission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={`payments.${index}.${ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE}`}
        control={control}
      />
    </div>
    <Typography variant='subtitle1'
      sx={{marginBottom: isFirstItem ? 2 : 1}}><b>{t('calc__text__bankCommissions__currencyControlCommission')}</b></Typography>
    {!isFirstItem && (
      <FormGroup>
        <FormControlLabel
          checked={isCopiedCurrencyControlCommissionData}
          onChange={copyCurrencyControlCommissionData}
          control={<Checkbox/>}
          label={t('calc__text__bankCommissions__copyDataFromPreviousPayment')}
        />
      </FormGroup>
    )}
    <div className='payment-item__form-row'>
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(`payments.${index}.${ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE}`,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors['payments']?.[index]?.[ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]?.message)}
            // @ts-ignore
            helperText={errors['payments']?.[index]?.[ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__commission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PERCENT,
            }}
          />
        )}
        name={`payments.${index}.${ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE}`}
        control={control}
      />

      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(`payments.${index}.${ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE}`,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors['payments']?.[index]?.[ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]?.message)}
            // @ts-ignore
            helperText={errors['payments']?.[index]?.[ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__minCommission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={`payments.${index}.${ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE}`}
        control={control}
      />

      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(`payments.${index}.${ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE}`,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors['payments']?.[index]?.[ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]?.message)}
            // @ts-ignore
            helperText={errors['payments']?.[index]?.[ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__maxCommission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={`payments.${index}.${ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE}`}
        control={control}
      />
    </div>
  </div>
}

export default PaymentItem
