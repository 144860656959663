import React, {ReactNode, useState, useRef} from "react";
import { AuthContext } from "../../../context/AuthContext";
import {getToken, useGetUserMutation} from "../../../api/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "../../../config/constants";

type AuthProviderProps = {
    children: ReactNode
}

const AuthProvider = ({ children } : AuthProviderProps) => {

  const [ userData, setUserData ] = useState(undefined);
  const [ isSuccess, setIsSuccess ] = useState(false);
  const [ isNewUser, setIsNewUser ] = useState(false);

  const [ getUser ] = useGetUserMutation()

  const authToken = getToken();

  const navigate = useNavigate();

  const handleUser = (user: any) => {
    setUserData(user);
  };

  const fetchLoggedInUser = async () => {

    try {
      // @ts-ignore
      const response = await getUser()

      // @ts-ignore
      setUserData(response.data);
      setIsNewUser(false)

      // @ts-ignore
      if (response?.error) {
        setIsNewUser(true);
        navigate(ROUTES.LOGIN)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSuccess(true)
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser();
    } else {
      setIsNewUser(true);
      setIsSuccess(true);
    }
  }, [ authToken ])

  return (
    <AuthContext.Provider
      value={{ user: userData, setUser: handleUser, isSuccess, isNewUser, setIsSuccess }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
