import {useTranslation} from "react-i18next";

export const getCertificateInfo = (summaryCustoms?: DeclarationSummaryParamsOutput | Record<string, never>) => {

  const { t } = useTranslation();

  return [
    [
      ...(summaryCustoms?.cert_total_manual || summaryCustoms?.cert_total
        ? [
          {
            label: t('dictionaries__text__certification__priceManual'),
            value: `${summaryCustoms?.cert_total_manual || summaryCustoms?.cert_total} USD`,
          },
        ]
        : []),
    ],
  ]
}
