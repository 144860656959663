export const leftEye = {
  x: 15,
  y: 25,
  radius: 10
};

export const rightEye = {
  x: 38,
  y: 10,
  radius: 10
};

export const customCoords = {
  leftEye: {
    x: 5,
    y: 15
  },
  rightEye: {
    x: 27,
    y: 0
  }
}
