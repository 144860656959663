import React, {useState, useEffect} from "react";

import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";

import {
  useCreateIncotermMutation,
  useIncotermById,
  useChangeIncotermMutation
} from "../../../../../../api/dictionary";
import ModalAdd from "../../../components/modal_add";
import Remove from './../remove';

import './add.scss';

type Props = {
    isOpen: boolean
    isEdit: boolean
    id?: string
    onClose(): void
    onRemoved(isSuccess: boolean): void
    onAddedSuccess(): void
    handleIncotermsRequest(data?: DictionaryParamsInput): Promise<any>
}

type PropsSendData = {
    isClosed?: boolean
}

export enum MODAL_ADD_FORM {
    NAME = 'name',
    CODE = 'code',
}

export type ModalAddParams = {
    [MODAL_ADD_FORM.NAME]: string
    [MODAL_ADD_FORM.CODE]: string
}

const Add = ({isOpen, onClose, isEdit, handleIncotermsRequest, id, onAddedSuccess, onRemoved }: Props) => {

  const { t } = useTranslation()

  const incotermsInfo = useIncotermById(id);

  const [ errorNotification, setErrorNotification ] = useState(false);

  const [ createIncoterm ] = useCreateIncotermMutation()
  const [ changeIncotermRequest ] = useChangeIncotermMutation()

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: {errors, isValid}
  } = useForm<ModalAddParams>({
    defaultValues: {
      [MODAL_ADD_FORM.NAME]: isEdit ? incotermsInfo?.[MODAL_ADD_FORM.NAME] : undefined,
      [MODAL_ADD_FORM.CODE]: isEdit ? incotermsInfo?.[MODAL_ADD_FORM.CODE] : undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
  }, [ isOpen ])

  const handleSendData = ({isClosed}: PropsSendData) => {

    const onSubmit = async (values: IncotermDictionary) => {

      let response: any;

      if (isEdit) {
        response = await changeIncotermRequest({
          code: values.code,
          name: values.name
        })

      } else {
        response = await createIncoterm({
          code: values.code,
          name: values.name
        })
      }

      // @ts-ignore
      if (response?.error) {

        // @ts-ignore
        const errorList = Object.keys(response?.error?.data)

        errorList.map((error) => {
          // @ts-ignore
          setError(error, { type: "custom",  message: response?.error?.data[error][0]})
        })

        setErrorNotification(true)
      } else {
        onAddedSuccess();
        setErrorNotification(false);
        await reset()
        isClosed && await handleClose();
      }

    }

    handleSubmit(onSubmit)()

  }

  const handleClose = () => {
    clearErrors();
    return handleIncotermsRequest().then(() => {
      onClose();
      setValue(MODAL_ADD_FORM.NAME, '');
      setValue(MODAL_ADD_FORM.CODE, '');
    });
  }

  const handleAddIncotermAndClose = () => handleSendData({isClosed: true});

  return (
    <ModalAdd
      isOpen={isOpen}
      isEdit={isEdit}
      handleSendData={handleSendData}
      handleAddAndClose={handleAddIncotermAndClose}
      handleClose={handleClose}
      isValid={isValid}
      title={isEdit ? t('dictionaries__text__incoterms__editIncotermsModal') : t('dictionaries__text__incoterms__addIncotermsModal')}
      saveButtonText={t('dictionaries__text__incoterms__addIncotermsAndSaveButton')}
      removeButtonText={t('dictionaries__text__incoterms__removeIncoterms')}
      errorNotification={errorNotification}
      modalRemove={(handleClose) => id && <Remove
        isOpen
        ids={[ id ]}
        onClose={handleClose}
        handleIncotermsRequest={handleIncotermsRequest}
        onRemoved={onRemoved}
      />}
    >
      <div className='dictionary__modal-add-container'>
        <TextField
          {...register(MODAL_ADD_FORM.NAME, {required: t('common__text__isRequired')})}
          error={Boolean(errors[MODAL_ADD_FORM.NAME])}
          helperText={errors[MODAL_ADD_FORM.NAME]?.message}
          variant='outlined'
          label={t('menu__text__incoterms')}
          InputLabelProps={{shrink: true}}
        />
        <TextField
          {...register(MODAL_ADD_FORM.CODE, {required: t('common__text__isRequired')})}
          error={Boolean(errors[MODAL_ADD_FORM.CODE])}
          helperText={errors[MODAL_ADD_FORM.CODE]?.message}
          variant='outlined'
          label={t('common__text__code')}
          InputLabelProps={{shrink: true}}
          disabled={isEdit}
        />
      </div>
    </ModalAdd>
  );
}

export default Add
