export const enum USER_GROUPS {
  MANAGER = 'manager'
}

export const enum RESULTS_COUNT {
  COUNT_50 = 50,
  COUNT_100 = 100,
  COUNT_500 = 500
}

export const NOT_FOUND_STATUS = 404.
