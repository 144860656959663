export const enum SORTING_OPTIONS {
    CODE_DESC = 'code',
    CODE_ASC = '-code',
    NAME_DESC = 'name',
    NAME_ASC = '-name',
}

export const enum ACTIONS {
    EDIT = 'EDIT',
    REMOVE = 'REMOVE',
}

export const INITIAL_OPTION = {
  id: '',
  label: ''
}

export const INITIAL_DATA_REQUEST = {
  limit: 50,
  page: 1,
  ordering: SORTING_OPTIONS.CODE_DESC
}
