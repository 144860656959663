import React from "react";

import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {COLORS, ROUTES} from "../../../../../config/constants";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import {useNavigate} from "react-router-dom";

import {useDispatch} from "react-redux";
import CheckIcon from '@mui/icons-material/Check';
import {supportedLngs} from "../../../../../config/i18";

type Props = {
    anchorEl: HTMLElement | null
    handleCloseMenu(): void
}

const LanguageMenu = ({ anchorEl, handleCloseMenu } : Props) => {

  const { t } = useTranslation()

  const { i18n } = useTranslation();

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value)
  }

  const navigate = useNavigate();

  const dispatch = useDispatch()

  return (
    <Menu
      id='menu-language'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      sx={{
        '.MuiMenuItem-root': {
          minHeight: 36
        },
        '.MuiList-root': {
          width: 240,
          paddingBottom: 1,
        },
      }}
    >
      <div className='app-bar__userContainer'>
        <Typography variant='body1'>
          {t('settings__text__interfaceLanguage')}
        </Typography>
      </div>
      <Divider sx={{ marginBottom: 1 }}/>
      {Object.entries(supportedLngs).map(([ code, name ]) => {
        const isCurrentLanguage = code === i18n.resolvedLanguage;
        return  (
          <MenuItem onClick={() => handleChangeLanguage(code)} key={code}>
            {isCurrentLanguage && <CheckIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }}/>}
            <Typography variant='body2' sx={{ marginLeft: isCurrentLanguage ? 4 : 7, color: COLORS.BLACK }}>
              {name}
            </Typography>
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default LanguageMenu
