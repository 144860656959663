
export const getCountries = (data: {
    results: CountryDictionary[]
    count?: number
}) => (data?.results ?? [])
  .map(({ alta_code, code, name, }: { alta_code: string, code: string, name: string }) => ({ id: alta_code, label: name, code }))

export const enum PACKAGE_FORM {
    NAME = 'name',
    PIECES_IN_CARTOON = 'pieces_in_cartoon',
    PLACES_MANUAL = 'places_manual',
    PIECES = 'pieces',
    UNIT_PRICE = 'unit_price',
    UNIT_PRICE_TOTAL = 'unit_price_total',
    PRICE = 'price',
    PRICE_TOTAL = 'price_total',
    PALLETES = 'palletes',
    LENGTH = 'length',
    WIDTH = 'width',
    HEIGHT = 'height',
    NET_WEIGHT_CARTOON = 'net_weight_cartoon',
    GROSS_WEIGHT_CARTOON = 'gross_weight_cartoon',
    NET_WEIGHT_MANUAL = 'net_weight_manual',
    GROSS_WEIGHT_MANUAL = 'gross_weight_manual',
    COUNTRY_CODE = 'country_code',
    CODE = 'code',
    VOLUME_MANUAL = 'volume_manual',
    EXCISE_VALUE = 'excise_value',
    EXCISE_RATE = 'excise_rate',
    PALLETE_TYPE = 'pallete_type',
    PALLETE_CNT = 'pallete_cnt',
    PALLETE_HEIGHT = 'pallete_height',
    PALLETE_WEIGHT = 'pallete_weight',
    TWO_TIERS_ALLOWED = 'two_tiers_allowed'
}

export type PackageForm = {
    [PACKAGE_FORM.NAME]: string
    [PACKAGE_FORM.PIECES_IN_CARTOON]: number
    [PACKAGE_FORM.PLACES_MANUAL]: number
    [PACKAGE_FORM.PIECES]: number
    [PACKAGE_FORM.UNIT_PRICE]: number
    [PACKAGE_FORM.PRICE]: number
    [PACKAGE_FORM.PALLETES]: boolean
    [PACKAGE_FORM.LENGTH]: number
    [PACKAGE_FORM.WIDTH]: number
    [PACKAGE_FORM.HEIGHT]: number
    [PACKAGE_FORM.NET_WEIGHT_CARTOON]: number
    [PACKAGE_FORM.GROSS_WEIGHT_CARTOON]: number
    [PACKAGE_FORM.NET_WEIGHT_MANUAL]: number
    [PACKAGE_FORM.GROSS_WEIGHT_MANUAL]: number
    [PACKAGE_FORM.CODE]: string
    [PACKAGE_FORM.COUNTRY_CODE]: string
    [PACKAGE_FORM.VOLUME_MANUAL]?: number
    [PACKAGE_FORM.EXCISE_VALUE]?: number
    [PACKAGE_FORM.EXCISE_RATE]?: Excise
    [PACKAGE_FORM.PALLETE_TYPE]?: string
    [PACKAGE_FORM.PALLETE_CNT]?: number
    [PACKAGE_FORM.PALLETE_HEIGHT]?: number
    [PACKAGE_FORM.PALLETE_WEIGHT]?: number,
    [PACKAGE_FORM.TWO_TIERS_ALLOWED]?: boolean
}

export const getCommonFields = (values: PackageForm) => {
  return {
    [PACKAGE_FORM.NAME]: values[PACKAGE_FORM.NAME],
    [PACKAGE_FORM.PIECES_IN_CARTOON]: values[PACKAGE_FORM.PIECES_IN_CARTOON],
    [PACKAGE_FORM.PLACES_MANUAL]: values[PACKAGE_FORM.PLACES_MANUAL],
    [PACKAGE_FORM.PIECES]: values[PACKAGE_FORM.PIECES],
    [PACKAGE_FORM.PRICE]: values[PACKAGE_FORM.PRICE],
    [PACKAGE_FORM.UNIT_PRICE]: values[PACKAGE_FORM.UNIT_PRICE],
    ...(values[PACKAGE_FORM.CODE] && { [PACKAGE_FORM.CODE]: values[PACKAGE_FORM.CODE] }),
    [PACKAGE_FORM.COUNTRY_CODE]: values[PACKAGE_FORM.COUNTRY_CODE],
  }
}

export const getPackageItemFields = (values: PackageForm) => {
  return {
    [PACKAGE_FORM.LENGTH]: values[PACKAGE_FORM.LENGTH],
    [PACKAGE_FORM.WIDTH]: values[PACKAGE_FORM.WIDTH],
    [PACKAGE_FORM.HEIGHT]: values[PACKAGE_FORM.HEIGHT],
    [PACKAGE_FORM.PALLETES]: values[PACKAGE_FORM.PALLETES],
    [PACKAGE_FORM.NET_WEIGHT_CARTOON]: values[PACKAGE_FORM.NET_WEIGHT_CARTOON],
    [PACKAGE_FORM.GROSS_WEIGHT_CARTOON]: values[PACKAGE_FORM.GROSS_WEIGHT_CARTOON],
  }
}

export const getExciseItemFields = (values: PackageForm) => {
  return {
    [PACKAGE_FORM.EXCISE_VALUE]: values[PACKAGE_FORM.EXCISE_VALUE],
    [PACKAGE_FORM.EXCISE_RATE]: values[PACKAGE_FORM.EXCISE_RATE],
  }
}

export const getPalletFields = (values: PackageForm) => {
  return {
    [PACKAGE_FORM.PALLETE_TYPE]: values[PACKAGE_FORM.PALLETE_TYPE],
    [PACKAGE_FORM.PALLETE_CNT]: values[PACKAGE_FORM.PALLETE_CNT],
    [PACKAGE_FORM.PALLETE_HEIGHT]: values[PACKAGE_FORM.PALLETE_HEIGHT],
    [PACKAGE_FORM.PALLETE_WEIGHT]: values[PACKAGE_FORM.PALLETE_WEIGHT],
    [PACKAGE_FORM.TWO_TIERS_ALLOWED]: values[PACKAGE_FORM.TWO_TIERS_ALLOWED],
  }
}
