import SummaryCalculator from '../../../../common/summaryCalculator';

import React, { useEffect } from "react";
import {
  useAdditionalServices,
  useDeclarationsByOrderIdQuery, useGetBankCommissionsByOrderIdQuery, useGetExtraServicesByOrderIdQuery,
  useGetOrderSummaryByIdQuery,
  useGetRouteByOrderIdQuery,
  useInvoicesByOrderIdQuery, useOrder,
  useOrderByIdQuery, useSummary,
  useSummaryByOrderIdQuery,
} from "../../../../../api/calc";
import isEmpty from "lodash/isEmpty";

type Props = {
  orderId: string;
    noLimeColor?: boolean;
}

const Summary = ({orderId, noLimeColor}: Props) => {

  useEffect(() => {

  }, [ orderId ])

  const { refetch: fetchOrder } = useOrderByIdQuery(orderId)
  const invoices = useInvoicesByOrderIdQuery(orderId)?.data
  const { data: summaryInfo } = useGetOrderSummaryByIdQuery(orderId)
  const { refetch: fetchSummaryOrder } = useSummaryByOrderIdQuery(orderId)
  const { data: summaryDelivery } = useGetRouteByOrderIdQuery(orderId)
  const { data: declarations } = useDeclarationsByOrderIdQuery(orderId)
  const { refetch: fetchBankCommissions } = useGetBankCommissionsByOrderIdQuery({orderId})
  const { data: userExtraServices } = useGetExtraServicesByOrderIdQuery(orderId)

  const order = useOrder();
  const summaryOrder = useSummary();

  const userSummaryOrder = {
    ...summaryOrder,
    containers_user: !isEmpty(summaryOrder?.containers_manual) ? summaryOrder?.containers_manual : summaryOrder?.containers
  }

  return (
    <SummaryCalculator
      noInfo
      order={order}
      invoices={invoices}
      // @ts-ignore
      summaryOrder={userSummaryOrder}
      summaryDelivery={summaryDelivery}
      summaryCustoms={summaryInfo?.customs}
      summaryCompleted={summaryInfo?.completed}
      declarations={declarations}
      noLimeColor={noLimeColor}
    />
  )
}

export default Summary
