import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const geoQuery = fetchBaseQuery({
  baseUrl: 'https://api.mapbox.com/',
  prepareHeaders: (headers) => {
    headers.set('authorization', 'Token 7df11f14790c1bec82b01a4f0bbb18a61791a93a')

    return headers
  }
})

export const MAP_TOKEN = 'pk.eyJ1Ijoia2lyaWxsIiwiYSI6ImNpdGZpNHF4cDAwZm8yc3RvNnk5a3QxbGIifQ.OEKQ6UQJWDiKmfdCeF9-mg'

export const geocodingApi = createApi({
  baseQuery: geoQuery,
  reducerPath: 'geocodingApi',
  endpoints: (builder) => ({
    getCoords: builder.query<any, string>({
      query: (query) => `geocoding/v5/mapbox.places/${query}.json?access_token=${MAP_TOKEN}&types=region,district,place`,
    }),
  }),
})

export const { useLazyGetCoordsQuery } = geocodingApi
