import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {useOrderParamsData} from "../../../../api/calc";
import SearchIcon from '@mui/icons-material/Search';

type Props = {
    onChange(value: string): void,
    noFilterValues?: boolean,
}

const Search = ({ onChange, noFilterValues }: Props) => {

  const { t } = useTranslation()

  const filterData = useOrderParamsData();

  const [ value, setValue ] = React.useState<string | undefined>(filterData.search);

  useEffect(() => {
    if (noFilterValues) {
      setValue('')
    }
  }, [ noFilterValues ])

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value)
  }

  return (
    <TextField
      label={t('common__text__searchCalculationQuery')}
      fullWidth
      onChange={({ target: { value } }) => handleChange(value)}
      value={value}
      InputProps={{
        endAdornment: <SearchIcon/>,
      }}
    />
  )
}

export default Search
