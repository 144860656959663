import React, {ReactNode, useEffect, useState} from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {useTranslation} from "react-i18next";
import {
  useSummaryByOrderIdQuery,
  useSummary,
  useUpdateSummaryByOrderIdMutation,
  useGetInvoices,
  useDeclarationsByOrderIdQuery,
  useSetCompletedByOrderIdMutation,
  useAddServicesToRouteByOrderIdMutation, useGetRouteByOrderIdQuery,
} from "../../../../../../../api/calc";

import './summary.scss'
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import CombinedCargo from './components/combined_cargo';
import DeliveryType from './components/delivery_type';
import Completed from '../../../../../../common/completed-new';
import EditContainers from "./components/edit_containers";
import isEmpty from "lodash/isEmpty";
import COLORS from "../../../../../../../styles/colors.scss";
import Tooltip from "../../../../../../common/tooltip";

export const enum METHODS {
  COMBINED_CARGO = 1,
  CONTAINERS = 2
}

export const enum CONTAINERS_FORM {
  FT_20 = 'type_20dc',
  FT_40 = 'type_40dc',
  FT_40_HIGH_CUBE = 'type_40hc',
}

export type SummaryData = {
  title: ReactNode
  items: {
    label: ReactNode
    value: ReactNode
  }[]
  hasChip?: boolean
}

type Props =  {
  productsCount: number
  handleComplete(): void
}

const Summary = ({productsCount, handleComplete}: Props) => {

  const [ deliveryMethod, setDeliveryMethod ] = useState<number | null>(null);

  const [ editMode, setEditMode ] = useState(false);

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const invoices = useGetInvoices()

  const { data: summary, refetch, isSuccess } = useSummaryByOrderIdQuery(orderId)

  const summaryContainers = useSummary()

  const containersUser = summaryContainers?.containers_user;
  const currentDeliveryType = summaryContainers?.delivery_type;
  const showWarningNotificationContainers = summaryContainers?.not_enough_space_warning;

  const { refetch: refetchDeclaration } = useDeclarationsByOrderIdQuery(orderId, { skip: !currentDeliveryType })

  const [ saveSummary ] = useUpdateSummaryByOrderIdMutation()
  const [ setCompleted ] = useSetCompletedByOrderIdMutation()
  const [ addServices ] = useAddServicesToRouteByOrderIdMutation()

  const hasContainersManual = !isEmpty(summaryContainers?.containers_manual);

  useEffect( () => {
    refetch();
  }, [ invoices.length, productsCount ])

  useEffect(() => {
    !isSuccess && refetch();
  }, [])

  useEffect(() => {
    summary && setDeliveryMethod(currentDeliveryType);
  }, [ summary ])

  if (!summary?.products_count) {
    return null;
  }

  const handleSendCompleted = async () => {
    await setCompleted({
      orderId,
      packaging: true,
      delivery: false,
      customs: false,
      additional_services: false,
    })

    await addServices({
      orderId,
      route: null,
      services: []
    });
  }

  const handleSave = async () => {
    let savedData: UpdateSummaryParamsInput = {
      orderId,
      delivery_type: deliveryMethod,
    }

    if (deliveryMethod === METHODS.CONTAINERS) {
      savedData = {
        ...savedData,
        containers_manual: containersUser
      }
    }

    await saveSummary(savedData)

    if (deliveryMethod !== summary.delivery_type) {
      await handleSendCompleted()
    }

    refetch();
    refetchDeclaration();
    handleComplete();
    setEditMode(false);
  }

  const isCombinedCargoMethod = summary.delivery_type === METHODS.COMBINED_CARGO;
  const isContainersMethod = summary.delivery_type === METHODS.CONTAINERS;

  const handleEditSave = async (values?: ContainersManual) => {
    setDeliveryMethod(METHODS.CONTAINERS)

    const savedData: UpdateSummaryParamsInput = {
      orderId,
      delivery_type: deliveryMethod,
      containers_manual: values
    }

    await saveSummary(savedData)

    if (summary.delivery_type !== METHODS.CONTAINERS) {
      await handleSendCompleted()
    }

    setEditMode(false)

    refetch();
    refetchDeclaration();
  }

  const handleSetAutoContainers = async () => {
    await handleEditSave({})
    setEditMode(true);
  }

  const getCompletedInfo = () => [
    {
      label: t('calc__text__packaging__numberOfAccounts'),
      value: summary.invoices_count
    },
    {
      label: t('common__text__totalVolume'),
      value: <>{summary.volume_total} {t('units__text__meter')}<sup>3</sup></>
    },
    {
      label: t('calc__text__packaging__totalProducts'),
      value: summary.products_count
    },
    {
      label: t('common__text__totalNetWeight'),
      value: <>{summary.net_weight_total} {t('units__text__kg')}</>
    },
    {
      label: t('calc__text__rightPanel__totalCostOfGoods'),
      value: summary.price_total + ' USD'
    },
    {
      label: t('common__text__totalGrossWeight'),
      value: <>{summary.gross_weight_total} {t('units__text__kg')}</>
    },
  ]

  const getCompletedInfoCargoMethod = () => [
    {
      label: t('calc__text__delivery__airfreight'),
      value: `${summary?.volume_weight.avia || 0} ${t('units__text__kg')}`
    },
    {
      label: t('calc__text__delivery__trucking'),
      value: `${summary?.volume_weight.auto || 0} ${t('units__text__kg')}`
    },
    {
      label: t('calc__text__delivery__seafreight'),
      value: `${summary?.volume_weight.sea || 0} ${t('units__text__kg')}`
    },
    {
      label: t('calc__text__delivery__railwayFreight'),
      value: `${summary?.volume_weight.rails || 0} ${t('units__text__kg')}`
    },
  ]

  const getCompletedInfoContainers = () => [
    {
      label: '20 Ft',
      value: !isEmpty(summary?.containers_manual) ? summary?.containers_manual?.[CONTAINERS_FORM.FT_20] || 0 : summary?.containers?.[CONTAINERS_FORM.FT_20] || 0
    },
    {
      label: '40 Ft',
      value: !isEmpty(summary?.containers_manual) ? summary?.containers_manual?.[CONTAINERS_FORM.FT_40] || 0 : summary?.containers?.[CONTAINERS_FORM.FT_40] || 0
    },
    {
      label: '40 Ft High cube',
      value: !isEmpty(summary?.containers_manual) ? summary?.containers_manual?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] || 0: summary?.containers?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] || 0
    },
  ]

  return <>
    <div className='summary-packaging'>
      <Typography variant='h6' sx={{ marginBottom: 3}}>
        {t('calc__text__packaging__summaryAboutPackage')}
      </Typography>
      <Completed
        info={getCompletedInfo()}
        noPadding
      />
      {(editMode || !summary?.delivery_type) ? <div className='summary-packaging__delivery'>
        <DeliveryType deliveryMethod={deliveryMethod} setDeliveryMethod={setDeliveryMethod} defaultValue={summary.delivery_type} />
        {deliveryMethod && <div className='summary-packaging__block'>
          {deliveryMethod === METHODS.COMBINED_CARGO && <CombinedCargo summary={summary} handleSave={handleSave} /> }
          {deliveryMethod === METHODS.CONTAINERS && <EditContainers onSave={handleEditSave} handleSetAutoContainers={handleSetAutoContainers} />}
        </div>}
      </div> : <div className='summary-packaging__completed'>
        {isCombinedCargoMethod && <Completed
          info={getCompletedInfoCargoMethod()}
          onEdit={() => setEditMode(true)}
          caption={`${t('calc__text__packaging__deliveryMethod')}: ${t('calc__text__packaging__combinedCargo')}` }
          noBackground
          preInfo={<>
            <Divider sx={{ marginTop: 2, marginBottom: 2 }}/>
            <Typography variant='subtitle1' sx={{color: `${COLORS.BLACK}`}}>{t('calc__text__packaging__volumetricWeight')}</Typography>
          </>}
        />}
        {isContainersMethod && <Completed
          info={getCompletedInfoContainers()}
          onEdit={() => setEditMode(true)}
          hasThreeRows
          caption={`${t('calc__text__packaging__deliveryMethod')}: ${t('calc__text__packaging__containers')}` }
          noBackground
          preInfo={<>
            <Divider sx={{ marginTop: 2, marginBottom: 2 }}/>
            <div className='summary-packaging__completed-preInfo'>
              <Typography variant='subtitle1'
                sx={{
                  color: `${COLORS.BLACK}`,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {t('calc__text__packaging__calculatedNumberOfContainers')}
                <Typography variant='body2' sx={{ marginLeft: 0.5, fontSize: '16px' }}>{`(${hasContainersManual ? t('calc__text__packaging__manually') : t('calc__text__packaging__automatically')})`}</Typography>
              </Typography>
              {showWarningNotificationContainers && <Tooltip content={t('calc__text__packaging__containersDoNotFitError')} isWarning/>}
            </div>
          </>}
        />}
      </div>
      }
    </div>
  </>
}

export default Summary
