import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {getToken} from "../../../api/auth";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import {ROUTES} from "../../../config/constants";
import Preloader from "../../common/preloader";
import {useAuthContext} from "../../../context/AuthContext";

const Main = () => {

  const { t } = useTranslation()

  const { user } = useAuthContext();

  const navigate = useNavigate();

  const authToken = getToken();

  useEffect(() => {
    if (!authToken) {
      navigate("/login")
    }
  }, [])

  const handleGoToCalculationPage = () => navigate(ROUTES.CALCULATOR)

  if (!user) {
    return <Preloader/>
  }
  return (
    <div className='wrapper'>
      <Button
        variant='contained'
        startIcon={<AddIcon />}
        sx={{ width: '312px' }}
        onClick={handleGoToCalculationPage}
      >
        {t('calc__text__newCalculation')}
      </Button>
    </div>
  )
}

export default Main
