import React from "react";

import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";

import './new-button.scss';

type Props = {
    append: any
    initialValues: any
}

const NewButton = ( { append, initialValues } : Props ) => {

  return (
    <IconButton onClick={() => {
      append(initialValues)
    }} className='payments-tabs__new-button'
    sx={{ marginLeft: 1, position: 'absolute' }}>
      <AddIcon />
    </IconButton>
  );
}

export default NewButton


