import React, {useEffect, useRef} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useReactToPrint} from "react-to-print";

import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import TurnedInOutlinedIcon from '@mui/icons-material/TurnedInOutlined';

import SummaryItem from './summary_item';
import { CalculationStatus } from "../../../calculations/components/calculation";
import {
  CALC_ID, useGetOrderSummaryByIdQuery,
  useGetOrderSummaryInvoicesByIdQuery,
  useOrder, useSummary, useSummaryCompleted, useSummaryCustoms, useSummaryInvoices,
  useUpdateOrderMutation
} from "../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";
import AlertBottom from "../../../../common/alertBottom";
import {ROUTES} from "../../../../../config/constants";
import PrintOrder from "../../../../print/order";
import Notification, {NOTIFICATION_TYPE} from "../../../../common/notification";
import {CONTAINERS_FORM, METHODS} from "../invoices-and-products/components/summary";

import COLORS from "../../../../../styles/colors.scss";

import './summary.scss';

const enum ACTIONS {
  PRINT = 'PRINT',
}

const Summary = () => {

  const { t } = useTranslation()

  const componentRef = useRef();

  const [ action, setAction ] = React.useState<string>();

  const [ openAlert, setOpenAlert ] = React.useState(false);

  const orderId = useGetCalcIdFromRoute()

  const navigate = useNavigate();

  const { refetch } = useGetOrderSummaryInvoicesByIdQuery(orderId)
  const { refetch: summaryRefetch } = useGetOrderSummaryByIdQuery(orderId)

  const summaryInfo = useSummaryInvoices();

  const summaryCompleted = useSummaryCompleted()

  const summaryOrder = useSummary();

  const summaryCustoms = useSummaryCustoms();

  useEffect(() => {
    refetch();
  }, [ summaryCompleted ])

  useEffect(() => {
    summaryRefetch();
  }, [ ])

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: `${t('computation__text__computation')} №${orderId}`
  });

  const order = useOrder()

  const [ updateOrder ] = useUpdateOrderMutation()

  const getProductInfo = (products: OrderSummaryProduct[], field: string, measure: React.ReactNode) => products.map((product) => ({
    title: product.name,
    // @ts-ignore
    price: <>{product[field]} {measure}</>
  }));

  if (!summaryOrder){
    return null;
  }

  const getContainersInfo = () => {
    if (summaryOrder.delivery_type === METHODS.COMBINED_CARGO){

      return [
        {
          title: t('calc__text__delivery__airfreight'),
          price: `${summaryOrder?.volume_weight.avia} ${t('units__text__kg')}`
        },
        {
          title: t('calc__text__delivery__seafreight'),
          price: `${summaryOrder?.volume_weight.sea} ${t('units__text__kg')}`
        },
        {
          title: t('calc__text__delivery__trucking'),
          price: `${summaryOrder?.volume_weight.auto} ${t('units__text__kg')}`
        },
        {
          title: t('calc__text__delivery__railwayFreight'),
          price: `${summaryOrder?.volume_weight.rails} ${t('units__text__kg')}`
        },
      ]

    } else if (summaryOrder.delivery_type === METHODS.CONTAINERS){
      return [
        {
          title: '20 Ft',
          price: summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_20] ? `${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_20]} ${t('units__text__items')}` : '—',
        },
        {
          title: '40 Ft',
          price: summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40] ? `${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40]} ${t('units__text__items')}` : '—',
        },
        {
          title: '40 Ft High cube',
          price: summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] ? `${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE]} ${t('units__text__items')}` : '—',
        }
      ]
    }
  }

  const getBankCommissions = () => {
    return [
      {
        title: t('calc__text__bankCommissions__payments'),
        price: `${summaryOrder?.total_payment_amount} $`
      },
      {
        title: t('calc__text__bankCommissions__finalCommissionOfTheBank'),
        price: `${summaryOrder?.total_banking_commissions} $`
      },
      {
        title: t('calc__text__bankCommissions__finalCurrencyControlCommission'),
        price: `${summaryOrder?.total_currency_control_fees} $`
      },
      {
        title: t('calc__text__bankCommissions__totalToBePaid'),
        price: `${summaryOrder?.total_payment_amount_with_commissions} $`
      },
    ]
  }

  const showWarningNotificationContainers = summaryOrder?.not_enough_space_warning;

  const extraServices = order?.userExtraServices;

  const getAdditionalServices = () => {
    if (!extraServices) {
      return []
    }

    return extraServices.flatMap(extraService =>
      extraService.services.map(service => ({
        title: service.name,
        price: `${service.price} $`
      })));

  }

  const getInvoiceData = (invoice: OrderSummaryInvoicesParamsOutput) => {

    return [ {
      title: t('common__text__netWeight'),
      price: `${invoice.net_weight_total} ${t('units__text__kg')}`,
      additional: getProductInfo(invoice.products, 'net_weight', `${t('units__text__kg')}`)
    },
    {
      title: t('common__text__grossWeight'),
      price: `${invoice.gross_weight_total} ${t('units__text__kg')}`,
      additional: getProductInfo(invoice.products, 'gross_weight', `${t('units__text__kg')}`)
    },
    {
      title: t('common__text__volume'),
      price: <>{`${invoice.volume_total} ${t('units__text__meter')}`}<sup>3</sup></>,
    },
    {
      title: t('common__text__volumetricWeight'),
      price: <>{`${invoice.volume_weight_total} ${t('units__text__kg')}/${t('units__text__meter')}`}<sup>3</sup></>,
      additional: getProductInfo(invoice.products, 'volume_weight', <>{t('units__text__meter')}<sup>3</sup></>)
    },
    {
      title: t('calc__text__summary__netAndGross'),
      price: invoice.net_gross_index
    },
    {
      title: t('calc__text__summary__customsValueIndex'),
      price: t('common__text__detailed'),
      additional: getProductInfo(invoice.products, 'customs_index', t('units__text__kg'))
    },
    {
      title: t('calc__text__summary__theInitialCostOf1KgOfCargo'),
      price: t('common__text__detailed'),
      additional: getProductInfo(invoice.products, 'price_1kg', `USD/${t('units__text__kg')}`)
    },
    {
      title: t('common__text__customsPayments'),
      price: `${invoice.fees_total} USD`,
    },
    {
      title: t('calc__text__summary__customsPaymentsFor1KgOfCargo'),
      price: t('common__text__detailed'),
      additional: getProductInfo(invoice.products, 'fees_1kg', `USD/${t('units__text__kg')}`)
    },
    {
      title: t('common__text__delivery'),
      price: `${invoice.shipg_total} USD`,
    },
    {
      title: t('calc__text__summary__deliveryFor1Kr'),
      price: t('common__text__detailed'),
      additional: getProductInfo(invoice.products, 'delivery_1kg', `USD/${t('units__text__kg')}`)
    },
    {
      title: t('calc__text__summary__totalPricePerPiece'),
      price: t('common__text__detailed'),
      additional: getProductInfo(invoice.products, 'price_unit', 'USD')
    },
    {
      title: t('calc__text__summary__containers'),
      price: t('common__text__detailed'),
      additional: getContainersInfo(),
      notification: showWarningNotificationContainers && <Notification
        type={NOTIFICATION_TYPE.WARNING}
        title={t('common__message__warning')}
        text={t('calc__text__packaging__containersDoNotFitError')}
        sx={{ marginTop: 2 }}
        noCloseButton
      />
    },
    {
      title: t('dictionaries__text__certification__priceManual'),
      price: `${summaryCustoms?.cert_total_manual || summaryCustoms?.cert_total} USD`,
    },
    {
      title: t('common__text__bankCommissions'),
      price: t('common__text__detailed'),
      additional: getBankCommissions()
    },
    {
      title: t('common__text__additional'),
      price: t('common__text__detailed'),
      additional: getAdditionalServices()
    },
    ]
  };

  const handleClick = async () => {
    await updateOrder({
      status: "done",
      id: orderId
    })
    setOpenAlert(true);
    localStorage.removeItem(CALC_ID);
    setTimeout(() => {
      navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATIONS}`, { replace: true });
    }, 1000);
  }

  const getPageMargins = () => `@page { margin: 32px 48px !important; }`

  // @ts-ignore
  const calculationStatusDone = order.status === CalculationStatus.DONE;

  return <>
    <div className='summary-calculator'>
      <Typography variant='h5' sx={{
        display: 'flex', alignItems: 'center', fontWeight: '700', padding: '30px 24px 4px'
      }}>
        <ContentPasteOutlinedIcon
          sx={{
            color: COLORS.LIGHT_TEXT_DISABLED,
            marginRight: 2,
            width: 32,
            height: 33,
          }}/> {t('calc__text__summary__finalCalculationOfCargoDelivery')} №{order.id}
      </Typography>
      <Divider/>
      {summaryInfo?.map((invoice, index) => (
        <div key={index} className='summary-calculator__info'>
          <Accordion sx={{
            marginBottom: 0,
            boxShadow: 'none',
            '&.Mui-expanded:last-of-type': {
              marginBottom: 0
            }
          }} defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              sx={{
                padding: '20px 24px 16px',
                '> div': {margin: '6px 0 !important'},
                '&.Mui-expanded': {
                  borderBottom: 'none'
                }
              }}
            >
              <div className='summary-calculator__title'>
                <div className='summary-calculator__title-left'>
                  <TurnedInOutlinedIcon sx={{color: COLORS.GREEN, marginRight: 2}}/>
                  <Typography variant='h6'>
                    {t('common__text__invoice')} №{invoice.invoice_num + 1}
                  </Typography>
                </div>
                <Typography variant='h6' sx={{marginRight: 3}}>
                  {invoice?.price_total} USD
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{borderTop: `1px solid ${COLORS.SLIGHTLY_GRAY}`, padding: '2px 0 0 0'}}>
              {getInvoiceData(invoice).map((listItem, index) => <SummaryItem item={listItem} key={index}/>)}
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <div className={cx('summary-calculator__buttons', {
        'summary-calculator__buttons-one': calculationStatusDone,
      })}>
        <div className='summary-calculator__title'>
          <Typography variant='h6'>
            {t('common__text__inTotalCaps')}
          </Typography>
          <Typography variant='h6' sx={{marginRight: 1}}>
            {summaryOrder?.total_amount} USD
          </Typography>
        </div>
        {!calculationStatusDone && (
          <Button
            sx={{maxHeight: '42px'}}
            color='secondary'
            startIcon={<SendIcon/>}
            variant='contained'
            fullWidth
            onClick={handleClick}
          >
            {t('calc__text__summary__placeAnOrderForTheDeliveryOfCargo')}
          </Button>
        )}
      </div>
      <div className='summary-calculator__actions'>
        <FormControl
          sx={{width: '344px'}}>
          <InputLabel id='action-select'>{t('common__text__otherActions')}</InputLabel>
          <Select
            labelId='action-select'
            id='action-select'
            value={action}
            label={t('actions__text__selectAction')}
            onChange={({target: {value}}) => setAction(value)}
          >
            <MenuItem
              value={ACTIONS.PRINT}
              onClick={handlePrint}
            >{t('actions__text__print')}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
    <AlertBottom
      open={openAlert}
      onClose={() => setOpenAlert(false)}
      message={t('calc__text__summary__calculationSaved')}
    />
    <div className='summary-calculator__print-version'>
      <PrintOrder ref={componentRef}/>
      <style>{getPageMargins()}</style>
    </div>
  </>
}

export default Summary
