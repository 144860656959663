import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {useTranslation} from "react-i18next";
import {COLORS} from "../../../../../../../config/constants";
import Link from "@mui/material/Link";
import Completed from "../../../../../../common/completed-new";
import {Download} from "../../../../../../../assets/svg";


type Props = {
  isActive?: boolean
  defaultExpanded: boolean
  services: AddService[]
  documents: FreightDocument[]
  handleClickAccordion(value: boolean): void
}

const Services = ({isActive, services, handleClickAccordion, documents, defaultExpanded} : Props) => {
  const { t } = useTranslation()

  const [ isExpanded, setIsExpanded ] = useState(defaultExpanded)

  useEffect(() => {
    setIsExpanded(defaultExpanded)
  }, [ defaultExpanded ])

  const activeServices: AddService[] = [];

  services.map(service => {
    if (!service.id) {
      activeServices.push(service)
    }
  })

  const getCompletedInfo = (services: AddService[]) => {

    return services.map((service) => ({
      label: service.name,
      value: service.price
    }));
  }


  return <Accordion sx={{
    boxShadow: 'none',
    background: isActive ? COLORS.LIME : COLORS.WHITE,
    marginBottom: 0,
    padding: '6px 24px 0',
    '&.Mui-expanded': {
      marginBottom: 3,
    }
  }}
  className='delivery-card__accordion'
  expanded={isExpanded}
  onChange={() => {
    handleClickAccordion(!isExpanded)
    setIsExpanded(!isExpanded)
  }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{
        padding: 0,
        '&.Mui-expanded': {
          borderBottom: 0
        }
      }}
    >
      <Typography variant='body1' sx={{ marginBottom: 1 }}>
        {t('calc__text__delivery__tariffTermsAndAdditionalServices')}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: 0 }}>
      {(activeServices.length > 0) && (
        <Completed
          info={getCompletedInfo(activeServices)}
          isFullRow
        />)}
      {documents.map(document => (
        <Typography variant='body2' sx={{ marginTop: 2, display: 'flex', alignItems: 'center' }} key={document.title}>
          <Download />
          <Link href={document.link} target='_blank' sx={{ marginLeft: 1 }}>{`${t('common__text__download')} ${document.title}`}</Link>
        </Typography>
      ))}
    </AccordionDetails>
  </Accordion>
}

export default Services
