import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const deliveryTimeApi = createApi({
  baseQuery,
  reducerPath: 'deliveryTimeApi',
  endpoints: (builder) => ({
    getDeliveryTime: builder.query<Container[], string>({
      query: () => 'dictionary/v4.0/delivery_time/',
    }),
  }),
})

export const { useLazyGetDeliveryTimeQuery } = deliveryTimeApi
