import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Template from '../../../../../common/templateFilter';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import {useLocations, useLocationTypes} from "../../../../../../api/dictionary";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";

type Props = {
  onChangeLocations(value: string): void
}

const FilterLocation = ({onChangeLocations}: Props) => {

  const { t } = useTranslation()

  const [ locationTypes, setLocationTypes ] = useState<string[]>([]);

  const allLocationTypes = useLocationTypes();
  
  const handleChangeCheckbox = (isChecked: boolean, code: string) => {

    const newLocationTypes = isChecked ? [ ...locationTypes, code ] : locationTypes.filter((location) => location !== code);

    const newParamsForFilter = newLocationTypes.reduce(
      (acc, currentValue) => `${acc}${acc ? ',' : ''}${currentValue}`,
      '',
    );

    setLocationTypes(newLocationTypes);

    onChangeLocations(newParamsForFilter);
  }

  const handleClear = () => {
    setLocationTypes([])
    onChangeLocations('')
  }

  if (!allLocationTypes) {
    return null;
  }

  return (
    <>
      <Template title={t('common__text__type')} hasSeveralValues>
        <FormGroup>
          {allLocationTypes.map((location) => <FormControlLabel
            key={location.code}
            control={<Checkbox defaultChecked checked={locationTypes?.includes(location.code)}/>}
            label={location.name}
            sx={{ marginBottom: '12px' }}
            // @ts-ignore
            onChange={(event) => handleChangeCheckbox(event.target.checked, location.code)}
          />)}
        </FormGroup>
      </Template>
      <Button
        startIcon={<ClearIcon />}
        onClick={handleClear}
        variant='outlined'
        fullWidth
        sx={{
          marginTop: 4,
          marginBottom: 1,
          marginLeft: 3,
          maxWidth: '312px',
        }}
      >{t('actions__text__clearFilters')}</Button>
    </>
  )
}

export default FilterLocation
