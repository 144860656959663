import React from "react";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {getBreadcrumbs} from "./utils";

type Props = {
  menuText: string;
}

const Header = ({ menuText }: Props) => {

  const breadcrumbs = getBreadcrumbs(menuText);

  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize='small' />}
          aria-label='breadcrumb'
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Typography variant='h4' sx={{
        marginBottom: 3,
        marginTop: 3
      }}>
        {menuText}
      </Typography>
    </>
  )
};

export default Header
