import {useTranslation} from "react-i18next";

export const getBankCommissionsInfo = (summaryBankCommissions?: BankCommissionsSummary, totalCommissionPayment?: number) => {

  const { t } = useTranslation();

  if (!summaryBankCommissions) {
    return []
  }

  return [
    summaryBankCommissions.invoices?.map((invoice, index) => (
      {
        label: `${index + 1}. ${t('common__text__invoice')} №${invoice.invoice_num}`,
        value: `${invoice?.invoice_summary?.total_payment_amount_with_commissions} USD`
      }
    )),
    [
      {
        label: t('common__text__inTotal'),
        value: `${totalCommissionPayment} USD`
      }
    ]
  ]

}
