import React, {useEffect} from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  useEffect(() => {
  }, [ ])

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`bankCommission-tabpanel-${index}`}
      aria-labelledby={`bankCommission-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default CustomTabPanel
