import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'
import {useSelector} from "react-redux";
import {rootReducer} from "../store";

export const informersApi = createApi({
  baseQuery,
  reducerPath: 'informersApi',
  endpoints: (builder) => ({
    getInformers: builder.query<InformersParamsOutput, string>({
      query: () => `/dictionary/v4.0/informers/`,
    }),
  }),
})

export const { useLazyGetInformersQuery } = informersApi

export const useInformers = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.informers)
}
