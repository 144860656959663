import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import every from "lodash/every";

import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

import {
  useDeclarationsByOrderIdQuery,
  useDeclarationSummaryByOrderIdQuery,
  useLazyGetOrderSummaryByIdQuery,
  useSetCompletedByOrderIdMutation,
  useSummaryCompleted,
} from "../../../../../api/calc"
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";
import {COLORS} from "../../../../../config/constants";

import CustomsTitle from "./components/customsTitle";
import CustomsDetails from "./components/customsDetails";
import BrokerServices from "./components/broker_services";
import BrokerCommission from "./components/broker_commission";

import './customs.scss'

type Props = {
  handleComplete(): void
}

const Customs = ({handleComplete}: Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()
  const [ getOrderSummaryRequest ] = useLazyGetOrderSummaryByIdQuery()

  const { data: summary, refetch: refetchSummary } = useDeclarationSummaryByOrderIdQuery(orderId)
  const { data: declarations, refetch: refetchDeclaration } = useDeclarationsByOrderIdQuery(orderId)

  const [ isConfirmButtonDisable, setIsConfirmButtonDisable ] = useState(false);

  const summaryCompleted = useSummaryCompleted()
  const declarationsCompleted = every(declarations, [ 'is_completed', true ]);

  if (!declarations) {
    return null;
  }

  const renderOneDeclaration = (declaration: DeclarationsParamsOutput, index: number) => (

    <div className='customs'>
      <Accordion sx={{
        marginBottom: 0,
        border: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
        boxShadow: 'none',
      }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          sx={{padding: '10px 28px 12px'}}
        >
          <CustomsTitle declaration={declaration} index={index}/>
        </AccordionSummary>
        <AccordionDetails className='customs__details'>
          <CustomsDetails
            declaration={declaration}
            index={index}
            setIsConfirmButtonDisable={setIsConfirmButtonDisable}
            refetchSummary={refetchSummary}
            refetchDeclaration={refetchDeclaration}
          />
        </AccordionDetails>
      </Accordion>
    </div>);

  const renderDeclarations = () => declarations.map((declaration, index) => <div key={index}>{renderOneDeclaration(declaration, index)}</div>);

  const handleSaveAll = async () => {
    await setCompleted({
      orderId,
      customs: true,
    })
    refetchDeclaration();
    getOrderSummaryRequest(orderId);
    handleComplete();
  }

  return <>
    {renderDeclarations()}
    <BrokerServices/>
    <BrokerCommission />
    {(!declarationsCompleted || !summaryCompleted?.customs) && <Button
      variant='contained'
      fullWidth
      type='submit'
      startIcon={<CheckIcon />}
      onClick={handleSaveAll}
      disabled={isConfirmButtonDisable}
      sx={{ marginBottom: 3}}
    >{t('actions__text__saveCustoms')}</Button>}
  </>
}

export default Customs
