import {useTranslation} from "react-i18next";
import {getPluralForm} from "../../../../../utils/validation";

export const getCustomsInfo = (summaryCustoms?: DeclarationSummaryParamsOutput | Record<string, never>, declarations?: DeclarationsParamsOutput[]) => {

  const { t } = useTranslation();

  if (!summaryCustoms || !declarations) {
    return []
  }

  const CODES_DECLENSIONS: PluralForms = [ t('declension__text__HSCodeOne'), t('declension__text__HSCodeSeveral'), t('declension__text__HSCodeMany') ];

  return [
    declarations?.map((declaration, index) => (
      {
        label: `ДТ №${index + 1} (${declaration.code_count} ${getPluralForm(declaration.code_count || 0, CODES_DECLENSIONS)})`,
        value: `${declaration.fees_total} USD`
      }
    )),
    [
      {
        label: t('common__text__inTotal'),
        value: `${summaryCustoms.fees_total} USD`
      }
    ]
  ]

}
