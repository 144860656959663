export const numberWithSpaces = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const compareBankCommissions = (a: BankCommissions, b: BankCommissions) => {
  if (!a.id || !b.id) {
    return -1;
  }

  return a.id > b.id ? 1 : -1;
}
