import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const palletesApi = createApi({
  baseQuery,
  reducerPath: 'palletesApi',
  endpoints: (builder) => ({
    getPalletes: builder.query<any, string>({
      query: (query) => `dictionary/v4.0/palletes/`,
    }),
  }),
})

export const { useLazyGetPalletesQuery } = palletesApi
