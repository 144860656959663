import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import { Navigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";
import Mole from './Mole.svg';
import Button from "@mui/material/Button";
import HomeIcon from '@mui/icons-material/Home';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import {ROUTES} from "../../../config/constants";
import {drawEyesCanvasInit, drawEyesCanvasRemove} from "../helpers";
import { leftEye, rightEye, customCoords} from './constants';

import './error-boundary.scss';

type Props = WithTranslation & {
  children: React.ReactNode
}

type State = {
  hasError: boolean;
  redirect: boolean;
};

class ErrorBoundaryComponent extends React.Component<Props, State> {

  state = {
    hasError: false,
    redirect: false
  };

  static getDerivedStateFromError() {
    // update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount(): void {
    drawEyesCanvasInit(50, 36, leftEye, rightEye, 'eyesErrorPage', customCoords);
  }

  componentWillUnmount() {
    drawEyesCanvasRemove(50, 36, leftEye, rightEye, 'eyesErrorPage', customCoords);
  }

  refreshPage = () => {
    window.location.reload();
    this.setState({ hasError: false });
  }


  goToTheMainPage = () => {
    this.setState({ hasError: false, redirect: true });
  };

  render() {

    const { t } = this.props;

    if (this.state.hasError) {
      return <>
        <div className='error-page-content'>
          <Typography variant='h4' sx={{ fontWeight: 500, marginBottom: 5 }}>
            {t('common__text__error')}
          </Typography>
          <canvas id='eyesErrorPage'></canvas>
          <img src={Mole} id='moleImage' className='error-page-content__image' />
          <Button
            variant='contained'
            startIcon={<CachedRoundedIcon />}
            sx={{ marginTop: 8, width: '312px' }}
            onClick={this.refreshPage}
          >
            {t('common__text__reloadPage')}
          </Button>
          <Button
            variant='contained'
            startIcon={<HomeIcon />}
            onClick={this.goToTheMainPage}
            sx={{ marginTop: 4, width: '312px' }}
          >
            {t('common__text__goToTheMainPage')}
          </Button>
        </div>
        {
          this.state.redirect && <Navigate to={ROUTES.CABINET} replace={true}/>
        }
      </>
    }

    return <>{this.props.children}</>;
  }
}


export default withTranslation()(ErrorBoundaryComponent);
