import { createTheme } from '@mui/material/styles'
import COLORS from 'styles/colors.scss'
import "typeface-inter"

const MUI_THEME = createTheme({
  typography: {
    fontFamily: 'Roboto',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Roboto';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          letterSpacing: '0.15px',
        },
        h5: {
          fontWeight: 500,
          color: COLORS.BLACK,
        },
        h4: {
          fontWeight: 500,
          color: COLORS.BLACK,
          letterSpacing: '0.25px',
        },
        body1: {
          letterSpacing: '0.15px',
        },
        body2: {
          color: COLORS.LIGHT_GRAY,
        },
        subtitle1: {
          fontWeight: 500,
        },
        caption: {
          color: COLORS.LIGHT_GRAY,
          letterSpacing: '0.4px',
        },
        overline: {
          letterSpacing: '0.46px',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '.MuiChip-label': {
            letterSpacing: '0.16px',
            paddingLeft: 8,
            paddingRight: 8
          },
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            color: COLORS.LIGHT_GRAY,
            background: COLORS.WHITE,
            '> textarea': {
              padding: 0
            }
          },
          '& .MuiInputLabel-root': {
            fontWeight: 400,
            color: COLORS.LIGHT_GRAY,
            '&.Mui-error': {
              color: COLORS.LIGHT_GRAY,
            }
          },
          '& .MuiOutlinedInput-input': {
            color: COLORS.BLACK,
            backgroundColor: COLORS.WHITE,
          },
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          letterSpacing: '0.15px',
          '&:hover': {
            color: COLORS.BLACK,
          }
        }
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 3,
          '&.Mui-checked svg, &.Mui-checked path': {
            color: COLORS.BLUE,
          },
          '&.Mui-disabled svg, &.Mui-disabled path': {
            color: COLORS.SLIGHTLY_GRAY,
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
          minHeight: '42px',
          letterSpacing: '1px',
          ':hover': {
            background: COLORS.BLUE_DARK,
            boxShadow: 'none',
          },
          '&.MuiButton-outlined:hover': {
            background: 'rgba(25, 118, 210, 0.04)',
          },
          '&.MuiButton-textSecondary:hover': {
            background: 'rgba(25, 118, 210, 0.04)',
          },
          '&.MuiButton-textError:hover': {
            background: 'rgba(211, 47, 47, 0.04)',
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 24px',
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            borderRadius: '8px',
          },
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: COLORS.WHITE,
          padding: '32px 24px',
        }
      }
    },
    MuiInputAdornment: { styleOverrides: { root: {
      marginRight: -2,
    } } },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 6,
        },
        label: { fontSize: 14, lineHeight: '24px', marginLeft: 0, fontWeight: 400 }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '100%',
            borderBottom: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
          },
          '.Mui-expanded.MuiAccordionSummary-content': {
            margin: '12px 0',
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent'
          }
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: 16,
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 24,
            ':before': {
              opacity: 1,
            }
          },
          ':last-of-type': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          },
          marginBottom: 24,
          '&.Mui-expanded:last-of-type': {
            marginBottom: 24,
          },
          '&:first-of-type': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          '&.Mui-disabled': {
            backgroundColor: COLORS.WHITE,
            '.MuiAccordionSummary-expandIconWrapper': {
              display: 'none'
            }
          }
        },
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '24px 24px 24px',
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '7.5px 4px 7.5px 12px',
          }
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          width: '100%',
          minWidth: '100%',
          minHeight: '46px',
          button: {
            fontSize: 14,
            letterSpacing: '0.4px'
          },
          position: 'relative',
          ':after': {
            background: COLORS.SLIGHTLY_GRAY,
            position: 'absolute',
            content: '""',
            bottom: 0,
            width: '100%',
            height: '1px',
          }
        },
        flexContainer: {
          display: 'block'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: COLORS.LIGHT_GRAY,
          flex: 1,
          maxWidth: 'unset'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            minHeight: 48,
            '&:hover': {
              background: COLORS.LIGHT_BLUE,
              color: COLORS.BLUE,
            }
          },
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            borderRadius: 0,
            color: COLORS.BLACK,
            fontWeight: 500
          },
          '& .MuiInputLabel-root': {
            fontWeight: 400,
            color: COLORS.LIGHT_GRAY,
            overflow: 'visible',
            '&.Mui-error': {
              color: COLORS.LIGHT_GRAY,
            }
          },
          '& .MuiOutlinedInput-input': {
            color: COLORS.BLACK,
            padding: '16.5px 13px',
          },
          '& .MuiInputBase-root': {
            borderRadius: 0,
            backgroundColor: COLORS.WHITE,
          },
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiBackdrop-root': {
            backgroundColor: COLORS.LIGHT_GRAY,
          },
          '.MuiDialog-container': {
            overflowX: 'auto'
          },
          '.MuiDialog-paperScrollBody': {
            width: '100%',
            // maxWidth: '736px',
            borderRadius: 0,
            boxShadow: '0 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
          },
          '.MuiDialog-paperWidthXl': {
            width: '1120px',
            minWidth: '1120px'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '24px',
          fontWeight: '500',
          padding: '24px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background:COLORS.BLUE_GRAY,
          '&.MuiDialogContent-root' :{
            padding: '0 24px 24px 24px'
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: { color: COLORS.BLUE },
        root: {
          width: 50,
          paddingLeft: '6px',
          '& .MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb':
              {
                color: COLORS.WHITE,
                boxShadow: '0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19)'
              },
          '& .MuiSwitch-switchBase.Mui-checked) .MuiSwitch-thumb':
              {
                boxShadow: 'none'
              },
          '& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track':
              {
                backgroundColor: COLORS.BLACK,
                opacity: 0.38,
              },
          '& .MuiSwitch-switchBase':
              {
                paddingLeft: 0,
              },
          '& .MuiSwitch-switchBase.Mui-checked':
              {
                transform: 'translateX(22px)',
              },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: { root: {
        position: 'absolute',
        lineHeight: 1.2,
        bottom: '-20px',
        background: 'inherit',
        padding: '0 4px',
      }}
    },
    MuiRating: {
      styleOverrides: {
        root: {
          '& .MuiRating-iconFilled': {
            color: COLORS.YELLOW,
          },
          '& .MuiRating-iconEmpty': {
            color: 'rgba(0, 0, 0, 0.54)',
          }
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '.MuiPagination-ul': {
            justifyContent: 'center'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '@media screen and (min-width: 600px)': {
            paddingLeft: '12px',
            paddingRight: '12px'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        }
      }
    },
    MuiRadio: { styleOverrides: { root: { padding: '9px 10px 9px 9px' } } },
    MuiInputLabel: { styleOverrides: { root: { color: COLORS.GRAY, fontWeight: 500 } } },
    MuiTooltip: { styleOverrides: {
      tooltip: {
        backgroundColor: COLORS.DARK_GRAY_TOOLTIP,
        fontSize: 14,
        lineHeight: '20px',
        paddingTop: 12,
        paddingRight: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        maxWidth: 500,
        p: { color: COLORS.WHITE }
      },
      arrow: {
        color: COLORS.DARK,
      }
    } },
    MuiToggleButtonGroup: {
      styleOverrides: { root: { maxHeight: 32, button: { flex: 1 } }, }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${COLORS.BORDER_GRAY}`,
          '&.Mui-selected': { backgroundColor: COLORS.ORANGE, color: COLORS.WHITE }
        },
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&.MuiAlert-standardError': {
            background: COLORS.DARK_RED,
            color: COLORS.WHITE,
            'svg' : { fill: COLORS.WHITE }
          },
          '.MuiIconButton-root': {
            padding: '4px 12px'
          },
          '&.MuiAlert-standardWarning': {
            background: COLORS.ORANGE,
            color: COLORS.WHITE,
            'svg' : { fill: COLORS.WHITE }
          },
          '&.MuiAlert-standardInfo': {
            background: COLORS.BLUE_DARK,
            color: COLORS.WHITE,
            'svg' : { fill: COLORS.WHITE }
          },
        },
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '.MuiPaper-rounded': {
            borderRadius: 0,
          },
          '.MuiList-root': {
            padding: 0,
          },
        },
      }
    }
  },
  palette: {
    primary: { main: COLORS.BLUE },
    secondary: { main: COLORS.BLUE },
    info: { main: COLORS.WHITE },
    error: { main: COLORS.RED },
  },
})

export default MUI_THEME
