import React, {useEffect, useState} from "react";
import { useForm, FormProvider } from "react-hook-form";
import {useTranslation} from "react-i18next";
import get from "lodash/get";

import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";

import IncotermInput from '../invoiceFields/incotermsInput'
import LocationInput from '../invoiceFields/locationInput'
import DateInput from '../invoiceFields/dateInput'

import {useGetCalcIdFromRoute} from "../../../../../../../../../../../utils/hooks";
import {
  useCreateInvoiceMutation,
  useCreateProductMutation, useInvoicesByOrderIdQuery, useProductsByInvoiceIdQuery,
  useSummaryByOrderIdQuery,
  useUpdateInvoiceMutation,
} from "../../../../../../../../../../../api/calc";
import Notification, {NOTIFICATION_TYPE} from "../../../../../../../../../../common/notification";
import {INFORMERS_CODES} from "../../../../../../../../../../../config/constants";
import { useLazyGetCityQuery } from "../../../../../../../../../../../api/dictionary";

import Tooltip from "../../../../../../../../../../common/tooltip";
import {useInformers} from "../../../../../../../../../../../api/informers";

import AddForm from "../products/components/add_form";
import {PackageForm} from "../products/components/add_form/helpers";

import './editInvoice.scss'

export const enum INVOICE_FORM {
  DESCRIPTION = 'description',
  INVOICE_NUM = 'invoice_num',
  INVOICE_DATE = 'invoice_date',
  INCOTERMS = 'incoterms',
  LOCATION_CITY = 'location_city',
}

export type InvoiceForm = {
  [INVOICE_FORM.DESCRIPTION]: string
  [INVOICE_FORM.INVOICE_NUM]: string
  [INVOICE_FORM.INVOICE_DATE]: string
  [INVOICE_FORM.INCOTERMS]: string
  [INVOICE_FORM.LOCATION_CITY]: string
}

type Props = {
  handleSuccess: () => void
  noBorder?: boolean
  hasCancelButton?: boolean
  isFirstInvoice?: boolean
  currentInvoice?: InvoiceParamsOutput
  onClose?(): void
  onCloseInvoiceForm?(): void
}

type OptionData = {
  label: string
}

type LocationItemDefaultCity = LocationItem & OptionData;

const EditInvoice = ( { handleSuccess, currentInvoice, onCloseInvoiceForm, isFirstInvoice, onClose } : Props ) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute();

  const [ defaultCity, setDefaultCity ] = useState<LocationItemDefaultCity>()

  const defaultCityName = currentInvoice?.[INVOICE_FORM.LOCATION_CITY]

  useEffect(() => {
    if (defaultCityName){
      setDefaultCity({...defaultCityName, label: `${defaultCityName.name}, ${defaultCityName.country.name}`})
    }
  }, [ defaultCityName ])

  const [ showErrorNotificationProduct, setShowErrorNotificationProduct ] = useState<boolean | undefined>(false);
  const [ showErrorNotificationInvoice, setShowErrorNotificationInvoice ] = useState<boolean | undefined>(false);

  const defaultValues = {
    [INVOICE_FORM.DESCRIPTION]: currentInvoice?.[INVOICE_FORM.DESCRIPTION] || '',
    [INVOICE_FORM.INVOICE_NUM]: currentInvoice?.[INVOICE_FORM.INVOICE_NUM] || '',
    [INVOICE_FORM.INVOICE_DATE]: currentInvoice?.[INVOICE_FORM.INVOICE_DATE] || '',
    [INVOICE_FORM.INCOTERMS]: currentInvoice?.[INVOICE_FORM.INCOTERMS] || '',
    [INVOICE_FORM.LOCATION_CITY]: currentInvoice?.[INVOICE_FORM.LOCATION_CITY].id || '',
  };

  const methods = useForm<InvoiceForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  })

  const { register, reset, getValues, watch, handleSubmit, formState: { errors, isValid } } = methods

  const [ createInvoice ] = useCreateInvoiceMutation()

  const [ updateInvoice ] = useUpdateInvoiceMutation()

  const [ createProduct ] = useCreateProductMutation()

  const { refetch: refetchSummary } = useSummaryByOrderIdQuery(orderId)
  const { refetch: refetchInvoices } = useInvoicesByOrderIdQuery(orderId, {skip: !orderId})
  const { refetch: refetchProductsByInvoiceId } = useProductsByInvoiceIdQuery(currentInvoice?.id || 0, {skip: !currentInvoice?.id})

  const onSubmit = handleSubmit(async values => {

    if (!currentInvoice){
      setShowErrorNotificationInvoice(true);
      return;
    }

    const result = await updateInvoice({ orderId, invoiceId: currentInvoice?.id, ...values})

    const invoiceId = get(result, 'data.id', '')

    if (!invoiceId) {
      setShowErrorNotificationInvoice(true);
      return
    }
    setShowErrorNotificationInvoice(false);
    handleSuccess()
    reset()
  })

  const createNewInvoice = async (data: PackageForm) => {

    const values = getValues();

    const result = await createInvoice({ id: orderId, ...values})

    const invoiceId = get(result, 'data.id', '')

    if (!invoiceId) {
      return
    }

    const productResult = await createProduct({ invoiceId: invoiceId, ...data})

    // @ts-ignore
    const errorData = productResult?.error?.data
    const productId = get(productResult, 'data.id', '')

    if (!productId || errorData?.non_field_errors) {
      setShowErrorNotificationProduct(errorData?.non_field_errors?.[0] || true);
      return
    }
    setShowErrorNotificationProduct(false);
    handleSuccess()
    refetchSummary()
    onCloseInvoiceForm && onCloseInvoiceForm()
    reset()
  }

  const informers = useInformers();

  const watchAllFields = watch()

  const saveButtonIsActive = Boolean(watchAllFields[INVOICE_FORM.INVOICE_NUM])
  && Boolean(watchAllFields[INVOICE_FORM.INVOICE_DATE])
  && watchAllFields[INVOICE_FORM.INVOICE_DATE] !== 'Invalid date'
  && Boolean(watchAllFields[INVOICE_FORM.INCOTERMS])
  && Boolean(watchAllFields[INVOICE_FORM.LOCATION_CITY])

  const handleSuccessAddedProduct = () => {
    refetchInvoices()
    refetchProductsByInvoiceId();
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        {!currentInvoice && <Notification
          type={NOTIFICATION_TYPE.INFO}
          title={t('calc__text__main__invoiceDetails')}
          text={t('calc__text__main__invoiceDetailsNotification')}
          sx={{ marginBottom: 3}}
        />}
        <div className='invoice-form'>
          <TextField
            {...register(INVOICE_FORM.DESCRIPTION, { required: true}) }
            error={Boolean(errors[INVOICE_FORM.DESCRIPTION])}
            helperText={errors[INVOICE_FORM.DESCRIPTION]?.message}
            variant='outlined'
            label={t('calc__text__main__descriptionOfTheGoods')}
            className={`invoice-form__${INVOICE_FORM.DESCRIPTION}`}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            {...register(INVOICE_FORM.INVOICE_NUM, { required: true}) }
            error={Boolean(errors[INVOICE_FORM.INVOICE_NUM])}
            helperText={errors[INVOICE_FORM.INVOICE_NUM]?.message}
            variant='outlined'
            label={t('calc__text__main__numOfInvoice')}
            className={`invoice-form__${INVOICE_FORM.INVOICE_NUM}`}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position='end' sx={{ right: '20px', position: 'absolute'}}><Tooltip content={informers?.[INFORMERS_CODES.INVOICE_NUM]} /></InputAdornment>
            }}
          />
          <DateInput />
          <IncotermInput/>
          {defaultCityName && defaultCity && <LocationInput defaultCity={defaultCity} />}
          {!defaultCityName && <LocationInput />}
        </div>
        {!currentInvoice && (<Typography variant='h6' className='invoice__title' sx={{ marginBottom: 3 }}>
          {t('common__text__products')}
        </Typography>)}
        {currentInvoice && (
          <>
            <div className='main-form__buttons'>
              <Button
                variant='outlined'
                sx={{ minWidth: '212px', marginRight: 3}}
                onClick={onClose}
              >{t('actions__text__cancel')}</Button>
              <Button
                variant='contained'
                type='submit'
                disabled={!saveButtonIsActive}
                startIcon={<CheckIcon />}
                sx={{ minWidth: 452}}
              >{t('actions__text__saveInvoice')}</Button>
            </div>
            {showErrorNotificationInvoice && <Notification type={NOTIFICATION_TYPE.ERROR} title={t('errors__message__save')} text={t('errors__message__tryLater5Min')} sx={{ marginTop: 4 }} /> }
          </>
        )}
        {isFirstInvoice &&
            <AddForm
              createInvoice={createNewInvoice}
              showErrorNotificationProduct={showErrorNotificationProduct}
              handleSuccess={handleSuccessAddedProduct}
              handleRemove={onCloseInvoiceForm}
            />}
      </form>
    </FormProvider>
  )
}

export default EditInvoice
