import React from "react";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Table from '../../../components/table';
import TableRowActions from '../../../components/table_row_actions';

import isArray from 'lodash/isArray'

import './table.scss';

import {useCities } from "../../../../../../api/dictionary";

import { ACTIONS } from "../../../constants";


type Props = {
  selectedRows?: string[];
  currentCities?: string[]
  handleEdit(): void
  handleRemove(): void
  handleCitiesRequest(data?: DictionaryParamsInput): void
  changeCity(id: string[]): void
}

const CityTable = ({ handleEdit, handleRemove, handleCitiesRequest, changeCity, currentCities }: Props) => {

  const { t } = useTranslation()

  const cities = useCities()?.data;

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('common__text__city'),
    },
  ];

  const preferences = [
    {
      id: 'name',
      width: 500,
    },
  ];

  if (!isArray(cities.results)){
    return null;
  }

  return (
    <div className='city-table'>
      <Table
        rows={cities.results}
        count={cities.count}
        headCells={headCells}
        preferences={preferences}
        chooseItem={changeCity}
        primaryField='id'
        handleSendRequest={handleCitiesRequest}
        hasEmptySelection={!currentCities?.length}
        actionsAfterTable={(selected: string[]) =>
          <TableRowActions hasEmptySelection={!selected.length}>
            {selected.length === 1 && <MenuItem
              value={ACTIONS.EDIT}
              onClick={handleEdit}
            >{t('actions__text__edit')}</MenuItem>}
            <MenuItem
              value={ACTIONS.REMOVE}
              onClick={handleRemove}
            >{t('actions__text__delete')}</MenuItem>
          </TableRowActions>}
      />
    </div>
  )
};

export default CityTable
