import {useTranslation} from "react-i18next";
import React from "react";
import moment from "moment";
import Logo from './../images/logo.svg';
import { getPluralForm } from "../../../utils/validation";
import Typography from "@mui/material/Typography";

import {COLORS} from "../../../config/constants";

import './order.scss';
import {useAdditionalServices, useCurrentRoute, useOrder, useSummary, useSummaryInvoices} from "../../../api/calc";
import {CONTAINERS_FORM, METHODS} from "../../cabinet/calculator/components/invoices-and-products/components/summary";
import {useUser} from "../../../api/auth";

type List = {
  label: string
  value: React.ReactNode
}

const PrintOrder = React.forwardRef((props, ref) => {

  const { t } = useTranslation()

  const order = useOrder();
  const user = useUser();

  const summaryOrder = useSummary();
  const summaryDelivery = useCurrentRoute();
  const invoices = order.invoices;
  const productsByInvoices = useSummaryInvoices();
  const additionalServices = useAdditionalServices();

  const getDeliveryType = () => {
    if (summaryOrder?.delivery_type === METHODS.COMBINED_CARGO) {
      return  `${t('calc__text__packaging__combinedCargo')}: ${t('calc__text__delivery__airfreight')} — ${summaryOrder?.volume_weight.avia} ${t('units__text__kg')},
          ${t('calc__text__delivery__seafreight')} — ${summaryOrder?.volume_weight.sea} ${t('units__text__kg')},
          ${t('calc__text__delivery__trucking')} — ${summaryOrder?.volume_weight.auto} ${t('units__text__kg')},
          ${t('calc__text__delivery__railwayFreight')} — ${summaryOrder?.volume_weight.rails} ${t('units__text__kg')},`
    }
    else if (summaryOrder?.delivery_type === METHODS.CONTAINERS){

      const ft20 = `20 Ft — ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_20]} ${t('units__text__items')}, `
      const ft40 = `40 Ft — ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40]} ${t('units__text__items')}, `
      const ft40hc = `40 Ft High cube — ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE]} ${t('units__text__items')}`

      return `${t('calc__text__packaging__containers')}: ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_20] ? ft20 : ''} 
      ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40] ? ft40 : ''}
      ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] ? ft40hc : ''}`
    }
  }

  const DAYS_DECLENSIONS: PluralForms = [ t('declension__text__daysOne'), t('declension__text__daysSeveral'), t('declension__text__daysMany') ];

  const getInvoiceData = () => invoices ? invoices.map((invoice) => ({
    label: `${t('common__text__invoice')} №${invoice.invoice_num}`,
    value: `${invoice.price_total} USD`
  })): []

  const commonOrderData = [
    [
      {
        label: t('common__text__netGrossWeight'),
        value: `${summaryOrder?.net_weight_total} ${t('units__text__kg')} / ${summaryOrder?.gross_weight_total} ${t('units__text__kg')}`
      },
      {
        label: t('common__text__loading'),
        value: getDeliveryType()
      },
      {
        label: t('common__text__cargoVolume'),
        value: <>{summaryOrder?.volume_weight_total} {t('units__text__meter')}<sup>3</sup></>
      }
    ],
    [
      {
        label: t('calc__text__delivery__routeNumber'),
        value: summaryDelivery?.code
      },
      {
        label: t('print__text__transportCompany'),
        value: summaryDelivery?.freights ? summaryDelivery.freights.reduce((arr: string, fright: Freight) => `${arr ? `${arr}, ` : ''}${fright.delivery_company.name}`, '') : '',
      },
      {
        label: t('print__text__costOfTransportationIsRelevant'),
        value: moment(summaryDelivery?.price_expired_date).format('Do MMMM YYYY')
      },
      {
        label: t('print__text__route'),
        value: `${summaryDelivery?.location_from?.name} → ${summaryDelivery?.location_to?.name}`
      },
      {
        label: t('print__text__estimatedDeliveryTime'),
        value: summaryDelivery?.min_days && summaryDelivery?.max_days ? `${summaryDelivery?.min_days}-${summaryDelivery?.max_days}  ${getPluralForm(summaryDelivery.max_days, DAYS_DECLENSIONS)}` : '—',
      }
    ],
    [
      {
        label: t('calc__text__bankCommissions__payments'),
        value: `${summaryOrder?.total_payment_amount} $`,
      },
      {
        label: t('calc__text__bankCommissions__finalCommissionOfTheBank'),
        value: `${summaryOrder?.total_banking_commissions} $`,
      },
      {
        label: t('calc__text__bankCommissions__finalCurrencyControlCommission'),
        value: `${summaryOrder?.total_currency_control_fees} $`
      },
      {
        label: t('calc__text__bankCommissions__totalToBePaid'),
        value: `${summaryOrder?.total_payment_amount_with_commissions} $`
      },
    ]
  ]

  const getInvoiceCommonData = (invoice: InvoiceParamsOutput) => {

    const hasPickupIncoterms = [ 'exw', 'fca' ];

    const commonData = [
      {
        label: t('calc__text__main__incoterms'),
        value: invoice.incoterms
      },
      {
        label: t('common__text__dateOfShipment'),
        value: `${t('common__text__before')} ${moment(invoice?.invoice_date).format('Do MMMM YYYY')}`
      },
    ]

    if (hasPickupIncoterms.includes(invoice.incoterms)){
      return [ ...commonData, {
        label: t('common__text__pickup'),
        value: `${invoice?.location_city}`,
      } ]
    }

    return commonData;
  }

  const getProductInfo = (product: OrderSummaryProduct) => [
    {
      label: t('declension__text__HSCodeOne'),
      value: product.code
    },
    {
      label: t('calc__text__packaging__countryOfOrigin'),
      value: product.country_data.name
    },
    {
      label: t('calc__text__packaging__volumetricWeight'),
      value: <>{product.volume_weight} {t('units__text__meter')}<sup>3</sup></>
    },
    {
      label: `${t('common__text__netWeight')} / ${t('common__text__grossWeight')}`,
      value: `${product.net_weight} ${t('units__text__kg')} / ${product.gross_weight} ${t('units__text__kg')}`
    },
    {
      label: t('common__text__volume'),
      value: <>{product?.volume_total} {t('units__text__meter')}<sup>3</sup></>
    },
    {
      label: t('calc__text__summary__theInitialCost'),
      value: `${product?.price_total} USD`
    },
    {
      label: t('calc__text__summary__customsPaymentsFor1KgOfCargo'),
      value: `${product?.fees_1kg} USD`
    },
    {
      label: t('calc__text__summary__deliveryFor1Kr'),
      value: `${product?.delivery_1kg} USD`
    },
    {
      label: t('calc__text__summary__totalPricePerPiece'),
      value: `${product?.price_unit} USD`
    },
  ]

  const getUserServices = () => {
    const userServices: { label: string, value: string }[] = []
    const potentialCosts: { label: string, value: string }[] = []

    additionalServices?.map((service: AdditionalService) => {
      if (!service.id) {
        potentialCosts.push({
          label: service.name,
          value: `${service?.cost} ${service?.currency}`
        })
      } else {
        // @ts-ignore
        if (summaryDelivery?.selected_services.includes(service.id)) {
          userServices.push({
            label: service.name,
            value: `${service?.cost} ${service?.currency}`
          })
        }
      }
    })

    return <>
      {potentialCosts.length > 0 && (
        <table className='print__table'>
          <thead>
            <tr>
              <td>
                <Typography variant='subtitle2'>
                  {t('calc__text__additional__possibleAdditionalCosts')}
                </Typography>
              </td>
              <td>
                <Typography variant='subtitle2'>
                  {t('common__text__cost')}
                </Typography>
              </td>
            </tr>
          </thead>
          <tbody className='print__tbody'>{renderTable(potentialCosts)}</tbody>
        </table>
      )}
      {userServices.length > 0 && (
        <table className='print__table'>
          <thead>
            <tr>
              <td>
                <Typography variant='subtitle2'>
                  {t('common__text__additionalServices')}
                </Typography>
              </td>
              <td>
                <Typography variant='subtitle2'>
                  {t('common__text__cost')}
                </Typography>
              </td>
            </tr>
          </thead>
          <tbody className='print__tbody'>{renderTable(userServices)}</tbody>
        </table>
      )}
    </>
  }

  const renderTwoRows = (item: List) => <li className='print__item' key={item.label}>
    <Typography variant='caption' className='print__item-label'>
      {item.label}
    </Typography>
    <Typography variant='subtitle2' className='print__value'>
      {item.value}
    </Typography>
  </li>

  const renderTable = (data: List[]) => data.map((item) => (
    <tr key={item.label}>
      <td>
        <Typography variant='body2' color={COLORS.BLACK} sx={{ fontSize: '12px' }}>
          {item.label}
        </Typography>
      </td>
      <td>
        <Typography variant='body2' color={COLORS.BLACK} sx={{ fontSize: '12px' }}>
          {item.value}
        </Typography>
      </td>
    </tr>
  ))

  const getProductData = (invoiceId: number) => productsByInvoices?.find((productByInvoice) =>
    productByInvoice.invoice_id === invoiceId)

  return (
    // @ts-ignore
    <div className='print' ref={ref}>
      <img src={Logo} alt='Dita' className='print__image' />
      <hr className='print__hr'/>
      <div>
        <ul className='print__list'>
          <li className='print__item'>
            <Typography variant='caption' className='print__item-label'>
              {t('common__text__client')}
            </Typography>
            <Typography variant='subtitle2' className='print__value'>
              {`${user.first_name} ${user.last_name}, ${user.phone}, ${user.email}`}
            </Typography>
          </li>
          <li className='print__item'>
            {getInvoiceData().map((invoiceData) => <div className='print__subItem' key={invoiceData.label}>
              <Typography variant='caption' className='print__item-label'>
                {invoiceData.label}
              </Typography>
              <Typography variant='subtitle2' className='print__value'>
                {invoiceData.value}
              </Typography>
            </div>)}
            <Typography variant='caption' className='print__item-label'>
              {t('common__text__totalCost')}
            </Typography>
            <Typography variant='subtitle2' className='print__value'>
              {`${summaryOrder?.total_amount} USD`}
            </Typography>
          </li>
        </ul>
        {commonOrderData.map((orderStep, index) => (<ul className='print__list' key={index}>
          {orderStep.map((data) => renderTwoRows(data))}
        </ul>))}
      </div>
      {getUserServices()}
      <hr className='print__hr-small'/>
      {invoices?.map((invoice, index) => {
        const products = getProductData(invoice.id)?.products;
        return (
          <div key={invoice.id}>
            <div className='print__invoice'>
              <div className='print__invoice-container'>
                <Typography variant='h5'>
                  {`${t('common__text__invoice')} ${index + 1} ${t('common__text__of')} ${invoices.length} — ${invoice.price_total} USD`}
                </Typography>
                <Typography variant='body1'>
                    № {invoice.invoice_num}
                </Typography>
                <ul className='print__list'>
                  {getInvoiceCommonData(invoice).map((data) => renderTwoRows(data))}
                </ul>
              </div>
            </div>
            {products?.map((product, index) => <table className='print__table print__table_product' key={index}>
              <thead>
                <tr>
                  <td>
                    <Typography variant='subtitle2'>
                      {`${t('common__text__addItem')} ${index + 1} ${t('common__text__of')} ${invoice?.products?.length}`}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant='subtitle2'>
                      {product.name}
                    </Typography>
                  </td>
                </tr>
              </thead>
              <tbody>
                {renderTable(getProductInfo(product))}
              </tbody>
            </table>)
            }
          </div>
        )
      })}
    </div>
  )
})

export default PrintOrder
