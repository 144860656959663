import React from "react";

import {useTranslation} from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import isEmpty from 'lodash/isEmpty'

import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";

import PaymentItem from '../payment-item';
import PaymentTabs from '../payment-tabs';
import CustomTabPanel from '../custom-tab-panel';

import {
  useUpdateBankCommissionsMutation,
  useBankCommissionsByInvoiceId,
  useSetCompletedByOrderIdMutation,
} from "../../../../../../../../../../../api/calc";

import { NOTIFICATION_TYPE} from "../../../../../../../../../../common/notification";
import Notification from "../../../../../../../../../../common/notification";
import AlertBottom from "../../../../../../../../../../common/alertBottom";

import {useGetCalcIdFromRoute} from "../../../../../../../../../../../utils/hooks";
import { PaymentsType } from '../../index';

type Props = {
    invoiceNumber: string
    invoiceAmount: number
    invoiceId: string
    initialValues: any
    refetch(): void
}

const NewPayments = ({ invoiceNumber, invoiceAmount, invoiceId, refetch, initialValues }: Props) => {

  const { t } = useTranslation()

  const [ openAlert, setOpenAlert ] = React.useState(false);
  const [ errorText, setErrorText ] = React.useState('');
  const [ formIsReady, setFormIsReady ] = React.useState(false);

  const [ tabValue, setTabValue ] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [ saveBankCommissions ] = useUpdateBankCommissionsMutation()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const orderId = useGetCalcIdFromRoute()

  const {  control, handleSubmit, getValues, watch } = useFormContext<PaymentsType>()

  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: 'payments',
  });

  const allFieldsFilled = (item: any) => Object.values(item)?.every((value) => value !== '');

  const allItemsFilled = () => {
    const allPayments = getValues().payments;
    return allPayments.every((payment) => allFieldsFilled(payment));
  };

  const handleClickNewButton = () => {
    setTabValue(fields.length);
  }

  watch(() => {
    setFormIsReady(allItemsFilled());
  });

  const invoiceBankCommissions = useBankCommissionsByInvoiceId(invoiceNumber);

  const isCompletedInvoice = !isEmpty(invoiceBankCommissions?.banking_commissions);

  const onSubmit = handleSubmit (async (values) => {

    try {

      const response: any = isCompletedInvoice ?
        invoiceBankCommissions && await saveBankCommissions({
          invoiceId,
          payments: invoiceBankCommissions.banking_commissions
        }) :
        await saveBankCommissions({invoiceId, payments : values.payments})

      if (response?.error) {
        setErrorText(response.error.data.error || t("common__text__error"));
      } else {
        setErrorText('')
        setOpenAlert(true)
        refetch()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setCompleted({
        orderId,
        banking_commissions: false,
        additional_services: false
      })
    }
  })

  const handleRemovePaymentFromArray = (index: number) => {

    if (fields.length == index + 1) {
      setTabValue(fields.length - 2);
    }

    remove(index);
  }

  return (
    <>
      <div className='payments-tabs'>
        <PaymentTabs
          tabValue={tabValue}
          handleChange={handleChange}
          fields={fields}
          getValues={getValues}
          append={append}
          initialValues={initialValues}
          onClickNewButton={handleClickNewButton}
          shouldAppendItem
        />
        {fields.map((field, index) => {
          const isFirstItem = index === 0;
          return (
            <CustomTabPanel value={tabValue} index={index} key={index}>
              <PaymentItem
                control={control}
                index={index}
                isFirstItem={isFirstItem}
                invoiceAmount={invoiceAmount}
                remove={fields.length == 1 ? undefined : handleRemovePaymentFromArray}
              />
            </CustomTabPanel>
          )
        })}
      </div>
      {<Button
        variant='contained'
        fullWidth
        startIcon={<CheckIcon />}
        disabled={!isCompletedInvoice && !formIsReady}
        onClick={onSubmit}
        sx={{ marginTop: 3 }}
      >{`${t('calc__text__bankCommissions__savePayments')} №${invoiceNumber}` }</Button>}
      {errorText && <Notification
        type={NOTIFICATION_TYPE.WARNING}
        title={t('common__message__warning')}
        text={errorText}
        sx={{ marginTop: 3 }}
        noCloseButton
      />}
      {openAlert && (
        <AlertBottom
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          message={t('common__message__dataSavedSuccessfully')}
        />
      )}
    </>
  );
}

export default NewPayments
