import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import './footer.scss'

const Footer = () => {

  const { t } = useTranslation()

  return (
    <footer className='footer'>
      {/*<div className='footer__logo'>*/}
      {/*  <Link href='https://n.systems/' target='_blank' sx={{ textAlign: 'center' }} className='footer__link'>Developed by</Link>*/}
      {/*  <LogoNSystems />*/}
      {/*</div>*/}
    </footer>
  )
}

export default Footer
