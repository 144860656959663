import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Logo } from "../../../assets/svg";

import './welcome.scss'

import {useTranslation} from "react-i18next";
import {COLORS} from "../../../config/constants";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Welcome = () => {

  const { t } = useTranslation()

  return (
    <div className='welcome'>
      <Logo />
      <div className='welcome__container'>
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: COLORS.BLUE }}
              aria-label='recipe'
            >
              {t('common__text__langRu')}
            </Avatar>
          }
          title={<Typography variant='subtitle1'>
            {t('common__text__interfaceRu')}
          </Typography>}
          sx={{
            boxShadow: `0 0 0 1px ${COLORS.DARK_GRAY}`,
          }}
          action={
            <IconButton aria-label='ru' sx={{ color: 'rgba(0, 0, 0, 0.54)', padding: '12px 0'}}>
              <ChevronRightIcon />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: COLORS.BLUE }}
              aria-label='recipe'
            >
              {t('common__text__langEn')}
            </Avatar>
          }
          title={<Typography variant='subtitle1'>
            {t('common__text__interfaceEn')}
          </Typography>}
          sx={{
            boxShadow: `0 0 0 1px ${COLORS.DARK_GRAY}`,
            marginTop: 4,
          }}
          action={
            <IconButton aria-label='ru' sx={{ color: 'rgba(0, 0, 0, 0.54)', padding: '12px 0'}}>
              <ChevronRightIcon />
            </IconButton>
          }
        />
      </div>
    </div>
  )
}

export default Welcome
