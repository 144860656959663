import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {CALC_ID, calcApi} from "./calc";
import { createSlice } from '@reduxjs/toolkit'
import {REHYDRATE} from "redux-persist";
import {useSelector} from "react-redux";
import {rootReducer} from "../store";
import {USER_GROUPS} from "../utils/constants";

export const API_AUTH = "https://api.dita.systems";
export const AUTH_TOKEN = "authToken";
export const BEARER = "Bearer";

export const getToken = () => localStorage.getItem(AUTH_TOKEN);

export const setToken = (token: string) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(CALC_ID);
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_AUTH,
    prepareHeaders: (headers) => {
      const token = getToken()
      if (token) {
        headers.set('authorization', `${BEARER} ${token}`)
      }
      return headers
    }
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) return action.payload[reducerPath]
  },
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInOutput, SignInput>({
      query: (body) => ({url: '/auth/token/', method: 'POST', body}),
    }),
    signUp: builder.mutation<SignInOutput, SignUpInput>({
      query: (body) => ({url: 'auth/register/', method: 'POST', body}),
    }),
    getUser: builder.mutation<MeParamsOutput, unknown>({
      query: () => ({url: 'users/me/'}),
    }),
    updateUserInfo: builder.mutation<UpdateUserInfoParamsOutput, UpdateUserInfoParamsOutput>({
      query: (body) => ({ url: `users/update/`, method: 'POST', body }),
    }),
    resetPassword: builder.mutation<unknown, ResetPasswordInput>({
      query: (body) => ({url: 'auth/reset_password/',  method: 'POST', body}),
    }),
    updatePassword: builder.mutation<unknown, ChangePasswordInput>({
      query: (body) => ({url: 'auth/reset_password/confirm/',  method: 'POST', body}),
    }),
  }),
})

export const authSlice = createSlice({
  name: 'userParams',
  initialState: {
    me: {} as MeParamsOutput,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.me = { ...state.me, ...payload}
      }
    )
    builder.addMatcher(
      authApi.endpoints.updateUserInfo.matchFulfilled,
      (state, { payload }) => {
        state.me = { ...state.me, ...payload}
      }
    )
  },
})
export const useSignInMutation = () => {
  return authApi.useSignInMutation({ fixedCacheKey: 'signIn' })
}

export const useSignUpMutation = () => {
  return authApi.useSignUpMutation({ fixedCacheKey: 'signUp' })
}

export const useGetUserMutation = () => {
  return authApi.useGetUserMutation({ fixedCacheKey: 'getUser' })
}

export const useResetPasswordMutation = () => {
  return authApi.useResetPasswordMutation({ fixedCacheKey: 'resetPassword' })
}

export const useUpdatePasswordMutation = () => {
  return authApi.useUpdatePasswordMutation({ fixedCacheKey: 'updatePassword' })
}

export const useUser = () => {
  return useSelector(({ userParams }: ReturnType<typeof rootReducer>) => userParams.me)
}

export const useUserIsManager = () => {
  const user = useUser();
  if (user.id) {
    return user.groups ? Boolean(user.groups.filter((group) => group.name === USER_GROUPS.MANAGER)?.length) : false
  }
}

export const useUpdateUserInfoMutation = () => {
  return authApi.useUpdateUserInfoMutation({ fixedCacheKey: 'updateUserInfo' })
}
