import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enDictionary from 'assets/dictionaries/en.json'
import ruDictionary from 'assets/dictionaries/ru.json'
import LanguageDetector from "i18next-browser-languagedetector";

const enum LOCALES {
  ENGLISH = 'en',
  RUSSIAN = 'ru'
}

const resources = {
  [LOCALES.ENGLISH]: { translation: enDictionary },
  [LOCALES.RUSSIAN]: { translation: ruDictionary }
}

export const supportedLngs = {
  ru: "Русский",
  en: "English",
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: Object.keys(supportedLngs),
    fallbackLng: LOCALES.RUSSIAN,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
