import React, {useState} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";

import {
  useInvoicesByOrderIdQuery, useRemoveInvoiceMutation
} from "../../../../../../../../../api/calc";

import Completed from "../../../../../../../../common/completed-new";
import ModalBig from "../../../../../../../../common/modalBig";
import ModalAction from "../../../../../../../../common/modalAction";

import EditInvoice from "./../../components/invoiceDetails/components/editInvoice";
import Products from "./../../components/invoiceDetails/components/products";

import {useGetCalcIdFromRoute} from "../../../../../../../../../utils/hooks";
import {getFlag} from "../../../../../delivery/helpers";

type Props = {
    openByDefault?: boolean
    invoice?: InvoiceParamsOutput
    onCloseInvoiceForm?(): void
}

const InvoiceDetails = ({openByDefault, invoice, onCloseInvoiceForm} : Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ openEditModal, setOpenEditModal ] = useState(false);

  const [ shouldOpenRemoveModal, setShouldOpenRemoveModal ] = useState(false)

  const [ removeInvoice ] = useRemoveInvoiceMutation()

  const { refetch: refetchInvoicedByOrderId } = useInvoicesByOrderIdQuery(orderId, { skip: !orderId })

  const getCompletedInfo = () => [
    {
      label: t('calc__text__main__descriptionOfTheGoods'),
      value: invoice?.description
    },
    {
      label: t('calc__text__main__incoterms'),
      value: invoice?.incoterms
    },
    {
      label: t('calc__text__main__cityOfDeliveryExplanation'),
      value: <span className='invoice__flag-info'>{getFlag({code: invoice?.location_city.country.code, height: '14px', marginRight: '8px'  })} {invoice?.location_city.name}</span>
    },
  ]

  const handleAddSuccess = () => {
    refetchInvoicedByOrderId()
    setOpenEditModal(false)
  }

  const onEdit = () => {
    setOpenEditModal(true)
  }

  const handleRemoveInvoice = async (invoiceId?: number | string) => {
    if (invoiceId) {
      await removeInvoice({orderId, invoiceId: Number(invoiceId)})
      setShouldOpenRemoveModal(false)
      await refetchInvoicedByOrderId()
    }
  }

  const invoiceId = invoice?.id;

  const getCompletedInvoiceCaption = () =>
    `${t('common__text__invoice')}  № ${invoice?.invoice_num} ${t('common__text__from')} ${moment(invoice?.invoice_date).format('DD.MM.YYYY')}`;

  return (
    <>
      {invoiceId ? <Completed
        info={getCompletedInfo()}
        onEdit={()=> onEdit()}
        onRemove={() => setShouldOpenRemoveModal(true)}
        isFullRow
        caption={getCompletedInvoiceCaption()}
      /> : <EditInvoice
        handleSuccess={handleAddSuccess}
        isFirstInvoice
        onCloseInvoiceForm={onCloseInvoiceForm}
      />}
      <Products productCount={invoice?.product_count || 0} openByDefault={openByDefault} invoice={invoice}/>
      {openEditModal && <ModalBig
        isOpen
        onClose={() => setOpenEditModal(false)}
        title={t('calc__text__main__editInvoice')}
      >
        <EditInvoice
          handleSuccess={handleAddSuccess}
          hasCancelButton
          onClose={() => setOpenEditModal(false)}
          currentInvoice={invoice}
        />
      </ModalBig>}
      {shouldOpenRemoveModal && <ModalAction
        isOpen={shouldOpenRemoveModal}
        onContinue={handleRemoveInvoice}
        index={invoiceId}
        onClose={() => setShouldOpenRemoveModal(false) }
        title={t('common__text__removeInvoice')}
        description={<>
          {t('actions__message__removeConfirm')}&nbsp;
          {t('common__text__invoice')}&nbsp;
          <span className='text_bold'>{`«${invoice?.invoice_num}»?` }</span>
        </>}
        buttonYesText={t('actions__text__yesDelete')}
      />}
    </>
  )
}

export default InvoiceDetails
