import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CityTable from "./table";
import {useCities, useLazyGetCityQuery, useUpdateCities} from "../../../../../api/dictionary";
import SearchField from "../../components/search_field";
import CitiesActions from "./actions";
import Header from './../../components/header';
import Preloader from "../../../../common/preloader";

import './city.scss';
import {useDispatch} from "react-redux";
import {INITIAL_DATA_REQUEST} from "../../constants";

const Cities = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [ currentCity, setCurrentCity ] = useState<string[]>([])

  const [ showModalRemoveByDefault, setShowModalRemoveByDefault ] = useState(false)
  const [ showModalAddByDefault, setShowModalAddByDefault ] = useState(false)

  const [ hasLoadedFirstRequest, setHasLoadedFirstRequest ] = useState(false)

  const [ citiesRequest, { isLoading: isLoadingCities } ] = useLazyGetCityQuery()

  const citiesParams = useCities()?.params;

  const citiesRequestUpdate = (params?: DictionaryParamsInput) =>
    citiesRequest({...citiesParams, ...params});

  useEffect(() => {
    citiesRequest(INITIAL_DATA_REQUEST).then(() =>
      setHasLoadedFirstRequest(true)
    )
  }, [])


  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(useUpdateCities());
    };
  }, []);

  const citiesRequestSearch = (query: string) => citiesRequest({
    ...citiesParams,
    search: query,
  })

  const handleEdit = () => {
    setShowModalAddByDefault(true);
  }

  const handleOpenRemoveModal = () => setShowModalRemoveByDefault(true)

  const handleCloseModal = () => {
    setCurrentCity([]);
    setShowModalRemoveByDefault(false);
    setShowModalAddByDefault(false);
  }

  const isLoadingStatus = isLoadingCities && !hasLoadedFirstRequest;

  return (
    <>
      <div>
        <Header menuText={t('menu__text__cities')}/>
        {isLoadingStatus ? <Preloader/> : <div className='city-container'>
          <Card>
            <CardContent sx={{ padding: 0, '&:last-child' : { paddingBottom: 0 } }}>
              <div className='city-container__search-container'>
                <SearchField onSearch={citiesRequestSearch}  />
              </div>
              <CityTable
                handleEdit={handleEdit}
                handleRemove={handleOpenRemoveModal}
                handleCitiesRequest={citiesRequestUpdate}
                changeCity={setCurrentCity}
                currentCities={currentCity}
              />
            </CardContent>
          </Card>
        </div>}
      </div>
      {!isLoadingStatus && (
        <div>
          <CitiesActions
            citiesRequestUpdate={citiesRequestUpdate}
            isOpenRemoveByDefault={showModalRemoveByDefault}
            isOpenAddByDefault={showModalAddByDefault}
            currentCities={currentCity}
            onCloseModal={handleCloseModal}
          />
        </div>
      )}
    </>
  )
};

export default Cities
