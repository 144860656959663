import React, { useEffect } from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TurnedInOutlinedIcon from '@mui/icons-material/TurnedInOutlined';

import {COLORS} from "../../../../../../../config/constants";
import CompanyCard from "../company-card";
import {useUserExtraServices} from "../../../../../../../api/calc";

type Props = {
  handleOpenModal(): void;
  refetchGetExtraServices(): void;
}

const AdditionalServices = ({ handleOpenModal, refetchGetExtraServices }: Props) => {

  const [ hasCompleted, setHasCompleted ] = React.useState(false);

  const userExtraServices = useUserExtraServices()

  useEffect(() => {
    setHasCompleted(userExtraServices ? userExtraServices?.length > 0 : false)
  }, [ userExtraServices ])

  const { t } = useTranslation()

  const totalCost = (userExtraServices || []).reduce(
    (accumulator, currentValue) => accumulator + (currentValue?.cost_total ?? 0),
    0
  );

  return (
    <div className='additional'>
      <Accordion sx={{
        border: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
        boxShadow: 'none',
      }}
      defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          sx={{padding: '10px 24px 12px'}}
        >
          <div className='additional-title'>
            <div className='additional-title__left'>
              <TurnedInOutlinedIcon sx={{ color: COLORS.SLIGHTLY_GRAY }}/>
              <Typography variant='h6'>
                {t('common__text__additionalServices')}
                {/*<span className='additional-title__count'> 4</span>*/}
              </Typography>
            </div>
            <div className='additional-title__right'>
              <Typography variant='h6'>
                {`${totalCost} USD`}
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {hasCompleted && userExtraServices?.map(userExtraService => <CompanyCard
            isActive
            company={userExtraService}
            key={userExtraService.id}
            refetchGetExtraServices={refetchGetExtraServices}
          />)}
          <Button
            variant='outlined'
            startIcon={<SearchIcon />}
            fullWidth
            onClick={handleOpenModal}
          >
            {hasCompleted ? t('calc__text__additional__findOtherAdditionalServices') : t('calc__text__additional__findAdditionalServices')}
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AdditionalServices
