import {useEffect, useState,} from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from "react-i18next"
import { InvoiceForm, INVOICE_FORM } from '../../editInvoice'
import { useLazyGetIncotermsQuery } from '../../../../../../../../../../../../api/dictionary';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { COLORS } from "../../../../../../../../../../../../config/constants";

const IncotermsInput  = ( ) => {

  const { setValue, getValues, register } = useFormContext<InvoiceForm>()

  const [ incotermsRequest, { data } ] = useLazyGetIncotermsQuery()

  const { t } = useTranslation()

  const getIncoterms = (data?: { results: IncotermDictionary[]}) => {
    const incotermsDictionary: { key: string; value: string; }[] = [];
    data?.results?.map(item => incotermsDictionary.push({
      key: item.code,
      value: item.name,
    }));
    return incotermsDictionary;
  }

  const incotermsOptions = getIncoterms(data);

  const findIncoterms = (value: string) => incotermsOptions?.find(item => item.value === value)

  const [ incotermsValue, setIncotermsValue ] = useState(incotermsOptions?.find(item => item.key === getValues()[INVOICE_FORM.INCOTERMS])?.value || '');

  const [ error, setError ] = useState(false);

  useEffect(() => {
    incotermsRequest({ search: ''})
  }, [ ])

  useEffect(() => {
    const currentIncoterms = incotermsOptions?.find(item => item.key === getValues()[INVOICE_FORM.INCOTERMS])?.value
    currentIncoterms && setIncotermsValue(currentIncoterms);
  }, [ data ])


  const handleChange = (event: SelectChangeEvent) => {
    const incoterms = findIncoterms(event.target.value)
    if (incoterms) {
      incoterms && setValue(INVOICE_FORM.INCOTERMS, incoterms?.key)
      setIncotermsValue(event.target.value)
    } else {
      setError(true)
    }
  };

  if (!incotermsOptions) {
    return null;
  }

  return (
    <FormControl
      fullWidth
      error={error}
      {...register(INVOICE_FORM.INCOTERMS, { required: true })}
    >
      <InputLabel id='incotermsInput' shrink sx={{ background: COLORS.WHITE}}>{t('calc__text__main__incoterms')}</InputLabel>
      <Select
        labelId='incotermsInput'
        id='incotermsInput'
        value={incotermsValue}
        label={t('calc__text__main__incoterms')}
        onChange={handleChange}
        onClose={() => setError(!(getValues()[INVOICE_FORM.INCOTERMS]))}
      >
        {incotermsOptions.map(option => (
          <MenuItem {...option} key={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{t('common__text__isRequired')}</FormHelperText>}
    </FormControl>
  )
}

export default IncotermsInput
