import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField'
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import 'react-phone-input-2/lib/material.css'
import CheckIcon from '@mui/icons-material/Check';
import {ADORNMENTS} from "../../../../../../../../../config/constants";
import {useSummary, useUpdateSummaryByOrderId} from "../../../../../../../../../api/calc";
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import './edit_containers.scss'
import { CONTAINERS_FORM } from "../../index";
import {useGetCalcIdFromRoute} from "../../../../../../../../../utils/hooks";
import {checkDecimalOrZero} from "../../../../../../../../../utils/validation";

type ContainersForm = {
    [CONTAINERS_FORM.FT_20]: number
    [CONTAINERS_FORM.FT_40]: number
    [CONTAINERS_FORM.FT_40_HIGH_CUBE]: number
}

type Props = {
    onClose?(): void,
    onSave?(values?: ContainersManual): void,
    handleSetAutoContainers?(): void
}

const EditContainers = ( { onClose, onSave, handleSetAutoContainers } : Props ) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const orderId = useGetCalcIdFromRoute()

  const summary = useSummary()

  const errorDecimal = t('errors__message__onlyDecimals');

  const [ containersValueHasChanged, setContainersValueHasChanged ] = useState(false);
  const [ isManualMode, setIsManualMode ] = useState(false);

  const containersData = summary?.containers_user;
  const hasContainersManual = !isEmpty(summary?.containers_manual);

  const methods = useForm<ContainersForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      [CONTAINERS_FORM.FT_20]: containersData?.[CONTAINERS_FORM.FT_20] || 0,
      [CONTAINERS_FORM.FT_40]: containersData?.[CONTAINERS_FORM.FT_40] || 0,
      [CONTAINERS_FORM.FT_40_HIGH_CUBE]: containersData?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] || 0
    },
  })

  const { register, handleSubmit, setValue, formState: { isValid, errors } } = methods


  useEffect(() => {
    setValue(CONTAINERS_FORM.FT_20, containersData?.[CONTAINERS_FORM.FT_20] || 0);
    setValue(CONTAINERS_FORM.FT_40, containersData?.[CONTAINERS_FORM.FT_40] || 0);
    setValue(CONTAINERS_FORM.FT_40_HIGH_CUBE, containersData?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] || 0)
  }, [ containersData ])

  useEffect(() => {
    setIsManualMode(hasContainersManual)
  }, [ summary ])

  const handleChange = () => {
    setContainersValueHasChanged(true);
  }

  const onSubmit = handleSubmit (async (values) => {

    const changedValues = containersValueHasChanged ? values: {};

    const containersManual = hasContainersManual ? values : changedValues;

    const summaryData = {
      orderId,
      summary: {
        ...summary,
        containers_manual: containersManual,
      }
    }

    // @ts-ignore
    dispatch(useUpdateSummaryByOrderId(summaryData));
    onSave && onSave(containersManual);
    onClose && onClose();
  });

  return (
    <form
      id='containers'
      onSubmit={onSubmit}
    >
      <Typography variant='subtitle1' sx={{ fontWeight: 500, marginTop: 2, marginBottom: 2 }}>{t('calc__text__packaging__calculatedNumberOfContainers')}</Typography>
      <FormGroup sx={{
        marginBottom: 2,
      }}>
        <FormControlLabel
          control={<Switch checked={!isManualMode} onChange={(event) => setIsManualMode(!event.target.checked)} />}
          label={t('calc__text__packaging__valuesAreCalculatedAutomatically')} />
      </FormGroup>
      <div className='summary-edit'>
        <TextField
          {...register(CONTAINERS_FORM.FT_20, { valueAsNumber: true, validate: (value: number) => checkDecimalOrZero(value, errorDecimal) })}
          error={Boolean(errors[CONTAINERS_FORM.FT_20])}
          onChange={handleChange}
          helperText={errors[CONTAINERS_FORM.FT_20]?.message}
          variant='outlined'
          label='20 Ft'
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: ADORNMENTS.AMOUNT
          }}
          disabled={!isManualMode}
        />
        <TextField
          {...register(CONTAINERS_FORM.FT_40, { valueAsNumber: true, validate: (value: number) => checkDecimalOrZero(value, errorDecimal) })}
          error={Boolean(errors[CONTAINERS_FORM.FT_40])}
          onChange={handleChange}
          helperText={errors[CONTAINERS_FORM.FT_40]?.message}
          variant='outlined'
          label='40 Ft'
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: ADORNMENTS.AMOUNT
          }}
          disabled={!isManualMode}
        />
        <TextField
          {...register(CONTAINERS_FORM.FT_40_HIGH_CUBE, { valueAsNumber: true, validate: (value: number) => checkDecimalOrZero(value, errorDecimal) })}
          error={Boolean(errors[CONTAINERS_FORM.FT_40_HIGH_CUBE])}
          onChange={handleChange}
          helperText={errors[CONTAINERS_FORM.FT_40_HIGH_CUBE]?.message}
          variant='outlined'
          label='40 Ft High cube'
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: ADORNMENTS.AMOUNT
          }}
          disabled={!isManualMode}
        />
      </div>
      {hasContainersManual &&  <Button
        variant='outlined'
        fullWidth
        onClick={handleSetAutoContainers}
        sx={{ marginBottom: 2 }}
      >{t('calc__text__packaging__calculateTheValueAutomatically')}</Button>}
      <Button
        variant='contained'
        fullWidth
        type='submit'
        disabled={!isValid}
        startIcon={<CheckIcon />}
      >{t('actions__text__confirmDeliveryMethodContainers')}</Button>
    </form>
  )
}

export default EditContainers
