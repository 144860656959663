import React from "react";
import {Outlet} from "react-router-dom"

import Header from '../common/header';
import Menu from '../common/menu';

import './cabinet.scss'
import Footer from "../common/footer";

const Cabinet = () => {

  return (
    <>
      <Header/>
      <div className='layout'>
        <Menu/>
        <Outlet/>
      </div>
      <Footer />
    </>
  )
};

export default Cabinet
