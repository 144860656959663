import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const containersApi = createApi({
  baseQuery,
  reducerPath: 'containersApi',
  endpoints: (builder) => ({
    getContainers: builder.query<any, string>({
      query: () => 'dictionary/v2.0/containers/',
    }),
  }),
})

export const { useLazyGetContainersQuery } = containersApi
