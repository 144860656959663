import {useAuthContext} from "../../../context/AuthContext";
import React, {useEffect} from "react";
import {redirect} from "react-router-dom";
import {ROUTES} from "../../../config/constants";
import {Login} from "../../index";
import isUndefined from 'lodash/isUndefined'
import Preloader from "../../common/preloader";

type Props = {
    children: JSX.Element
}

const AccessWrapper = ({children} : Props) => {

  const { user, isSuccess, isNewUser } = useAuthContext();

  const existingUserNoData = isUndefined(user) && !isNewUser;

  if (!isSuccess || existingUserNoData) {
    return <Preloader />
  }

  return user ? children : <Login/>

}

export default AccessWrapper;
