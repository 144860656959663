import React, {useState, useEffect} from "react";

import { useForm, FormProvider } from "react-hook-form";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {
  checkDecimal,
  checkOnlyEnglishCharacters,
  checkOnlyRussianCharacters
} from '../../../../../../utils/validation';
import  {useChangeCityMutation, useCitiesById, useCreateCityMutation} from "../../../../../../api/dictionary";
import ModalAdd from "../../../components/modal_add";
import Remove from './../remove';
import CountryInput from '../../../shared/country';
import { INITIAL_OPTION } from "../../../constants";

import './add.scss';

type Props = {
    isOpen: boolean
    isEdit: boolean
    id?: string
    onClose(): void
    onRemoved(isSuccess: boolean): void
    onAddedSuccess(): void
    handleCitiesRequest(data?: DictionaryParamsInput): Promise<any>
}

type PropsSendData = {
    isClosed?: boolean
}

export enum MODAL_ADD_FORM {
    COUNTRY = 'country',
    NAME = 'name',
    NAME_EN = 'name_en',
    POST_CODES = 'post_codes',
}

export type ModalAddParamsCities = {
    [MODAL_ADD_FORM.NAME]: string
    [MODAL_ADD_FORM.COUNTRY]: string
    [MODAL_ADD_FORM.NAME_EN]: string
    [MODAL_ADD_FORM.POST_CODES]: string
}

type OptionData = {
  label: string
  id: string
}

const Add = ({isOpen, onClose, isEdit, handleCitiesRequest, id, onAddedSuccess, onRemoved }: Props) => {

  const { t } = useTranslation()

  const cityInfo = useCitiesById(id);

  const [ errorNotification, setErrorNotification ] = useState(false);

  const [ currentCountry, setCurrentCountry ] = useState<OptionData>(INITIAL_OPTION);

  const [ createCity ] = useCreateCityMutation()
  const [ changeCityRequest ] = useChangeCityMutation()

  const methods = useForm<ModalAddParamsCities>({
    defaultValues: {
      [MODAL_ADD_FORM.NAME]: isEdit ? cityInfo?.[MODAL_ADD_FORM.NAME] : undefined,
      [MODAL_ADD_FORM.COUNTRY]: isEdit ? cityInfo?.[MODAL_ADD_FORM.COUNTRY].name : undefined,
      [MODAL_ADD_FORM.NAME_EN]: isEdit ? cityInfo?.[MODAL_ADD_FORM.NAME_EN] : undefined,
      [MODAL_ADD_FORM.POST_CODES]: isEdit ? cityInfo?.[MODAL_ADD_FORM.POST_CODES]?.[0] : undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: {errors, isValid},
  } = methods;

  const errorDecimal = t('errors__message__incorrectValue');
  const errorRussianCharacters = t('errors__message__russianCharactersOnly');
  const errorEnglishCharacters = t('errors__message__englishCharactersOnly');

  useEffect(() => {
  }, [ isOpen ])

  useEffect(() => {
    if (isEdit && cityInfo){
      setValue(MODAL_ADD_FORM.COUNTRY, cityInfo.country.name);
      setCurrentCountry({
        label: cityInfo.country.name,
        id: cityInfo.country.code
      })
    }
  }, [ isEdit ])

  const handleSendData = ({isClosed}: PropsSendData) => {

    if (!currentCountry){
      return;
    }

    const onSubmit = async (values: CityDictionary) => {

      let response: any;

      if (isEdit) {

        if (!id) {
          return;
        }

        // @ts-ignore
        response = await changeCityRequest({
          name: values.name,
          // @ts-ignore
          country: currentCountry.id,
          name_en: values.name_en,
          // @ts-ignore
          post_codes: [ values.post_codes ],
          id: id,
        })

      } else {

        // @ts-ignore
        response = await createCity({
          name: values.name,
          // @ts-ignore
          country: currentCountry.id,
          name_en: values.name_en,
          // @ts-ignore
          post_codes: [ values.post_codes ],
        })
      }

      // @ts-ignore
      if (response?.error) {
        // @ts-ignore
        const errorList = Object.keys(response?.error?.data)

        errorList.map((error) => {
          // @ts-ignore
          setError(error, { type: "custom",  message: response?.error?.data[error][0]})
        })

        setErrorNotification(true)
      } else {
        onAddedSuccess();
        setErrorNotification(false);
        isClosed && await handleClose();
        reset()
        setCurrentCountry(INITIAL_OPTION)
      }

    }

    // @ts-ignore
    handleSubmit(onSubmit)()

  }

  const handleClose = () => {
    clearErrors();
    return handleCitiesRequest().then(() => {
      onClose();
      setValue(MODAL_ADD_FORM.NAME, '');
      setValue(MODAL_ADD_FORM.COUNTRY, '');
      setValue(MODAL_ADD_FORM.NAME_EN, '');
      setValue(MODAL_ADD_FORM.POST_CODES, '');
    });
  }

  const handleAddCityAndClose = () => handleSendData({isClosed: true});

  return (
    <ModalAdd
      isOpen={isOpen}
      isEdit={isEdit}
      handleSendData={handleSendData}
      handleAddAndClose={handleAddCityAndClose}
      handleClose={handleClose}
      isValid={isValid}
      title={isEdit ? t('dictionaries__text__city__editCityModal') : t('dictionaries__text__city__addCityModal')}
      saveButtonText={t('dictionaries__text__city__addCityAndSaveButton')}
      removeButtonText={t('dictionaries__text__city__removeCity')}
      errorNotification={errorNotification}
      modalRemove={(handleClose) => id && <Remove
        isOpen
        ids={[ id ]}
        onClose={handleClose}
        handleCitiesRequest={handleCitiesRequest}
        onRemoved={onRemoved}
      />}
    >
      <div>
        <FormProvider {...methods}>
          {isEdit && currentCountry && <CountryInput
            formFieldName={MODAL_ADD_FORM.COUNTRY}
            setCurrentCountry={setCurrentCountry}
            defaultValue={currentCountry}
          />}
          {!isEdit && <CountryInput
            formFieldName={MODAL_ADD_FORM.COUNTRY}
            setCurrentCountry={setCurrentCountry}
            defaultValue={currentCountry}
          />}
          <TextField
            {...register(MODAL_ADD_FORM.NAME,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkOnlyRussianCharacters(value, errorRussianCharacters)
              })}
            error={Boolean(errors[MODAL_ADD_FORM.NAME])}
            helperText={errors[MODAL_ADD_FORM.NAME]?.message}
            variant='outlined'
            label={t('dictionaries__text__city__addCityNameRussian')}
            InputLabelProps={{shrink: true}}
            fullWidth
            sx={{ marginBottom: 4, marginTop: 4 }}
          />
          <TextField
            {...register(MODAL_ADD_FORM.NAME_EN,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkOnlyEnglishCharacters(value, errorEnglishCharacters)
              })}
            error={Boolean(errors[MODAL_ADD_FORM.NAME_EN])}
            helperText={errors[MODAL_ADD_FORM.NAME_EN]?.message}
            variant='outlined'
            label={t('dictionaries__text__city__addCityNameEnglish')}
            InputLabelProps={{shrink: true}}
            fullWidth
            sx={{ marginBottom: 4 }}
          />
          <div className='dictionary__modal-add-container__addZipCode'>
            <TextField
              {...register(MODAL_ADD_FORM.POST_CODES,
                {
                  // @ts-ignore
                  validate: (value: number) => checkDecimal(Number(value), errorDecimal),
                  min: { value: 0, message: t('errors__message__incorrectValue')}
                })}
              error={Boolean(errors?.[MODAL_ADD_FORM.POST_CODES])}
              helperText={errors?.[MODAL_ADD_FORM.POST_CODES]?.message}
              variant='outlined'
              label={t('dictionaries__text__city__addCityZipCodes')}
              InputLabelProps={{shrink: true}}
              sx={{ width: '308px' }}
            />
          </div>
        </FormProvider>
      </div>
    </ModalAdd>
  );
}

export default Add
