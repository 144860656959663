import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef} from "react";
import Preloader from "../../common/preloader";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Calculation from './components/calculation';

import './../cabinet.scss'
import './calculations.scss'
import CardContent from "@mui/material/CardContent";
import FilterCost from "./components/filterCost";
import Search from "./components/search";

import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import Card from "@mui/material/Card";
import {
  useGetOrdersMutation,
  useOrderParamsData,
} from "../../../api/calc";
import Pagination from "@mui/material/Pagination";
import Divider from "@mui/material/Divider";
import debounce from "lodash/debounce";

export const enum SORTING_OPTIONS {
  DATE_DESC = 'created_at',
  DATE_ASC = '-created_at',
  TOTAL_PRICE_DESC = 'order_summary__total_amount',
  TOTAL_PRICE_ASC = '-order_summary__total_amount',
}

export const enum RESULTS_COUNT {
  COUNT_10 = 10,
  COUNT_20 = 20,
  COUNT_45 = 45
}

const Calculations = () => {

  const { t } = useTranslation()

  const filterData = useOrderParamsData();

  const [ getOrders ] = useGetOrdersMutation()

  const [ calculations, setCalculations ] = React.useState<Calculation[]>([]);
  const [ totalCount, setTotalCount ] = React.useState(0);

  const [ isLoading, setIsLoading ] = React.useState(true);

  const [ noFilterValues, setNoFilterValues ] = React.useState(true);

  const calculationsRequest = (data?: OrdersParamsInput) => {

    getOrders({
      ...filterData,
      ...data,
    }).then((data) => {

      // @ts-ignore
      setCalculations(data.data?.results);
      // @ts-ignore
      setTotalCount(data.data?.count);

      setTimeout(() => {
        setIsLoading(false);
      }, 300);

    });
  }

  const handleChangeCost = (value: number[], filterData: OrdersParamsInput) => {
    calculationsRequest({
      ...filterData,
      min_total_amount: value[0],
      max_total_amount: value[1],
    });
    setNoFilterValues(false);
  }

  const handleSearch = (value: string, filterData: OrdersParamsInput) => {
    calculationsRequest({
      ...filterData,
      search: value,
    });
    setNoFilterValues(false);
  }

  const debouncedRequestChangeCost = useRef(debounce(handleChangeCost, 500)).current

  const debouncedSearchRequest = useRef(debounce(handleSearch, 500)).current

  useEffect(() => {
    calculationsRequest({
      ordering: SORTING_OPTIONS.DATE_ASC
    });
  }, [])

  if (isLoading) {
    return <Preloader/>
  }

  const handleChangeResultsCount = (value: string | RESULTS_COUNT ) => calculationsRequest({
    limit: Number(value)
  });

  const handleChangeCurrentPage = (event: React.ChangeEvent<unknown>, pageNumber: number) => calculationsRequest({
    page: pageNumber
  });

  const handleClearRequest = () => {
    calculationsRequest({
      min_total_amount: undefined,
      max_total_amount: undefined,
      search: undefined
    });
    setNoFilterValues(true);
  }

  const resultsCount = filterData.limit || RESULTS_COUNT.COUNT_20;

  return (
    <>
      <div className='calculations'>
        <Typography variant='h4' sx={{
          marginBottom: 3
        }}>
          {t('computation__text__computations')}
          <span className='calculations__count'>
            ({totalCount})
          </span>
        </Typography>
        <div className='calculations__container'>
          {(!totalCount && !isLoading) && <>
            <div className='search-results__results'>
              <Divider sx={{ marginBottom: 3, width: '100%' }} />
              <Typography variant='h6' gutterBottom sx={{ marginTop: 4}}>
                {t('actions__message__noOrdersAvailable')}
              </Typography>
            </div>
          </>
          }
          {Boolean(totalCount) && (
            <div className='calculations-results'>
              <div className='calculations-results__search'>
                <Search
                  onChange={(value) => debouncedSearchRequest(value, filterData)}
                  noFilterValues={noFilterValues} />
              </div>
              <div className='calculations-results__sorting'>
                <FormControl sx={{ width: '324px'}}>
                  <InputLabel id='sorting-select'>{t('common__text__sort')}</InputLabel>
                  <Select
                    labelId='sorting-select'
                    id='sorting-select'
                    value={filterData.ordering || SORTING_OPTIONS.DATE_ASC}
                    label={t('common__text__sort')}
                    onChange={({ target: { value } }) => calculationsRequest({
                      ordering: value
                    })}
                    className='calculations-results__select-sorting'>
                    <MenuItem value={SORTING_OPTIONS.DATE_ASC}>{t('common__message__sortDateAsc')}</MenuItem>
                    <MenuItem value={SORTING_OPTIONS.DATE_DESC}>{t('common__message__sortDateDesc')}</MenuItem>
                    <MenuItem value={SORTING_OPTIONS.TOTAL_PRICE_ASC}>{t('common__message__sortTotalPriceAsc')}</MenuItem>
                    <MenuItem value={SORTING_OPTIONS.TOTAL_PRICE_DESC}>{t('common__message__sortTotalPriceDesc')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel id='count-select'>{t('common__text__resultsCount')}</InputLabel>
                  <Select
                    labelId='count-select'
                    id='count-select'
                    value={resultsCount}
                    label={t('common__text__resultsCount')}
                    onChange={({ target: { value } }) => handleChangeResultsCount(value)}
                    className='calculations-results__select-count'>
                    <MenuItem value={RESULTS_COUNT.COUNT_10}>10</MenuItem>
                    <MenuItem value={RESULTS_COUNT.COUNT_20}>20</MenuItem>
                    <MenuItem value={RESULTS_COUNT.COUNT_45}>45</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Divider sx={{ marginTop: 4, marginBottom: 4, width: '100%' }} />
              {calculations.map(item => <Calculation order={item} key={item.id} refetch={calculationsRequest}/>)}
              {Number(totalCount) > resultsCount && <Pagination
                count={Math.ceil(Number(totalCount) / resultsCount)}
                sx={{ marginTop: '48px' }}
                size='large'
                onChange={handleChangeCurrentPage}
              />}
            </div>
          )}
          <Card className='calculations__filters'>
            <CardContent sx={{ padding: 0 }}>
              <Typography variant='h6' gutterBottom sx={{ padding: '16px 24px 10px'}}>
                {t('common__text__filters')}
              </Typography>
              <FilterCost onChange={(value) => debouncedRequestChangeCost(value, filterData)} noFilterValues={noFilterValues} />
              <Button
                startIcon={<ClearIcon />}
                // type='submit'
                variant='outlined'
                fullWidth
                sx={{
                  marginTop: 4,
                  marginBottom: 1,
                  marginLeft: 3,
                  maxWidth: '312px',
                }}
                onClick={handleClearRequest}
              >{t('actions__text__clearFilters')}</Button>
              {/*<Button*/}
              {/*  variant='contained'*/}
              {/*  fullWidth*/}
              {/*  type='submit'*/}
              {/*  startIcon={<CheckIcon />}*/}
              {/*  sx={{*/}
              {/*    marginTop: 1,*/}
              {/*    marginLeft: 3,*/}
              {/*    maxWidth: '312px',*/}
              {/*  }}*/}
              {/*>{t('actions__text__applyFilters')}</Button>*/}
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Calculations
