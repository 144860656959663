import isArray from "lodash/isArray";

export const getTnvedVAT = (tnvedInfo?: TnvedInfo) => {
  if (!tnvedInfo){
    return undefined;
  }
  let vat = tnvedInfo?.GoodInfo?.VATlist.VAT;
  if (vat && !isArray(vat)) {
    vat = [ vat ]
  }
  return vat
}
