import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm, useFormContext} from "react-hook-form";
import findIndex from "lodash/findIndex";
import {useDispatch} from "react-redux";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Link from "@mui/material/Link";

import { useUpdateProductById } from "../../../../../../../../../../../../../api/calc";
import {checkFloat} from "../../../../../../../../../../../../../utils/validation";
import AlertBottom from '../../../../../../../../../../../../common/alertBottom';

import {PACKAGE_FORM, PackageForm} from "../add_form/helpers";

import './excise_fields.scss';

type Props = {
  onClose(): void
  exciselist: Excise[]
  product?: ProductParamsOutput
}

enum EXCISE_FIELDS {
  EXCISE_VALUE = 'excise_value',
  EXCISE_RATE = 'excise_rate',
}

type ExciseFieldsParams = {
  [EXCISE_FIELDS.EXCISE_VALUE]: number
  [EXCISE_FIELDS.EXCISE_RATE]: number
}

const ExciseFields = ({onClose, exciselist, product}: Props) => {

  const { t } = useTranslation()

  const { setValue: setValuePackageForm, trigger: triggerPackageForm, getValues: getValuesPackageForm } = useFormContext<PackageForm>()

  const userExciseValue = product?.[EXCISE_FIELDS.EXCISE_VALUE] === 0 ? 0 : product?.[EXCISE_FIELDS.EXCISE_VALUE] || getValuesPackageForm(PACKAGE_FORM.EXCISE_VALUE);

  // @ts-ignore
  const currentExciseRate = exciselist.find((excise) => excise.Condition === (product?.[EXCISE_FIELDS.EXCISE_RATE]?.description || getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.Condition || getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.description));

  const currentExciseRateIndex = () => findIndex(exciselist, currentExciseRate);

  const [ exciseRateByUser, setExciseByUserRate ] = useState((currentExciseRate && currentExciseRateIndex() !== -1) ? currentExciseRateIndex() : "");
  const [ measure, setMeasure ] = useState((currentExciseRate && currentExciseRateIndex() !== -1) ? currentExciseRate?.Measure.Razm : "");

  const dispatch = useDispatch()

  const isSingleValue = exciselist?.length === 1;

  const { register, getValues, setValue, trigger, formState: { errors } } = useForm<ExciseFieldsParams>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [EXCISE_FIELDS.EXCISE_VALUE]: (userExciseValue || userExciseValue === 0) ? Number(userExciseValue): undefined,
      [EXCISE_FIELDS.EXCISE_RATE]: currentExciseRateIndex()
    }
  })

  const [ openAlert, setOpenAlert ] = React.useState(false);

  const handleCloseAlert = () => setOpenAlert(false);

  const checkHasErrors = () => {
    const exciseValueHasErrors = Boolean(!getValues()[EXCISE_FIELDS.EXCISE_VALUE] || Boolean(errors[EXCISE_FIELDS.EXCISE_VALUE]));
    return isSingleValue ? exciseValueHasErrors : exciseValueHasErrors || exciseRateByUser === "";
  }

  const onSubmit = async () => {

    if(!isSingleValue && typeof exciseRateByUser === "string") {
      return
    }

    const exciseRate = isSingleValue ? exciselist[0]: exciselist[Number(exciseRateByUser)];
    const exciseValue = getValues(EXCISE_FIELDS.EXCISE_VALUE);

    if (product) {
      const data = {
        ...product,
        [EXCISE_FIELDS.EXCISE_VALUE]: exciseValue,
        [EXCISE_FIELDS.EXCISE_RATE]: {
          description: exciseRate.Condition,
          document: exciseRate.Document,
          unit: exciseRate.Measure.Razm,
          value: exciseRate.Value,
        }
      };

      // @ts-ignore
      await dispatch(useUpdateProductById(data));
    }

    setValuePackageForm(PACKAGE_FORM.EXCISE_VALUE, exciseValue);
    setValuePackageForm(PACKAGE_FORM.EXCISE_RATE, exciseRate);
    triggerPackageForm(PACKAGE_FORM.PALLETES);
    setOpenAlert(true);
    onClose();
  }

  return <><div
    className='packaging-additional'>
    {isSingleValue ? <>{exciselist[0].Value} {exciselist[0].Condition} <br/>
      <Link href={exciselist[0].Link} target='_blank'>{exciselist[0].Document || ''}</Link>
    </> : <>
      <Typography variant='subtitle2' sx={{ fontSize: 16, marginBottom: 2 }}>{t('actions__message__selectValue')}</Typography>
      <FormControl>
        <RadioGroup
          defaultValue={currentExciseRate ? currentExciseRateIndex() : ""}
          value={exciseRateByUser}
          onChange={({ target: { value: rate } }) => {
            const exciseValue = exciselist[Number(rate)];
            setMeasure(exciseValue.Measure.Razm)
            setExciseByUserRate(Number(rate));
            setValue(EXCISE_FIELDS.EXCISE_RATE, Number(rate))
          }}
          sx={{ fontSize: 14 }}
        >
          {exciselist.map((excise, index) => <FormControlLabel
            key={index}
            sx={{ marginLeft: 0 }}
            value={Number(index)}
            control={<Radio />}
            className='packaging-additional__label'
            label={<>{excise.Value} {excise.Condition}</>
            }/>)}
        </RadioGroup>
      </FormControl>
    </>}
    <Typography variant='body2'>
      <Link href={exciselist[0].Link} target='_blank' sx={{ marginTop: 1, display: 'block' }}>
        {exciselist[0].Document || ''}
      </Link>
    </Typography>
    <div className='packaging-additional__fields'>
      <TextField
        className='packaging-additional__addField'
        variant='outlined'
        {...register(EXCISE_FIELDS.EXCISE_VALUE, { required: true, validate: (value: number) => checkFloat(Number(value), t('errors__message__incorrectValue')), min: { value: 0, message: t('errors__message__incorrectValue')} })}
        error={Boolean(errors[EXCISE_FIELDS.EXCISE_VALUE])}
        label={exciselist[0].Measure.Name}
        InputLabelProps={{ shrink: true }}
        onBlur={async () => await trigger([ EXCISE_FIELDS.EXCISE_RATE ])}
        InputProps={{
          endAdornment: measure
        }}
      />
      <Button
        className='packaging-additional__cancelButton'
        variant='outlined'
        sx={{ minWidth: '196px'}}
        onClick={onClose}
      >{t('actions__text__cancel')}</Button>
      <Button
        className='packaging-additional__closeButton'
        variant='contained'
        fullWidth
        onClick={onSubmit}
        startIcon={<CheckIcon />}
        disabled={checkHasErrors()}
      >{t('actions__text__save')}</Button>
    </div>
  </div>
  <AlertBottom
    open={openAlert}
    onClose={handleCloseAlert}
    message={t('actions__message__codeSuccess')}
  />
  </>
}

export default ExciseFields
