import {useTranslation} from "react-i18next";
import SearchForm, {DELIVERY_FORM} from './components/searchForm'
import Completed from "../../../../common/completed-new";
import React, { useState, useEffect } from "react";
import ModalBig from "../../../../common/modalBig";
import ModalSearch from './components/modalSearch'
import Typography from "@mui/material/Typography";
import DeliveryCard from './components/card';
import {
  useAddServicesToRouteByOrderIdMutation, useGetAdditionalServicesByOrderIdQuery,
  useGetRouteByOrderIdQuery,
  useGetRoutesMutation, useSetCompletedByOrderIdMutation, useSummary, useSummaryCompleted, useSummaryOrder
} from "../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";
import moment from "moment";
import Button from "@mui/material/Button";
import SearchIcon from '@mui/icons-material/Search';
import {useLazyGetLocationsQuery} from "../../../../../api/locations";
import { getFlag } from "./helpers";
import './delivery.scss';

type Props = {
  handleChoose(): void
}

const Delivery = ({handleChoose}: Props) => {

  const [ shouldOpenModal, setShouldOpenModal ] = useState(false)

  const [ routes, setRoutes ] = React.useState();

  const orderId = useGetCalcIdFromRoute()

  const currentDeliveryType = useSummary()?.delivery_type;

  const summaryOrder = useSummaryOrder();

  const [ locationsRequest ] = useLazyGetLocationsQuery()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const [ addServices ] = useAddServicesToRouteByOrderIdMutation()

  const deliveryIsCompleted = useSummaryCompleted()?.delivery

  const { data: routeInfo, refetch: refetchRoutes } = useGetRouteByOrderIdQuery(orderId, { skip: !orderId })
  const { refetch: refetchAddServices } = useGetAdditionalServicesByOrderIdQuery(orderId, { skip: !orderId })

  useEffect(() => {
  }, [ currentDeliveryType, summaryOrder?.delivery_type ])
  //
  // useEffect(() => {
  //   refetchRoutes().then(() => console.log(routeInfo));
  // }, [ summaryOrder?.delivery_type ])

  const { t } = useTranslation()

  const [ getRoutes ] = useGetRoutesMutation()

  if (!orderId) {
    return null;
  }

  const handleSend = (data: DeliveryRouteParamsMain) => {
    getRoutes({orderId, ...data}).then((data) => {
      // @ts-ignore
      setRoutes(data?.data)
    });
    setShouldOpenModal(true);
  }

  const getCompletedInfo = () => {

    return [
      {
        label: `(A) ${t('common__text__fromCity')}`,
        value: <span className='delivery-info'>
          {getFlag({code: routeInfo?.location_from.country.code, height: '14px', marginRight: '4px' })}
          {`${routeInfo?.location_from?.name} ${routeInfo?.location_from?.type.toUpperCase()} (${routeInfo?.location_from?.country.name})`}</span>
      },
      {
        label: `(B) ${t('common__text__toCity')}`,
        value: <span className='delivery-info'>
          {getFlag({code: routeInfo?.location_to?.country.code, height: '14px', marginRight: '4px' })}
          {`${routeInfo?.location_to?.name} ${routeInfo?.location_from?.type.toUpperCase()} (${routeInfo?.location_to?.country.name})`}</span>
      },
      {
        label: t('calc__text__delivery__planShippingTime'),
        value: moment(routeInfo?.planned_shipment_date).format('DD.MM.YYYY'),
      }
    ]
  }

  const refetchRequests = () => {
    refetchRoutes()
    refetchAddServices()
    setCompleted({
      orderId,
      delivery: true,
      customs: false,
      additional_services: false
    })
    handleChoose();
  }

  const handleEdit = async () => {

    if (!routeInfo) {
      return;
    }

    let cityFromId;
    let cityToId;


    await locationsRequest({ id: routeInfo?.location_from?.id}).then((response) => {
      cityFromId = response?.data?.find(item => item.code === routeInfo?.location_from?.code)?.id
    })

    await locationsRequest({ id: routeInfo?.location_to?.id}).then((response) => {
      cityToId = response?.data?.find(item => item.code === routeInfo?.location_to?.code)?.id
    })

    await getRoutes({
      orderId,
      [DELIVERY_FORM.LOCATION_FROM]: cityFromId,
      [DELIVERY_FORM.LOCATION_TO]: cityToId,
      [DELIVERY_FORM.CITY_NAME_FROM]: routeInfo?.location_from.name,
      [DELIVERY_FORM.CITY_NAME_TO]: routeInfo?.location_to.name,
      [DELIVERY_FORM.PLANNED_SHIPMENT_DATE]: moment(routeInfo?.planned_shipment_date).format('YYYY-MM-DD'),
    }).then((data) => {
      // @ts-ignore
      setRoutes(data?.data)
      setShouldOpenModal(true)
    });

  }

  const handleClose = () => setShouldOpenModal(false);

  const handleRemove = async () => {
    await addServices({
      orderId,
      route: null,
      services: []
    });

    await setCompleted({
      orderId,
      delivery: false,
      additional_services: false
    })

    await refetchRoutes()
    await refetchAddServices()
  }

  return (
    <>
      {(routeInfo?.id && deliveryIsCompleted) ?
        <Completed
          info={getCompletedInfo()}
          isFullRow
          onEdit={handleEdit}
          onRemove={handleRemove}
          caption={t('calc__text__delivery__chooseRoute')}
        />
        : <SearchForm onSend={handleSend} />}
      {routeInfo?.id && deliveryIsCompleted && <>
        <Typography variant='h6' sx={{ marginBottom: 2, marginTop: 2 }}>{t('calc__text__delivery__yourChoice')}</Typography>
        <DeliveryCard isActive route={routeInfo} refetch={refetchRequests} handleRemove={handleRemove}/>
        <Button
          variant='outlined'
          startIcon={<SearchIcon />}
          fullWidth
          sx={{
            marginTop: 1,
            'svg:nth-of-type(1)': { fontSize: '24px' }
          }}
          onClick={handleEdit}
        >{t('actions__text__findOtherDeliveryOptions')}</Button>
      </>
      }
      <ModalBig
        isOpen={Boolean(shouldOpenModal && routes)}
        onClose={handleClose}
        title={t('actions__text__searchDeliveryOptions')}
        isSearch
      >
        <ModalSearch
          preloadRoutes={routes}
          refetch={refetchRequests}
          handleRemove={handleRemove}
          handleClose={handleClose}
        />
      </ModalBig>
    </>
  )
}

export default Delivery
