import React, {useEffect, useState} from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from "react-i18next"
import { ModalAddParamsLocations } from "../add";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useLocationTypes } from "../../../../../../api/dictionary";
import { MODAL_ADD_FORM } from "../add";
import InputLabel from "@mui/material/InputLabel";
import { COLORS } from "../../../../../../config/constants";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from '@mui/material/FormControl';

const TypeSelect = () => {

  const { t } = useTranslation()

  const { setValue, getValues, register, trigger } = useFormContext<ModalAddParamsLocations>()

  const allLocationTypes = useLocationTypes();

  const getLocationTypesOptions = (data?: LocationType[]) => {

    const locationTypesDictionary: { key: string; value: string; }[] = [];

    data?.map(item => locationTypesDictionary.push({
      key: item.code,
      value: item.name,
    }));

    return locationTypesDictionary;
  }

  const locationTypesOptions = getLocationTypesOptions(allLocationTypes);

  const [ typeValue, setTypeValue ] = useState( locationTypesOptions?.find(item => item.value === getValues()[MODAL_ADD_FORM.TYPE])?.value || '');

  useEffect(() => {
    if (!getValues()[MODAL_ADD_FORM.TYPE]){
      setTypeValue('')
    }
  }, [ getValues()[MODAL_ADD_FORM.TYPE] ])

  const [ error, setError ] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    const type = locationTypesOptions?.find(item => item.value === event.target.value)
    if (type) {
      setValue(MODAL_ADD_FORM.TYPE, type?.value)
      setTypeValue(type.value)
      trigger(MODAL_ADD_FORM.TYPE)
    } else {
      setError(true)
    }
  };

  return (
    <FormControl
      fullWidth
      error={error}
      {...register(MODAL_ADD_FORM.TYPE, { required: true })}
    >
      <InputLabel id='typeLocationSelect' shrink sx={{ background: COLORS.WHITE}}>{t('common__text__type')}</InputLabel>

      <Select
        labelId='typeLocationSelect'
        id='typeLocationSelect'
        value={typeValue}
        label={t('common__text__type')}
        onChange={handleChange}
        onClose={() => setError(!(getValues()[MODAL_ADD_FORM.TYPE]))}
        fullWidth
      >
        {locationTypesOptions.map(option => (
          <MenuItem {...option} key={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{t('common__text__isRequired')}</FormHelperText>}
    </FormControl>
  )
}

export default TypeSelect
