import React from "react";
import {useTranslation} from "react-i18next";
import { Controller, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import isEmpty from "lodash/isEmpty";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

import { checkTwoDecimalsAfterDot } from "../../../../../../../../../../../../../utils/validation";
import { ADD_PAYMENT_FORM, AddParamsPaymentForm } from "../../../../index";
import {ADORNMENTS} from "../../../../../../../../../../../../../config/constants";
import MenuButton from "../../../../../../../../../../../../common/menuButton";
import {useGetCalcIdFromRoute} from "../../../../../../../../../../../../../utils/hooks";

import {
  useBankCommissionsByInvoiceId,
  useUpdateBankCommissionsByInvoiceId,
} from "../../../../../../../../../../../../../api/calc";


import './payment-item-edit.scss';

type Props = {
    index: number
    invoiceAmount: number
    invoiceNumber: string
    isFirstItem?: boolean
    data: BankCommissions
    remove(id: number): void
    onClose(): void
}

const PaymentItemEdit = ({
  index,
  isFirstItem,
  invoiceAmount,
  invoiceNumber,
  remove,
  data,
  onClose
}: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const orderId = useGetCalcIdFromRoute()

  const methods = useForm<AddParamsPaymentForm>({
    defaultValues: {
      [ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]: data?.commission_percentage || '',
      [ADD_PAYMENT_FORM.PAYMENT_AMOUNT]: data?.payment_amount || '',
      [ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]: data?.bank_transfer_fee || '',
      [ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]: data?.min_bank_transfer_fee || '',
      [ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]: data?.max_bank_transfer_fee || '',
      [ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]: data?.currency_control_fee || '',
      [ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]: data?.min_currency_control_fee || '',
      [ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]: data?.max_currency_control_fee || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    trigger,
    formState: {errors},
  } = methods;

  const errorNumber = t('errors__message__incorrectValue');

  const onSubmit = handleSubmit (async (values) => {

    if (!values){
      return
    }

    const paymentData = {
      ...values,
      id: data?.id,
      index,
    }

    const dataToSend = {
      orderId,
      invoiceNumber,
      data: paymentData
    }

    // @ts-ignore
    dispatch(useUpdateBankCommissionsByInvoiceId(dataToSend));

    onClose();

  })

  const handleChangeCommissionPercentage = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const isErrorValue = value < 0 || value > 100;

    if (isErrorValue || (checkTwoDecimalsAfterDot(value, errorNumber) !== true)){
      setError(ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE, { type: "custom",  message: t('errors__message__incorrectValue') });
    } else {
      clearErrors([ ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE ])
      setValue(ADD_PAYMENT_FORM.PAYMENT_AMOUNT, (invoiceAmount *  value / 100).toString());
      trigger(ADD_PAYMENT_FORM.PAYMENT_AMOUNT);
    }

  }

  const handleChangePaymentAmount = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const isErrorValue = value < 0 || value > invoiceAmount;

    if (isErrorValue || checkTwoDecimalsAfterDot(value, errorNumber) !== true){
      setError(ADD_PAYMENT_FORM.PAYMENT_AMOUNT, { type: "custom",  message: t('errors__message__incorrectValue') });
    } else {
      clearErrors([ ADD_PAYMENT_FORM.PAYMENT_AMOUNT ])
      const percentage = (value / invoiceAmount * 100).toFixed(2);
      setValue(ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE, percentage);
      trigger(ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE);
    }

  }


  const menuOptions = [
    {
      title: t('actions__text__delete'),
      onClick: () => remove(index),
    }
  ];

  return <form className='payment-item-edit'>
    <div className='payment-item-edit__menuButtons'>
      <MenuButton options={menuOptions}/>
    </div>
    <Typography variant='subtitle1'><b>{t('calc__text__bankCommissions__invoicePayment')}</b></Typography>
    <div className='payment-item-edit__form-row'>
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE,
              {
                required: t('common__text__isRequired'),
                onChange: handleChangeCommissionPercentage
              }
            )}
            // @ts-ignore
            error={Boolean(errors[ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]?.message)}
            // @ts-ignore
            helperText={errors[ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]?.message}
            variant='outlined'
            label={`${t('calc__text__bankCommissions__payment')}, ${t('calc__text__bankCommissions__percentages')}`}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PERCENT,
            }}
          />
        )}
        name={ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}
      />
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(ADD_PAYMENT_FORM.PAYMENT_AMOUNT,
              {
                required: t('common__text__isRequired'),
                onChange: handleChangePaymentAmount,
              })}
            // @ts-ignore
            error={Boolean(errors[ADD_PAYMENT_FORM.PAYMENT_AMOUNT]?.message)}
            // @ts-ignore
            helperText={errors[ADD_PAYMENT_FORM.PAYMENT_AMOUNT]?.message}
            variant='outlined'
            label={`${t('calc__text__bankCommissions__payment')}, ${t('calc__text__bankCommissions__amount')}`}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={ADD_PAYMENT_FORM.PAYMENT_AMOUNT}
      />
    </div>
    <Typography variant='subtitle1'
      sx={{marginBottom: isFirstItem ? 2 : 1}}><b>{t('calc__text__bankCommissions__bankCommissionForTheTransfer')}</b></Typography>
    <div className='payment-item-edit__form-row'>
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(ADD_PAYMENT_FORM.BANK_TRANSFER_FEE,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors[ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]?.message)}
            // @ts-ignore
            helperText={errors[ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__commission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PERCENT,
            }}
          />
        )}
        name={ADD_PAYMENT_FORM.BANK_TRANSFER_FEE}
      />
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors[ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]?.message)}
            // @ts-ignore
            helperText={errors[ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__minCommission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE}
      />
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors[ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]?.message)}
            // @ts-ignore
            helperText={errors[ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__maxCommission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE}
      />
    </div>
    <Typography variant='subtitle1'
      sx={{marginBottom: isFirstItem ? 2 : 1}}><b>{t('calc__text__bankCommissions__currencyControlCommission')}</b></Typography>
    <div className='payment-item-edit__form-row'>
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors[ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]?.message)}
            // @ts-ignore
            helperText={errors[ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__commission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PERCENT,
            }}
          />
        )}
        name={ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE}
      />

      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors[ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]?.message)}
            // @ts-ignore
            helperText={errors[ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__minCommission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE}
      />
      <Controller
        render={() => (
          <TextField
            // @ts-ignore
            {...register(ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE,
              {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
              })}
            // @ts-ignore
            error={Boolean(errors[ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]?.message)}
            // @ts-ignore
            helperText={errors[ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]?.message}
            variant='outlined'
            label={t('calc__text__bankCommissions__maxCommission')}
            InputLabelProps={{shrink: true}}
            fullWidth
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
          />
        )}
        name={ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE}
      />
    </div>
    <div className='payment-item-edit__buttons'>
      <Button
        variant='outlined'
        sx={{ minWidth: '212px', marginRight: 3}}
        onClick={onClose}
      >{t('actions__text__cancel')}</Button>
      <Button
        variant='contained'
        fullWidth
        startIcon={<CheckIcon />}
        disabled={!isEmpty(errors)}
        sx={{ minWidth: '452px'}}
        onClick={onSubmit}
      >{t('actions__text__saveTheDeliveryData')}</Button>
    </div>
  </form>
}

export default PaymentItemEdit
