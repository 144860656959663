import React from "react";

import {useTranslation} from "react-i18next";
import {useFieldArray, useFormContext} from "react-hook-form";
import {useDispatch} from "react-redux";

import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

import {
  useSetCompletedByOrderIdMutation,
  useUpdateBankCommissionsMutation
} from "../../../../../../../../../../../api/calc";

import Completed from "../../../../../../../../../../common/completed-new";
import {PaymentsType} from "../../index";
import PaymentItemEdit from "./components/payment-item-edit";
import Notification, {NOTIFICATION_TYPE} from "../../../../../../../../../../common/notification";
import AlertBottom from "../../../../../../../../../../common/alertBottom";
import {useGetCalcIdFromRoute} from "../../../../../../../../../../../utils/hooks";
import ModalBig from "../../../../../../../../../../common/modalBig";
import {
  useUpdateBankCommissionsByInvoiceId,
} from "../../../../../../../../../../../api/calc";
import {compareBankCommissions} from "../../../../../../../../../../../utils/helpers";

import PaymentItem from "../payment-item";
import PaymentTabs from '../payment-tabs';
import CustomTabPanel from '../custom-tab-panel';

type Props = {
    bankCommissions: BankCommissions[]
    initialValues: any
    invoiceAmount: number
    invoiceId: string
    invoiceNumber: string
    refetch(): void
}

const CompletedInfo = ({
  bankCommissions,
  initialValues,
  invoiceAmount,
  invoiceId,
  refetch,
  invoiceNumber
}: Props) => {

  const { t } = useTranslation()

  const [ tabValue, setTabValue ] = React.useState(0);

  const [ formIsReady, setFormIsReady ] = React.useState(true);

  const [ openAlert, setOpenAlert ] = React.useState(false);

  const [ errorText, setErrorText ] = React.useState('');

  const [ showModal, setShowModal ] = React.useState(false);

  const [ currentIndex, setCurrentIndex ] = React.useState(0);

  const [ addButtonHasClicked, setAddButtonHasClicked ] = React.useState<boolean>(false);

  const {  control, getValues, watch, handleSubmit } = useFormContext<PaymentsType>()

  const [ saveBankCommissions ] = useUpdateBankCommissionsMutation()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const orderId = useGetCalcIdFromRoute()

  const dispatch = useDispatch()

  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: 'payments',
  });

  const sortedBankCommissionsInvoices = bankCommissions.slice().sort(compareBankCommissions);

  const allFieldsFilled = (item: any) => Object.values(item).every((value) => value !== '');

  const allItemsFilled = () => {
    const allPayments = getValues().payments;
    return allPayments.every((payment) => allFieldsFilled(payment));
  };

  watch(() => {
    if (addButtonHasClicked){
      setFormIsReady(allItemsFilled());
    }
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onSubmit = handleSubmit (async (values) => {

    try {

      const newBankCommissions = [
        ...sortedBankCommissionsInvoices,
        ...(addButtonHasClicked ? values.payments : []),
      ];

      const response: any = sortedBankCommissionsInvoices && await saveBankCommissions({
        invoiceId,
        payments: newBankCommissions
      })

      if (response?.error) {
        setErrorText(response.error.data.error || t("common__text__error"));
      } else {
        setErrorText('')
        setOpenAlert(true)
        refetch()
      }
    } catch (error) {
      setErrorText(t("common__text__error"));
    } finally {
      setCompleted({
        orderId,
        banking_commissions: false,
        additional_services: false
      })
    }
  })

  const fieldsItems = [
    ...sortedBankCommissionsInvoices,
    ...(addButtonHasClicked ? fields : []),
  ];

  const handleRemovePaymentFromArray = (index: number) => {
    if (fields.length == index + 1) {
      setTabValue(fieldsItems.length - 2);
    }

    remove(index);
  }

  const handleRemove = (index: number, id?: number) => {

    if (!id){
      return;
    }

    const dataToSend = {
      orderId,
      invoiceNumber,
      data: {
        id
      },
      isRemove: true
    }

    // @ts-ignore
    dispatch(useUpdateBankCommissionsByInvoiceId(dataToSend));

    if (index === fieldsItems.length - 1){
      setTabValue(fieldsItems.length - 2);
    }
  }


  const handleRemoveEdit = (index: number, id?: number) => {
    handleRemove(index, id);
    setShowModal(false);
  }

  const handleClickNewButton = () =>{
    setAddButtonHasClicked(true);
    setTabValue(fieldsItems.length);
  }

  return (
    <>
      <div className='payments-tabs'>
        <PaymentTabs
          tabValue={tabValue}
          handleChange={handleChange}
          fields={fieldsItems}
          startFieldsIndex={sortedBankCommissionsInvoices.length}
          getValues={getValues}
          append={append}
          initialValues={initialValues}
          onClickNewButton={handleClickNewButton}
          shouldAppendItem={addButtonHasClicked}
        />
        {sortedBankCommissionsInvoices.map((field, index) => {

          const completedInfo = [
            {
              label: `${t('calc__text__bankCommissions__payment')} ${field.commission_percentage}%`,
              value: `${field.payment_amount} USD`,
            },
            {
              label: t('calc__text__bankCommissions__bankCommissionForTheTransfer'),
              value: `${field.bank_transfer_fee}% (${t('common__text__from')} ${field.min_bank_transfer_fee} USD ${t('common__text__before')} ${field.max_bank_transfer_fee} USD)`,
            },
            {
              label: t('calc__text__bankCommissions__currencyControlCommission'),
              value: `${field.currency_control_fee}% (${t('common__text__from')} ${field.min_currency_control_fee} USD ${t('common__text__before')} ${field.max_currency_control_fee} USD)`,
            },
          ]

          const handleEdit = () => {
            setShowModal(true);
            setCurrentIndex(index);
          }

          return <CustomTabPanel value={tabValue} index={index} key={index}>
            <div className='payments-tabs-completed'>
              <Completed
                info={completedInfo}
                caption={`${t('calc__text__bankCommissions__payment')} ${index + 1} ${t('common__text__of')} ${fieldsItems.length}`}
                isFullRow
                onEdit={handleEdit}
                onRemove={sortedBankCommissionsInvoices.length == 1 ?  undefined : () => handleRemove(index, field.id)}
              />
            </div>
          </CustomTabPanel>
        })}
        {fields.map((field, index) => {
          const isFirstItem = index === 0;
          return (
            <CustomTabPanel value={tabValue} index={sortedBankCommissionsInvoices.length + index} key={sortedBankCommissionsInvoices.length + index}>
              <PaymentItem
                control={control}
                index={index}
                isFirstItem={isFirstItem}
                invoiceAmount={invoiceAmount}
                remove={handleRemovePaymentFromArray}
              />
            </CustomTabPanel>
          )
        })}
      </div>
      {<Button
        variant='contained'
        fullWidth
        startIcon={<CheckIcon/>}
        disabled={!formIsReady}
        onClick={onSubmit}
        sx={{marginTop: 3}}
      >{`${t('calc__text__bankCommissions__savePayments')} №${invoiceNumber}`}</Button>}
      {errorText && <Notification
        type={NOTIFICATION_TYPE.WARNING}
        title={t('common__message__warning')}
        text={errorText}
        sx={{marginTop: 3}}
        noCloseButton
      />}
      {openAlert && (
        <AlertBottom
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          message={t('common__message__dataSavedSuccessfully')}
        />
      )}
      <ModalBig
        isOpen={showModal}
        onClose={()=> setShowModal(false)}
        title={`${t('calc__text__bankCommissions__editPayment')}-${currentIndex + 1}, ${t('common__text__invoice')} № ${invoiceNumber}` }
      >
        <PaymentItemEdit
          index={currentIndex}
          isFirstItem={true}
          invoiceAmount={invoiceAmount}
          remove={() => handleRemoveEdit(currentIndex, sortedBankCommissionsInvoices[currentIndex].id)}
          onClose={()=> setShowModal(false)}
          invoiceNumber={invoiceNumber}
          data={sortedBankCommissionsInvoices[currentIndex]}
        />
      </ModalBig>
    </>
  );
}

export default CompletedInfo
