import React from "react";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Table from '../../../components/table';
import TableRowActions from '../../../components/table_row_actions';

import isArray from 'lodash/isArray'

import './table.scss';

import {useTransportCompanies} from "../../../../../../api/dictionary";

import { ACTIONS } from "../../../constants";


type Props = {
  selectedRows?: string[];
  currentTransportCompanies?: string[]
  handleEdit(): void
  handleRemove(): void
  handleTransportCompaniesRequest(data?: DictionaryParamsInput): void
  changeTransportCompany(id: string[]): void
}

const TransportCompaniesTable = ({ handleEdit, handleRemove, handleTransportCompaniesRequest, changeTransportCompany, currentTransportCompanies }: Props) => {

  const { t } = useTranslation()

  const transportCompanies = useTransportCompanies().data;

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('common__text__name'),
    },
    {
      id: 'name_en',
      numeric: false,
      disablePadding: false,
      label: t('dictionaries__text__transportCompany__addTransportCompanyNameEnglish'),
    },
  ];

  const preferences = [
    {
      id: 'name',
      width: 250,
    },
    {
      id: 'name_en',
      width: 250,
    },
  ];

  if (!isArray(transportCompanies.results)){
    return null;
  }

  return (
    <div className='transport-company-table'>
      <Table
        rows={transportCompanies.results}
        count={transportCompanies.count}
        headCells={headCells}
        preferences={preferences}
        chooseItem={changeTransportCompany}
        primaryField='id'
        handleSendRequest={handleTransportCompaniesRequest}
        hasEmptySelection={!currentTransportCompanies?.length}
        actionsAfterTable={(selected: string[]) =>
          <TableRowActions hasEmptySelection={!selected.length}>
            {selected.length === 1 && <MenuItem
              value={ACTIONS.EDIT}
              onClick={handleEdit}
            >{t('actions__text__edit')}</MenuItem>}
            <MenuItem
              value={ACTIONS.REMOVE}
              onClick={handleRemove}
            >{t('actions__text__delete')}</MenuItem>
          </TableRowActions>}
      />
    </div>
  )
};

export default TransportCompaniesTable
