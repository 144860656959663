import React, { useEffect } from "react";

import {useTranslation} from "react-i18next";
import ModalRemove from '../../../components/modal_remove';
import {
  useRemoveTransportCompanyMutation, useTransportCompanies, useTransportCompaniesById
} from "../../../../../../api/dictionary";
import {NOT_FOUND_STATUS} from "../../../../../../utils/constants";

type Props = {
    isOpen: boolean
    ids: string[]
    onClose(): void
    handleTransportCompaniesRequest(params?: DictionaryParamsInput): Promise<any>
    onRemoved(isSuccess: boolean): void
}

const Remove = ({isOpen, onClose, ids, handleTransportCompaniesRequest, onRemoved}: Props) => {

  const { t } = useTranslation()

  const [ removeTransportCompanyRequest ] = useRemoveTransportCompanyMutation()

  const transportCompaniesInfoByIds = ids.map((id: string) => useTransportCompaniesById(id))

  const transportCompaniesParams = useTransportCompanies()?.params;

  useEffect(() => {
  }, [ isOpen ])

  if (!transportCompaniesInfoByIds.length){
    return null;
  }

  const handleRemove = async () => {

    const response = await removeTransportCompanyRequest({transport_company_ids: ids});

    // @ts-ignore
    if (response?.error) {
      onRemoved(false);
    } else {
      onRemoved(true);
      handleTransportCompaniesRequest().then((data) => {
        if (data?.error?.status === NOT_FOUND_STATUS) {
          // @ts-ignore
          handleTransportCompaniesRequest({ page: transportCompaniesParams ? transportCompaniesParams.page - 1 : 1})
        }
      })
      onClose();
    }
  }

  return (<ModalRemove
    isOpen={isOpen}
    onClose={onClose}
    onRemoved={handleRemove}
    additionalField={'name_en'}
    title={t('dictionaries__text__transportCompany__removeTransportCompanyModal')}
    areYouSureText={transportCompaniesInfoByIds.length > 1 ? t('dictionaries__text__transportCompany__areYouSureToRemoveSeveral') : t('dictionaries__text__transportCompany__areYouSureToRemoveOne')}
    // @ts-ignore
    items={transportCompaniesInfoByIds}
  />
  );
}

export default Remove
