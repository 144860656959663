import React from "react";

import { useTranslation } from "react-i18next";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {COLORS, ROUTES} from "../../../config/constants";
import {useLocation, useNavigate} from 'react-router-dom'
import { useUserIsManager } from "../../../api/auth";

import './menu.scss'

const Menu = () => {

  const [ openDocuments, setOpenDocuments ] = React.useState(false);

  const handleClickDocuments = () => setOpenDocuments(!openDocuments);

  const [ openDictionaries, setOpenDictionaries ] = React.useState(false);

  const handleClickDictionaries = () => setOpenDictionaries(!openDictionaries);

  const { t } = useTranslation()

  const location = useLocation()

  const navigate = useNavigate()

  const userIsManager = useUserIsManager();

  const handleClickCalculator = () => {
    if (location.pathname.includes(ROUTES.CALCULATOR)){
      return;
    }
    navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}`);
  }

  return (
    <div className='menu'>
      <List
        sx={{ width: '100%', maxWidth: 232, margin: '32px auto', padding: '0 8px'}}
        component='nav'
      >
        <ListItemButton selected={location.pathname === `/${ROUTES.CABINET}`} onClick={() => navigate(`/${ROUTES.CABINET}`)}>
          <ListItemText primary={t('menu__text__main')} />
        </ListItemButton>
        <ListItemButton selected={location.pathname.includes(ROUTES.CALCULATOR)} onClick={handleClickCalculator}>
          <ListItemText primary={t('menu__text__calculator')}/>
        </ListItemButton>
        <ListItemButton selected={location.pathname.includes(ROUTES.CALCULATIONS)} onClick={() => navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATIONS}`)}>
          <ListItemText primary={t('menu__text__calculations')}/>
        </ListItemButton>
        <ListItemButton>
          <ListItemText primary={t('menu__text__orders')}/>
        </ListItemButton>
        <ListItemButton>
          <ListItemText primary={t('menu__text__shipments')}/>
        </ListItemButton>
        <ListItemButton onClick={handleClickDocuments}>
          <ListItemText primary={t('menu__text__documents')}/>
          {openDocuments ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openDocuments} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItemButton sx={{ pl: 7 }} className='menu__button'>
              <ListItemText
                secondary={t('menu__text__contracts')}
                secondaryTypographyProps={{
                  color: COLORS.BLACK
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 7 }} className='menu__button'>
              <ListItemText
                secondary={t('menu__text__accounts')}
                secondaryTypographyProps={{
                  color: COLORS.BLACK
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 7 }} className='menu__button'>
              <ListItemText
                secondary={t('menu__text__closingDocuments')}
                secondaryTypographyProps={{
                  color: COLORS.BLACK
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 7 }} className='menu__button'>
              <ListItemText
                secondary={t('menu__text__consignments')}
                secondaryTypographyProps={{
                  color: COLORS.BLACK
                }}
              />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton selected={location.pathname.includes(ROUTES.SETTINGS)} onClick={() => navigate(`/${ROUTES.CABINET}/${ROUTES.SETTINGS}`)}>
          <ListItemText primary={t('menu__text__settings')} />
        </ListItemButton>
        <ListItemButton>
          <ListItemText primary={t('menu__text__support')}/>
        </ListItemButton>
        {userIsManager && <>
          <ListItemButton onClick={handleClickDictionaries} selected={location.pathname.includes(ROUTES.DICTIONARIES)}>
            <ListItemText primary={t('menu__text__dictionaries')}/>
            {openDictionaries ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openDictionaries} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton sx={{ pl: 7 }} className='menu__button' onClick={() => navigate(`/${ROUTES.DICTIONARIES}/${ROUTES.CURRENCY}`)}>
                <ListItemText
                  secondary={t('menu__text__currency')}
                  secondaryTypographyProps={{
                    color: COLORS.BLACK
                  }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 7 }} className='menu__button' onClick={() => navigate(`/${ROUTES.DICTIONARIES}/${ROUTES.LOCATIONS}`)}>
                <ListItemText
                  secondary={t('menu__text__locations')}
                  secondaryTypographyProps={{
                    color: COLORS.BLACK
                  }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 7 }} className='menu__button' onClick={() => navigate(`/${ROUTES.DICTIONARIES}/${ROUTES.CITIES}`)}>
                <ListItemText
                  secondary={t('menu__text__cities')}
                  secondaryTypographyProps={{
                    color: COLORS.BLACK
                  }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 7 }} className='menu__button' onClick={() => navigate(`/${ROUTES.DICTIONARIES}/${ROUTES.COUNTRIES}`)}>
                <ListItemText
                  secondary={t('menu__text__countries')}
                  secondaryTypographyProps={{
                    color: COLORS.BLACK
                  }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 7 }} className='menu__button' onClick={() => navigate(`/${ROUTES.DICTIONARIES}/${ROUTES.INCOTERMS}`)}>
                <ListItemText
                  secondary={t('menu__text__incoterms')}
                  secondaryTypographyProps={{
                    color: COLORS.BLACK
                  }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 7 }} className='menu__button' onClick={() => navigate(`/${ROUTES.DICTIONARIES}/${ROUTES.TRANSPORT_COMPANIES}`)}>
                <ListItemText
                  secondary={t('menu__text__transport_companies')}
                  secondaryTypographyProps={{
                    color: COLORS.BLACK
                  }}
                />
              </ListItemButton>
            </List>
          </Collapse>
        </>}
      </List>
    </div>
  )
}

export default Menu
