import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import debounce from "lodash/debounce";

import Typography from "@mui/material/Typography";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
import Pagination from '@mui/material/Pagination';
import Divider from "@mui/material/Divider";

import {
  useGetExtraServicesSearchByOrderIdMutation,
  useParamsExtraServicesData,
  useSetExtraServicesByOrderIdMutation, useUserExtraServices,
} from "../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";


import FilterCost from './filterCost';
import ServiceProviders from './serviceProviders';

import CircularProgress from "@mui/material/CircularProgress";
import CompanyCard from "../company-card";
import CheckIcon from "@mui/icons-material/Check";

export const enum SORTING_OPTIONS {
    PRICE_DESK = 'price',
    PRICE_ASC = '-price',
}

export const enum RESULTS_COUNT {
    COUNT_10 = 10,
    COUNT_30 = 30,
    COUNT_45 = 45
}

type Props = {
    preloadCompanies?: {
      results: ExtraService[]
      count: number
    }
    handleChoose(): void
    refetchGetExtraServices(): void
}

const ModalSearch = ({preloadCompanies, handleChoose, refetchGetExtraServices} : Props ) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ companies, setCompanies ] = React.useState<ExtraService[]>(preloadCompanies?.results || []);
  const [ totalCompanies, setTotalCompanies ] = React.useState(preloadCompanies?.results.length);

  const [ showProgressBar, setShowProgressBar ] = useState(true);

  const [ noFilterValues, setNoFilterValues ] = React.useState(true);

  const [ getCompanies ] = useGetExtraServicesSearchByOrderIdMutation()
  const [ setExtraServices ] = useSetExtraServicesByOrderIdMutation()

  const filterData = useParamsExtraServicesData();

  const userExtraServices = useUserExtraServices()

  useEffect(() => {
    if (preloadCompanies?.results){
      setCompanies(preloadCompanies?.results);
      setTotalCompanies(preloadCompanies?.results.length);
    }
  }, [ preloadCompanies?.results ])

  useEffect(() => {
    setTimeout(() => {
      setShowProgressBar(false)
    }, 1000);
  }, [ ])

  const companiesRequest = (data?: OrdersParamsExtraServices) => {
    getCompanies({
      orderId,
      ...filterData,
      ...data,
    }).then((data) => {
      // @ts-ignore
      setCompanies(data.data.results);
      // @ts-ignore
      setTotalCompanies(data.data.count);
    });
  }

  const handleChangeResultsCount = (value: string | RESULTS_COUNT ) => companiesRequest({
    limit: Number(value)
  });

  const handleChangeCurrentPage = (event: React.ChangeEvent<unknown>, pageNumber: number) => companiesRequest({
    page: pageNumber
  });

  const handleChangeCost = (value: number[]) => {
    companiesRequest({
      price_min: value[0],
      price_max: value[1],
    });
    setNoFilterValues(false);
  }

  const handleChangeCompanies = (value: number[]) => {
    companiesRequest({
      company_id: value
    });
    setNoFilterValues(false);
  }

  const debouncedRequestChangeCost = useRef(debounce(handleChangeCost, 500)).current

  const handleClearRequest = () => {
    companiesRequest({
      price_min: undefined,
      price_max: undefined,
      limit: undefined,
      page: undefined,
      company_id: undefined
    });
    setNoFilterValues(true);
  }

  const resultsCount = filterData.limit || RESULTS_COUNT.COUNT_30;

  const userExtraServicesServiceItems = userExtraServices?.flatMap(item => [
    ...(item.id !== undefined ? [ item.id ] : []),
    ...(item.services || []).map(service => service.id),
  ]);

  return (
    <div className='modal-search-container'>
      <div className='search-results'>
        {showProgressBar ? <CircularProgress/> : (
          <div className='search-results__container'>
            <div className='search-results__results'>
              <div className='search-results__sorting'>
                <FormControl sx={{width: '324px'}}>
                  <InputLabel id='sorting-select'>{t('common__text__sort')}</InputLabel>
                  <Select
                    labelId='sorting-select'
                    id='sorting-select'
                    value={filterData.order_by || SORTING_OPTIONS.PRICE_DESK}
                    label={t('common__text__sort')}
                    onChange={({target: {value}}) => companiesRequest({
                      order_by: value
                    })}
                    className='search-results__select-sorting'>
                    <MenuItem
                      value={SORTING_OPTIONS.PRICE_DESK}>{t('dictionaries__text__certification__priceAscendingOrder')}</MenuItem>
                    <MenuItem
                      value={SORTING_OPTIONS.PRICE_ASC}>{t('dictionaries__text__certification__priceDescendingOrder')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel id='count-select'>{t('common__text__resultsCount')}</InputLabel>
                  <Select
                    labelId='count-select'
                    id='count-select'
                    value={resultsCount}
                    label={t('common__text__resultsCount')}
                    onChange={({target: {value}}) => handleChangeResultsCount(value)}
                    className='search-results__select-count'>
                    <MenuItem value={RESULTS_COUNT.COUNT_10}>10</MenuItem>
                    <MenuItem value={RESULTS_COUNT.COUNT_30}>30</MenuItem>
                    <MenuItem value={RESULTS_COUNT.COUNT_45}>45</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {userExtraServices && userExtraServices?.length > 0 && (<>
                <Typography variant='h6' className='customs-title__name' sx={{marginBottom: 3}}>
                  {t('calc__text__delivery__yourChoice')}
                </Typography>
                {userExtraServices.map(userExtraService =>
                  <CompanyCard
                    isActive
                    company={userExtraService}
                    key={userExtraService.id}
                    refetchGetExtraServices={refetchGetExtraServices}
                  />)}
              </>)}
              <Divider sx={{marginBottom: 3}}/>
              {!totalCompanies && <div className='search-results__results'>
                <Typography variant='h6' gutterBottom sx={{marginTop: 1}}>
                  {t('actions__message__searchNotFound')}
                </Typography>
                <Divider sx={{marginBottom: 3}}/>
              </div>}
              {companies.map((company) => <CompanyCard
                key={company.id}
                company={company}
                userServices={userExtraServicesServiceItems}
                refetchGetExtraServices={refetchGetExtraServices}
              />
              )}
              {Number(totalCompanies) > resultsCount && <Pagination
                count={Math.ceil(Number(totalCompanies) / resultsCount)}
                sx={{marginTop: 3}}
                size='large'
                onChange={handleChangeCurrentPage}
              />}
            </div>
            <Card className='search-results__filters'>
              <CardContent sx={{padding: 0}}>
                <Typography variant='h6' gutterBottom sx={{padding: '16px 24px 0'}}>
                  {t('common__text__filters')}
                </Typography>
                <FilterCost onChange={debouncedRequestChangeCost} noFilterValues={noFilterValues}/>
                <ServiceProviders onChange={handleChangeCompanies}/>
                <Button
                  startIcon={<ClearIcon/>}
                  variant='outlined'
                  fullWidth
                  sx={{
                    marginTop: 3,
                    marginBottom: 0,
                    marginLeft: 3,
                    maxWidth: '312px',
                  }}
                  onClick={handleClearRequest}
                >{t('actions__text__clearFilters')}</Button>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </div>
  )
}

export default ModalSearch
