import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import {useState} from "react";
import './notification.scss'

export const enum NOTIFICATION_TYPE {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info'
}

type Props = {
    type: NOTIFICATION_TYPE;
    title: string;
    text?: React.ReactNode;
    noCloseButton?: boolean
    sx?: object;
}


const Notification = ( { type, title, text, sx, noCloseButton } : Props ) => {

  const [ showNotification, setShowNotification ] = useState(true);

  if (!showNotification) {
    return null
  }

  return (
    <Stack sx={{ width: '100%', ...sx }} spacing={2} className='notification'>
      <Alert severity={type} onClose={noCloseButton ? undefined : () => setShowNotification(false)}>
        <AlertTitle className='notification__title'>{title}</AlertTitle>
        {text && <div className='notification__description'>
          {text}
        </div>}
      </Alert>
    </Stack>
  );
}

export default Notification
