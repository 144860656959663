import React from "react";

import Typography from "@mui/material/Typography";
import { COLORS } from "../../../../../../../config/constants";
import {useTranslation} from "react-i18next";

import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import './modal_table.scss';
import {getMergeVatRates} from "../modal_chooseVat";

type Props = {
  children: React.ReactNode
  title: string
}

const ModalTableAccordion = ({children, title}: Props) => (
  <Accordion sx={{
    boxShadow: 'none',
    marginBottom: 0,
    borderBottom: `1px solid ${COLORS.BORDER_GRAY}`,
    ':before': {
      opacity: 0
    },
    '&.Mui-expanded': {
      marginBottom: 0,
      paddingBottom: 0,
      ':last-of-type': {
        marginBottom: 0,
      },
      ':before': {
        opacity: 0
      },
    }
  }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{ padding: 0 }}
    >
      <Typography variant='body1' sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '24px 0 0' }}>
      {children}
    </AccordionDetails>
  </Accordion>
)

type PropsModalTable = {
  tnvedInfoVat: Vat[]
  tnvedInfoHierarchy: CodeTnved[]
}

const ModalTable = ({tnvedInfoVat, tnvedInfoHierarchy}: PropsModalTable) => {

  const mergeVatRates = getMergeVatRates(tnvedInfoVat);
  const nameVat = Object.keys(mergeVatRates)[0];
  const vatData = Object.values(mergeVatRates)[0];

  const { t } = useTranslation()

  return <>
    <ModalTableAccordion title={t('calc__text__customs__HSPosition')}>
      <table className='code-table'>
        <tbody>
          {tnvedInfoHierarchy.map((item, index) => (<tr key={item.Level}>
            <td>
              <Typography variant='body2' sx={{ color: COLORS.BLACK, fontWeight: 500 }}>
                {item.Code}
              </Typography>
            </td>
            <td>
              <Typography variant='body2' sx={{ color: COLORS.BLACK, paddingBottom: 2 }}>
                {item.Name}
              </Typography>
            </td>
          </tr>))}
        </tbody>
      </table>
    </ModalTableAccordion>
    <ModalTableAccordion title={t('calc__text__customs__customsDutiesAndImport')}>
      <table className='code-table'>
        <tbody>
          <tr>
            <td>
              <Typography variant='body2' sx={{ color: COLORS.BLACK, fontWeight: 500,  paddingBottom: 2 }}>
                {t('calc__text__customs__basicRateOfCustomsDuty')}
              </Typography>
            </td>
            <td>
              <Typography variant='body2' sx={{ color: COLORS.BLACK, fontWeight: 500 }}>
                1.75 евро/кг
              </Typography>
              <Typography variant='body2' sx={{ color: COLORS.BLACK }}>
                <a href='' className='code-table__link'>реш.80</a>
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant='body2' sx={{ color: COLORS.BLACK, fontWeight: 500 }}>
                {t('common__text__vatRate')}
              </Typography>
            </td>
            <td>
              {vatData[0].Directory.RuName !== null && (<Typography variant='body2' sx={{ color: COLORS.BLACK, fontWeight: 500}}>
                {vatData[0].Directory.RuName}
              </Typography>)}
              <Typography variant='body2' sx={{ color: COLORS.BLACK }}>
                {nameVat}
                <br/>
                <a href={vatData[0].Link} target='_blank' className='code-table__link'>{vatData[0].Document}</a>
              </Typography>
              <Typography variant='body2' sx={{ color: COLORS.BLACK }}>
                <ul className='code-table__list'>
                  {vatData.map((vat, index) => (
                    <li key={index}>
                      <span className='code-table__bold'>{vat?.ValueDetail?.ValueCount}{vat?.ValueDetail?.ValueUnit}</span> {vat.Condition}
                    </li>
                  ))}
                </ul>
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </ModalTableAccordion>
  </>
}

export default ModalTable
