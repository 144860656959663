import React, {useState} from "react";

import sortBy from "lodash/sortBy";
import {useTranslation} from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";

import Invoice from './components/invoice';
import Summary from './components/summary';

import {
  useGetInvoices,
} from "../../../../../api/calc";
import {COLORS} from "../../../../../config/constants";

import './packaging.scss';

type Props = {
  handleComplete(): void
}

// const handleComplete = (hasRemoved?: boolean) => {
//
//   // @ts-ignore
//   if (!orderId) {
//     return
//   }
//
//   if (!isLoadedFirstTime) {
//
//     const resetParams = {
//       packaging: false,
//       delivery: false,
//       customs: false,
//       additional_services: false
//     }
//
//     setCompleted({
//       orderId,
//       common: hasRemoved? invoices?.length !== 1 : true,
//       ...(isSuccess && invoices?.length !== 1 && resetParams)
//     })
//   }
// }


// const handleRemove = () => {
//   if (invoices?.length === 1){
//     handleComplete(true);
//   }
// }

const getInvoicesList = (invoices: InvoiceParamsOutput[]) =>
  invoices.map((invoice: InvoiceParamsOutput, index) =>
    <div className='invoice-container' key={invoice.id}>
      <Invoice index={index} invoice={invoice} />
    </div>)

const InvoicesAndProducts = ({handleComplete} : Props) => {

  const { t } = useTranslation()

  const [ showNewInvoiceForm, setShowNewInvoiceForm ] = useState(false);

  const invoices = useGetInvoices();

  const productsCount = invoices.reduce(
    (accumulator, invoice) => accumulator + (invoice?.product_count || 0),
    0
  );

  const sortedInvoices = sortBy(invoices, 'id');

  const handleAddNewInvoice = () => setShowNewInvoiceForm(true);

  const onCloseInvoiceForm = () => {
    setShowNewInvoiceForm(false);
  }

  return <>
    {sortedInvoices.length > 0 ? (
      <>
        {getInvoicesList(sortedInvoices)}
        {showNewInvoiceForm && <Invoice index={sortedInvoices.length} openByDefault onCloseInvoiceForm={onCloseInvoiceForm}/>}
        <button className='invoices__add' onClick={handleAddNewInvoice}>
          <AddIcon sx={{marginRight: 1, color: COLORS.BLUE}}/>
          <Typography variant='body1' sx={{color: COLORS.BLUE}}>
            {t('calc__text__main__createInvoice')}
          </Typography>
        </button>
      </>)
      : <Invoice index={0} openByDefault />}
    {productsCount > 0 && <Summary productsCount={productsCount} handleComplete={handleComplete}/>}
  </>
}

export default InvoicesAndProducts
