import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  const location = useLocation();

  const hashElement = useMemo(() => {
    const hash = location.hash;
    const removeHashCharacter = (str: string) => str.slice(1);

    if (hash) {
      return document.getElementById(removeHashCharacter(hash));
    } else {
      return null;
    }
  }, [ location ]);

  useEffect(() => {
    if (hashElement) {

      window.scrollTo({
        behavior: 'smooth',
        top:
            hashElement.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            10,
      })

    }
  }, [ hashElement ]);

  return null;
};

export default ScrollToHashElement;
