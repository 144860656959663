import React from "react";
import { useTranslation } from "react-i18next";
import {useFormContext} from "react-hook-form";

import TextField from "@mui/material/TextField";

import { PACKAGE_FORM, PackageForm } from '../add_form/helpers';

import {checkTwoDecimalsAfterDot} from '../../../../../../../../../../../../../utils/validation'
import {ADORNMENTS} from "../../../../../../../../../../../../../config/constants";

const CommonPackage = () => {

  const { t } = useTranslation()

  const { register, formState, getValues, trigger } = useFormContext<PackageForm>()

  const { errors } = formState;

  const errorValue = t('errors__message__incorrectValueTwoDecimalsAfterDot');

  const checkGrossWeightValueIsCorrect = (value: number) => {
    const netValue = getValues()[PACKAGE_FORM.NET_WEIGHT_MANUAL]

    const hasTwoDecimalsError = checkTwoDecimalsAfterDot(value, errorValue);

    if (hasTwoDecimalsError !== true) {
      return hasTwoDecimalsError
    }

    return Number(value) > Number(netValue) ? true: t('errors__message__incorrectValueGrossWeight');

  }

  return <>
    <div className='add-product__row'>
      <TextField
        className={`add-product__${PACKAGE_FORM.VOLUME_MANUAL}`}
        {...register(PACKAGE_FORM.VOLUME_MANUAL, { required: true, validate: (value?: number) => checkTwoDecimalsAfterDot(value, errorValue), min: { value: 0, message: t('errors__message__incorrectValueTwoDecimalsAfterDot')} })}
        error={Boolean(errors[PACKAGE_FORM.VOLUME_MANUAL])}
        helperText={errors[PACKAGE_FORM.VOLUME_MANUAL]?.message}
        variant='outlined'
        label={t('common__text__totalVolume')}
        // disabled={isLoading}
        InputProps={{
          endAdornment: ADORNMENTS.VOLUME,
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        {...register(PACKAGE_FORM.NET_WEIGHT_MANUAL, { required: true, validate: (value: number) => checkTwoDecimalsAfterDot(value, errorValue), min: { value: 0, message: t('errors__message__incorrectValueTwoDecimalsAfterDot')} })}
        error={Boolean(errors[PACKAGE_FORM.NET_WEIGHT_MANUAL])}
        helperText={errors[PACKAGE_FORM.NET_WEIGHT_MANUAL]?.message}
        variant='outlined'
        label={t('common__text__totalNetWeight')}
        // disabled={isLoading}
        InputProps={{
          endAdornment: ADORNMENTS.WEIGHT,
        }}
        InputLabelProps={{ shrink: true }}
        onBlur={async () => {
          if (getValues(PACKAGE_FORM.GROSS_WEIGHT_MANUAL) && getValues(PACKAGE_FORM.NET_WEIGHT_MANUAL)) {
            await trigger([ PACKAGE_FORM.GROSS_WEIGHT_MANUAL ])
          }
        }}
      />
      <TextField
        {...register(PACKAGE_FORM.GROSS_WEIGHT_MANUAL, { required: true, validate: (value: number) => checkGrossWeightValueIsCorrect(value), min: { value: 0, message: t('errors__message__incorrectValueTwoDecimalsAfterDot')} })}
        error={Boolean(errors[PACKAGE_FORM.GROSS_WEIGHT_MANUAL])}
        helperText={errors[PACKAGE_FORM.GROSS_WEIGHT_MANUAL]?.message}
        variant='outlined'
        label={t('common__text__totalGrossWeight')}
        // disabled={isLoading}
        InputProps={{
          endAdornment: ADORNMENTS.WEIGHT,
        }}
        InputLabelProps={{ shrink: true }}
      />
    </div>
  </>
}

export default CommonPackage
