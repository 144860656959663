import React from "react";

import Typography from "@mui/material/Typography";

import {useTranslation} from "react-i18next";

import ModalBig from "../../../../../../common/modalBig";

import ModalTable from '../modal_table';
import './modal_code.scss';
import isArray from "lodash/isArray";
import {getTnvedVAT} from "../../utils";

type Props = {
    isOpen: boolean
    onClose(): void
    insideModal?: boolean
    code: string
    tnvedInfo?: TnvedInfo
}

const ModalCode = ( {isOpen, onClose, insideModal, code, tnvedInfo}: Props) => {

  const { t } = useTranslation()

  if (!tnvedInfo) {
    return null;
  }
  const tnvedInfoVat = getTnvedVAT(tnvedInfo);
  const name = tnvedInfo.GoodInfo.Name;

  return <ModalBig
    isOpen={isOpen}
    onClose={onClose}
    title={`${t('common__text__codeOfHS')} ${code}`  }
    insideModal={insideModal}
  >
    <>
      <Typography variant='h6'>
        {t('calc__text__customs__productCodeInformation')} {code}
      </Typography>
      {name && (
        <Typography variant='body1' sx={{ marginTop: 1, marginBottom: 1 }}>
          {name}
        </Typography>
      )}
      {tnvedInfo.Hierarchy && tnvedInfoVat && <ModalTable tnvedInfoHierarchy={tnvedInfo.Hierarchy} tnvedInfoVat={tnvedInfoVat} />}
      <Typography variant='body2' sx={{ padding: '24px 0 0' }}>
        {t('calc__text__customs__dataSource')}: <a href='' className='code-table__link'>
          {`https://www.alta.ru/tnved/code/${code}/`}
        </a>
      </Typography>
    </>
  </ModalBig>
}

export default ModalCode
