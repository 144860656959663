import { createContext, useContext } from "react";

type AuthContextProps = {
  user: User | undefined
  isSuccess: boolean
  isNewUser: boolean
  setUser: (user?: User) => void,
  setIsSuccess: (value: boolean) => void,
}
export const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  isSuccess: false,
  isNewUser: false,
  setUser: (user?: User) => {},
  setIsSuccess: (value: boolean) => {},
});

export const useAuthContext = () => useContext(AuthContext);
