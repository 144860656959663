import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import {
  useGetExtraServicesCompaniesForFilterQuery, useParamsExtraServicesData
} from "../../../../../../../api/calc";
import Template from '../../../../../../common/templateFilter';
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";

type Props = {
  onChange(value: number[]): void
}

const ServiceProviders = ({onChange}: Props) => {

  const { t } = useTranslation()
  const orderId = useGetCalcIdFromRoute()

  const [ companyIds, setCompanyIds ] = useState<number[]>([]);

  const filterData = useParamsExtraServicesData();

  const { data: companiesList } = useGetExtraServicesCompaniesForFilterQuery(orderId)

  useEffect(() => {
    if (!filterData.company_id && companiesList) {
      const array: number[] = [];
      companiesList.map((item) => array.push(item.id));
      setCompanyIds(array);
    }
  }, [ filterData, companiesList ])

  const handleChangeCheckbox = (value: number) => {
    if (companyIds.includes(value)) {
      const newArray = companyIds.filter((item) => item !== value)
      setCompanyIds(newArray)
      onChange(newArray)
    } else {
      setCompanyIds([ ...companyIds, value ])
      onChange([ ...companyIds, value ])
    }
  }

  return (
    <Template title={t('dictionaries__text__additional__serviceProviders')} hasSeveralValues>
      <FormGroup>
        {companiesList?.map((company) => <FormControlLabel
          key={company.id}
          control={<Checkbox
            defaultChecked
            checked={companyIds.includes(company.id)}
            onChange={() => handleChangeCheckbox(company.id)}
          />}
          label={company.name}
          sx={{ marginBottom: '12px' }}
        />)}
      </FormGroup>
    </Template>
  )
}

export default ServiceProviders
