import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import debounce from "lodash/debounce";

import Typography from "@mui/material/Typography";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
import Pagination from '@mui/material/Pagination';
import Divider from "@mui/material/Divider";

import {
  useGetCertificationCentersMutation,
  useParamsCertCentersData,
} from "../../../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../../../utils/hooks";


import CompanyCard from "../company-card";
import FilterCost from './filterCost';
import CircularProgress from "@mui/material/CircularProgress";

export const enum SORTING_OPTIONS {
    COST_DESK = 'cost_total',
    COST_ASC = '-cost_total',
}

export const enum RESULTS_COUNT {
    COUNT_10 = 10,
    COUNT_30 = 30,
    COUNT_45 = 45
}

type Props = {
    preloadCenters?: {
        results: CertificationCenter[]
        count: number
    }
    handleClose(): void
    handleComplete(): void
}

const ModalSearch = ({preloadCenters, handleClose, handleComplete} : Props ) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ centers, setCenters ] = React.useState<CertificationCenter[]>(preloadCenters?.results || []);
  const [ totalCenters, setTotalCenters ] = React.useState(preloadCenters?.results.length);

  const filterData = useParamsCertCentersData();

  const [ showProgressBar, setShowProgressBar ] = useState(true);

  const [ noFilterValues, setNoFilterValues ] = React.useState(true);

  const [ getCenters ] = useGetCertificationCentersMutation()

  useEffect(() => {
    if (preloadCenters?.results){
      setCenters(preloadCenters?.results);
      setTotalCenters(preloadCenters?.results.length);
    }
  }, [ preloadCenters?.results ])

  useEffect(() => {
    setTimeout(() => {
      setShowProgressBar(false)
    }, 1000);
  }, [ ])

  const centersRequest = (data?: OrdersParamsCertCenters) => {
    getCenters({
      orderId,
      ...filterData,
      ...data,
    }).then((data) => {
      // @ts-ignore
      setCenters(data.data.results);
      // @ts-ignore
      setTotalCenters(data.data.count);
    });
  }

  const handleChangeResultsCount = (value: string | RESULTS_COUNT ) => centersRequest({
    limit: Number(value)
  });

  const handleChangeCurrentPage = (event: React.ChangeEvent<unknown>, pageNumber: number) => centersRequest({
    page: pageNumber
  });

  const handleChangeCost = (value: number[]) => {
    centersRequest({
      cost_total_min: value[0],
      cost_total_max: value[1],
    });
    setNoFilterValues(false);
  }

  const debouncedRequestChangeCost = useRef(debounce(handleChangeCost, 500)).current

  const handleClearRequest = () => {
    centersRequest({
      cost_total_min: undefined,
      cost_total_max: undefined,
    });
    setNoFilterValues(true);
  }

  const resultsCount = filterData.limit || RESULTS_COUNT.COUNT_30;

  return (
    <div className='modal-search-container'>
      <div className='search-results'>
        {showProgressBar ? <CircularProgress/> : (
          <div className='search-results__container'>
            <div className='search-results__results'>
              <div className='search-results__sorting'>
                <FormControl sx={{width: '324px'}}>
                  <InputLabel id='sorting-select'>{t('common__text__sort')}</InputLabel>
                  <Select
                    labelId='sorting-select'
                    id='sorting-select'
                    value={filterData.order_by || SORTING_OPTIONS.COST_DESK}
                    label={t('common__text__sort')}
                    onChange={({target: {value}}) => centersRequest({
                      order_by: value
                    })}
                    className='search-results__select-sorting'>
                    <MenuItem
                      value={SORTING_OPTIONS.COST_DESK}>{t('dictionaries__text__certification__priceAscendingOrder')}</MenuItem>
                    <MenuItem
                      value={SORTING_OPTIONS.COST_ASC}>{t('dictionaries__text__certification__priceDescendingOrder')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel id='count-select'>{t('common__text__resultsCount')}</InputLabel>
                  <Select
                    labelId='count-select'
                    id='count-select'
                    value={resultsCount}
                    label={t('common__text__resultsCount')}
                    onChange={({target: {value}}) => handleChangeResultsCount(value)}
                    className='search-results__select-count'>
                    <MenuItem value={RESULTS_COUNT.COUNT_10}>10</MenuItem>
                    <MenuItem value={RESULTS_COUNT.COUNT_30}>30</MenuItem>
                    <MenuItem value={RESULTS_COUNT.COUNT_45}>45</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {Boolean(totalCenters) && <Divider sx={{marginBottom: 3}}/>}
              {!totalCenters && <>
                <div className='search-results__results'>
                  <Divider sx={{marginBottom: 3, width: '100%'}}/>
                  <Typography variant='h6'>
                    {t('actions__message__searchNotFound')}
                  </Typography>
                </div>
              </>}
              {centers.map((center, index) =>
                <CompanyCard
                  key={index}
                  center={center}
                  onClose={handleClose}
                  handleComplete={handleComplete}
                />)}
              {Number(totalCenters) > resultsCount && <Pagination
                count={Math.ceil(Number(totalCenters) / resultsCount)}
                sx={{marginTop: 3}}
                size='large'
                onChange={handleChangeCurrentPage}
              />}
            </div>
            <Card className='search-results__filters'>
              <CardContent sx={{padding: 0}}>
                <Typography variant='h6' gutterBottom sx={{padding: '16px 24px 0'}}>
                  {t('common__text__filters')}
                </Typography>
                <FilterCost onChange={debouncedRequestChangeCost} noFilterValues={noFilterValues}/>
                <Button
                  startIcon={<ClearIcon/>}
                  // type='submit'
                  variant='outlined'
                  fullWidth
                  sx={{
                    marginTop: 3,
                    marginBottom: 0,
                    marginLeft: 3,
                    maxWidth: '312px',
                  }}
                  onClick={handleClearRequest}
                >{t('actions__text__clearFilters')}</Button>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </div>
  )
}

export default ModalSearch
