import React, { useEffect } from "react";

import {useTranslation} from "react-i18next";
import ModalRemove from '../../../components/modal_remove';
import { useLocations, useLocationsById, useRemoveLocationMutation } from "../../../../../../api/dictionary";
import {NOT_FOUND_STATUS} from "../../../../../../utils/constants";


type Props = {
    isOpen: boolean
    ids: string[]
    onClose(): void
    handleLocationsRequest(params?: DictionaryParamsInput): Promise<any>
    onRemoved(isSuccess: boolean): void
}

const Remove = ({isOpen, onClose, ids, handleLocationsRequest, onRemoved}: Props) => {

  const { t } = useTranslation()

  const [ removeLocationRequest ] = useRemoveLocationMutation()

  const locationInfoByIds = ids.map((id: string) => useLocationsById(id))

  const locationsParams = useLocations()?.params;

  useEffect(() => {
  }, [ isOpen ])

  if (!locationInfoByIds.length){
    return null;
  }

  const handleRemove = async () => {

    const response = await removeLocationRequest({location_ids: ids});

    // @ts-ignore
    if (response?.error) {
      onRemoved(false);
    } else {
      onRemoved(true);
      handleLocationsRequest().then((data) => {
        if (data?.error?.status === NOT_FOUND_STATUS) {
          // @ts-ignore
          handleLocationsRequest({ page: locationsParams ? locationsParams.page - 1 : 1})
        }
      })
      onClose();
    }
  }

  return (<ModalRemove
    isOpen={isOpen}
    onClose={onClose}
    onRemoved={handleRemove}
    title={t('dictionaries__text__location__removeLocationModal')}
    mainField='id'
    areYouSureText={locationInfoByIds.length > 1 ? t('dictionaries__text__location__areYouSureToRemoveSeveral') : t('dictionaries__text__location__areYouSureToRemoveOne')}
    // @ts-ignore
    items={locationInfoByIds}
  />
  );
}

export default Remove
