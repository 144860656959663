import React, { useEffect } from "react";
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom'

import Typography from "@mui/material/Typography";
import Mole from './Mole.svg';
import Button from "@mui/material/Button";
import HomeIcon from '@mui/icons-material/Home';
import {ROUTES} from "../../../config/constants";
import {drawEyesCanvasInit, drawEyesCanvasRemove} from "../helpers";

import './not-found.scss';

const NotFound = () => {

  const { t } = useTranslation()

  const navigate = useNavigate();

  useEffect(() => {

    const leftEye = {
      x: 10,
      y: 10,
      radius: 10
    };

    const rightEye = {
      x: 40,
      y: 10,
      radius: 10
    };

    drawEyesCanvasInit(50, 25, leftEye, rightEye, 'eyesNotFound');

    return () => drawEyesCanvasRemove(50, 25, leftEye, rightEye, 'eyesNotFound');
  }, [])

  const goToTheMainPage = () => navigate(ROUTES.CABINET);

  return <div className='no-match-content'>
    <Typography variant='h4' sx={{ fontWeight: 500, marginBottom: 5 }}>
      {t('common__text__error404')}
    </Typography>
    <canvas id='eyesNotFound'></canvas>
    <img src={Mole} id='moleImage' />
    <Typography variant='body1' sx={{ marginTop: 4 }}>
      {t('common__text__notFound')}
    </Typography>
    <Button
      variant='contained'
      startIcon={<HomeIcon />}
      onClick={goToTheMainPage}
      sx={{ marginTop: 8, width: '312px' }}
    >
      {t('common__text__goToTheMainPage')}
    </Button>
  </div>
}

export default NotFound
