import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Template from '../../../../../../common/templateFilter';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useLazyGetDeliveryTimeQuery } from "../../../../../../../api/delivery_time";

type OptionData = {
    value: string,
    label: string
}

type Props = {
    onChange?(value: string): void,
    noFilterValues?: boolean,
}

const DeliveryTime = ({onChange, noFilterValues} : Props) => {

  const { t } = useTranslation()

  const [ deliveryTimeRequest, { data } ] = useLazyGetDeliveryTimeQuery()

  useEffect(() => {
    deliveryTimeRequest('')
  }, [ ])

  const options = data?.map(item => ({
    value: item.code,
    label: item.name,
  }));

  const [ value, setValue ] = React.useState('');

  useEffect(() => {
    if (noFilterValues) {
      setValue('')
    }
  }, [ noFilterValues ])

  if (!options) {
    return null;
  }

  return (
    <Template title={t('calc__text__delivery__shippingTime')}>
      <Autocomplete
        options={options}
        // loading={isLoading}
        filterOptions={(options) => options}
        noOptionsText=''
        // @ts-ignore
        value={value}
        onChange={(_, value ) => {
          // @ts-ignore
          setValue(value.label);
          // @ts-ignore
          onChange(value.value);
        }}
        isOptionEqualToValue={(o: OptionData, v: OptionData) => o.value === v.value}
        loadingText={t('common__message__loadingText')}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            className='search-results__input'
            label={t('calc__text__delivery__shippingTime')}
            // disabled={isLoading}
            InputProps={{
              ...params.InputProps,
            }}
            InputLabelProps={{ shrink: true }}
          />)}
        className='search-results__autocomplete'
      />
    </Template>
  )
}

export default DeliveryTime
