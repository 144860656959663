import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const deliveryRoutesApi = createApi({
  baseQuery,
  reducerPath: 'deliveryRoutesApi',
  endpoints: (builder) => ({
    deliveryRoutes: builder.query<any, Record<string, unknown>>({
      query: (body) => ({
        url: 'calc-api/v2.0/delivery_routes/search/',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLazyDeliveryRoutesQuery,
} = deliveryRoutesApi

