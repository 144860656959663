import React, { useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useFormContext } from 'react-hook-form'
import { DeliveryFormFields, DELIVERY_FORM } from './index'
import { useLazyGetLocationsQuery } from "../../../../../../../api/locations";
import { useTranslation } from "react-i18next"
import { COLORS } from "../../../../../../../config/constants";
import cx from 'classnames'
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { getFlag } from "../../helpers";
import {Ship} from "../../../../../../../assets/svg";

type OptionData = {
    label: string
    id: string
    text: string
    countryCode: string
}

type Props = {
    fieldName: DELIVERY_FORM
    label: string
    placeholder: string
    isBigInput?: boolean
    changeCityFrom?(id?: string): void
    changeCityTo?(id?: string): void
    defaultValue?: OptionData
}

const LocationInput = ( { fieldName, label, placeholder, isBigInput, changeCityFrom, changeCityTo, defaultValue } : Props) => {

  const { register, setValue, watch, getValues, formState: { errors } } = useFormContext<DeliveryFormFields>()

  const [ hasValue, setHasValue ] = useState<boolean>(defaultValue ? true : Boolean(getValues()[fieldName]));

  const [ cityValue ] = useState<OptionData | undefined>(defaultValue);
  const [ flagValue, setFlagValue ] = useState(defaultValue?.countryCode);

  const [ locationsRequest, { data, isLoading } ] = useLazyGetLocationsQuery()

  const debouncedRequest = useRef(debounce(locationsRequest, 1000)).current

  const locations = (data ?? [])
    .map(({ id, country, name, code, type }: LocationItem) => {
      return ({ id: id, label: `${name} ${code} ${type.toUpperCase()} (${country?.name})`,
        text: `${name} ${code} ${type.toUpperCase()} (${country?.name})`,
        countryCode: country.code });
    })

  const { t } = useTranslation()

  watch((array) => setHasValue(Boolean(array[fieldName])))

  const cityInputStart = <InputAdornment position='start'>
    <Typography variant='subtitle1' align='center' className='searchForm__adornment' sx={{ fontWeight: 900, color: hasValue ? COLORS.BLACK: COLORS.BORDER_GRAY, marginRight: '2px' }}>
      {fieldName === DELIVERY_FORM.LOCATION_FROM ? 'A' : 'B'}
    </Typography>
  </InputAdornment>

  const handleChange = (_: Event, value: OptionData, reason: string) => {
    setValue(fieldName, reason !== 'clear' ? value?.text : undefined);

    if (value) setFlagValue(value.countryCode);

    if (fieldName === DELIVERY_FORM.LOCATION_FROM) {
      changeCityFrom && changeCityFrom(reason !== 'clear' ? value.id : undefined)
    }
    if (fieldName === DELIVERY_FORM.LOCATION_TO) {
      changeCityTo && changeCityTo(reason !== 'clear' ? value.id : undefined)
    }
  }

  return (
    <div id={fieldName} className={cx({
      'searchForm__city': true,
      'searchForm__city_big': isBigInput,
    })}>
      <Autocomplete
        options={locations}
        loading={isLoading}
        noOptionsText=''
        defaultValue={cityValue}
        filterOptions={(options) => options}
        {...register(fieldName, { required: true })}
        // @ts-ignore
        onChange={handleChange}
        isOptionEqualToValue={(o: OptionData, v: OptionData) => o.id === v.id}
        loadingText={t('common__message__loadingText')}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            onChange={({ target: { value: query } }) => {
              if (query.length >= 3) debouncedRequest({q: query})
            }}
            error={Boolean(errors[fieldName])}
            variant='outlined'
            label={label}
            // disabled={isLoading}
            InputProps={{
              ...params.InputProps,
              startAdornment: <span className='searchForm__adornment-container'>{cityInputStart} {getFlag({code: flagValue, marginRight: '0px' })}</span>,
            }}
            InputLabelProps={{ shrink: true }}
          />)}/>
    </div>
  )
}

export default LocationInput
