import {useTranslation} from "react-i18next";

import {ORDER_FORM} from "../../../../cabinet/calculator/components/main/components/editMain";

export const getMainInfo = (order?: OrderParamsOutput) => {

  const { t } = useTranslation();

  if (!order){
    return []
  }

  return [
    [
      ...(order?.[ORDER_FORM.ORDER_NAME]
        ? [
          {
            label: t('calc__text__main__nameOfOrder'),
            value: order?.[ORDER_FORM.ORDER_NAME],
          },
        ]
        : []),
    ]
  ]
}
