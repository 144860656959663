import CircularProgress from '@mui/material/CircularProgress'
import './preloader.scss';

const Preloader = () => {
  return (
    <div className='preloader-container'>
      <CircularProgress size={30} color='secondary' />
    </div>
  )
}


export default Preloader
