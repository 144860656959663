import React, { ReactNode } from 'react'
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import COLORS from 'styles/colors.scss'
import Chip from '@mui/material/Chip';

import cx from "classnames";

import './completed.scss'

type CompletedParams = {
    info: {
        label: string
        value: ReactNode
        isStrong?: boolean
    }[]
    onEdit?(id?: number): void,
    onRemove?(id?: number): void,
    caption?: string
    id?: number
    hasTitle?: boolean
    twoRows?: boolean
    chip?: string
    children?: React.ReactNode
}

const Completed = (props: CompletedParams) => {
  const { info, hasTitle, onRemove, onEdit, id, twoRows, chip, children } = props;

  const rows = hasTitle? info.slice(1) : info;

  const isMain = rows.some(row => row.isStrong);

  if (!info) return <></>
  return <div className='completed'>
    {chip && <Chip label={chip} variant='outlined' color='success' className='completed__chip' />}
    <ul className={cx('list',{
      'list_chip': chip,
    })}>
      {hasTitle && (<li className='list__main'>
        <Typography variant='caption'>{info[0]?.label}</Typography>
        <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>{info[0]?.value}</Typography>
        {props.caption && <Typography variant='subtitle1' sx={{ color: `${COLORS.LIGHT_GRAY}`}}>{props.caption}</Typography>}
      </li>)}
      <ul className={cx({
        'list__main': isMain,
        'list__row': !isMain,
        'list__main_two-rows': twoRows,
      })}>
        {rows.map((item, index) => (
          <li className='list__item' key={index}>
            <Typography variant='caption'>{item.label}</Typography>
            <Typography variant='subtitle1' sx={{ fontWeight: item.isStrong ? 500 : 400, marginBottom: item.isStrong && !twoRows ? 2 : 0 }}>{item.value}</Typography>
          </li>
        ))}
      </ul>
      {children}
    </ul>
    <div className='icons'>
      {onRemove && <IconButton onClick={() => onRemove(id)} sx={{ marginTop: '-2px', width: '32px', height: '32px' }}>
        <DeleteIcon sx={{ color: COLORS.LIGHT_GRAY }}/>
      </IconButton>}
      {onEdit && <IconButton sx={{ padding: '4px',  width: '32px', height: '32px'}} onClick={() => onEdit(id)}>
        <EditIcon sx={{ color: COLORS.LIGHT_GRAY }} />
      </IconButton>}
    </div>
  </div>
}

export default Completed
