import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const mediaStoragesApi = createApi({
  baseQuery,
  reducerPath: 'mediaStorageApi',
  endpoints: (builder) => ({
    uploadImage: builder.query<any, FormData>({
      query: (body) => ({
        url: 'media_storage/images/',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLazyUploadImageQuery,
} = mediaStoragesApi

