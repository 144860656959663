import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const locationsApi = createApi({
  baseQuery,
  reducerPath: 'locationsApi',
  endpoints: (builder) => ({
    getLocations: builder.query<LocationItem[], { q?: string, id?: string, loc_type?: string }>({
      query: ({ q, id, loc_type }) => ({ url: 'dictionary/v4.0/search/locations/', params: { q, id, loc_type } }),
    }),
  }),
})

export const { useLazyGetLocationsQuery } = locationsApi
