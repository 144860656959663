import React, {useState, useEffect} from "react";

import { useForm, FormProvider } from "react-hook-form";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {
  useChangeLocationMutation,
  useLocationsById,
  useCreateLocationMutation,
} from "../../../../../../api/dictionary";
import ModalAdd from "../../../components/modal_add";
import Remove from './../remove';
import CountryInput from '../../../shared/country';
import {checkOnlyEnglishCharacters} from "../../../../../../utils/validation";
import { INITIAL_OPTION } from "../../../constants";
import TypeSelect from "../type";

import './add.scss';

type Props = {
    isOpen: boolean
    isEdit: boolean
    id?: string
    onClose(): void
    onRemoved(isSuccess: boolean): void
    onAddedSuccess(): void
    handleLocationsRequest(data?: DictionaryParamsInput): Promise<any>
}

type PropsSendData = {
    isClosed?: boolean
}

export enum MODAL_ADD_FORM {
    COUNTRY = 'country',
    NAME = 'name',
    CODE = 'code',
    TYPE = 'type',
}

export type ModalAddParamsLocations = {
    [MODAL_ADD_FORM.NAME]: string
    [MODAL_ADD_FORM.COUNTRY]: string
    [MODAL_ADD_FORM.CODE]: string
    [MODAL_ADD_FORM.TYPE]: string
}

type OptionData = {
  label: string
  id: string
}


const Add = ({isOpen, onClose, isEdit, handleLocationsRequest, id, onAddedSuccess, onRemoved }: Props) => {

  const { t } = useTranslation()

  const locationInfo = useLocationsById(id);

  const [ errorNotification, setErrorNotification ] = useState(false);

  const [ currentCountry, setCurrentCountry ] = useState<OptionData>(INITIAL_OPTION);

  const [ createLocation ] = useCreateLocationMutation()
  const [ changeLocationRequest ] = useChangeLocationMutation()

  const methods = useForm<ModalAddParamsLocations>({
    defaultValues: {
      [MODAL_ADD_FORM.NAME]: isEdit ? locationInfo?.[MODAL_ADD_FORM.NAME] : undefined,
      [MODAL_ADD_FORM.COUNTRY]: isEdit ? locationInfo?.[MODAL_ADD_FORM.COUNTRY].name : undefined,
      [MODAL_ADD_FORM.CODE]: isEdit ? locationInfo?.[MODAL_ADD_FORM.CODE] : undefined,
      [MODAL_ADD_FORM.TYPE]: isEdit ? locationInfo?.[MODAL_ADD_FORM.TYPE] : undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: {errors, isValid},
  } = methods;

  useEffect(() => {
  }, [ isOpen ])

  useEffect(() => {

    if (isEdit && locationInfo){
      setValue(MODAL_ADD_FORM.COUNTRY, locationInfo.country.name);
      setCurrentCountry({
        label: locationInfo.country.name,
        id: locationInfo.country.code
      })
    }
  }, [ isEdit ])

  const handleSendData = ({isClosed}: PropsSendData) => {

    if (!currentCountry) {
      return;
    }

    const onSubmit = async (values: LocationDictionary) => {

      let response: any;

      if (isEdit) {

        if (!id) {
          return;
        }

        // @ts-ignore
        response = await changeLocationRequest({
          name: values.name,
          // @ts-ignore
          country: currentCountry.id,
          code: values.code,
          type: values.type,
          id
        })

      } else {
        // @ts-ignore
        response = await createLocation({
          name: values.name,
          // @ts-ignore
          country: currentCountry.id,
          code: values.code,
          type: values.type,
        })
      }

      // @ts-ignore
      if (response?.error) {
        setErrorNotification(true)

        // @ts-ignore
        const errorList = Object.keys(response?.error?.data)

        errorList.map((error) => {
          // @ts-ignore
          setError(error, { type: "custom",  message: response?.error?.data[error][0]})
        })
      } else {
        onAddedSuccess();
        setErrorNotification(false);
        isClosed && await handleClose();
        reset()
        setCurrentCountry(INITIAL_OPTION)
      }

    }

    // @ts-ignore
    handleSubmit(onSubmit)()

  }

  const handleClose = () => {
    clearErrors();
    return handleLocationsRequest().then(() => {
      onClose();
      setValue(MODAL_ADD_FORM.NAME, '');
      setValue(MODAL_ADD_FORM.COUNTRY, '');
      setValue(MODAL_ADD_FORM.CODE, '');
      setValue(MODAL_ADD_FORM.TYPE, '');
    });
  }

  const handleAddLocationAndClose = () => handleSendData({isClosed: true});
  const errorEnglishCharacters = t('errors__message__englishCharactersOnly');

  return (
    <ModalAdd
      isOpen={isOpen}
      isEdit={isEdit}
      handleSendData={handleSendData}
      handleAddAndClose={handleAddLocationAndClose}
      handleClose={handleClose}
      isValid={isValid}
      title={isEdit ? t('dictionaries__text__location__editLocationModal') : t('dictionaries__text__location__addLocationModal')}
      saveButtonText={t('dictionaries__text__location__addLocationAndSaveButton')}
      removeButtonText={t('dictionaries__text__location__removeLocation')}
      errorNotification={errorNotification}
      modalRemove={(handleClose) => id && <Remove
        isOpen
        ids={[ id ]}
        onClose={handleClose}
        handleLocationsRequest={handleLocationsRequest}
        onRemoved={onRemoved}
      />}
    >
      <div className='dictionary__modal-add-container'>
        <FormProvider {...methods}>
          <TextField
            {...register(MODAL_ADD_FORM.NAME, {
              required: t('common__text__isRequired'),
            })}
            error={Boolean(errors[MODAL_ADD_FORM.NAME])}
            helperText={errors[MODAL_ADD_FORM.NAME]?.message}
            variant='outlined'
            label={t('common__text__location')}
            InputLabelProps={{shrink: true}}
            fullWidth
          />
          {isEdit && currentCountry && <CountryInput
            formFieldName={MODAL_ADD_FORM.COUNTRY}
            setCurrentCountry={setCurrentCountry}
            defaultValue={currentCountry}
          />}
          {!isEdit && <CountryInput
            formFieldName={MODAL_ADD_FORM.COUNTRY}
            setCurrentCountry={setCurrentCountry}
            defaultValue={currentCountry}
          />}
          <div className='dictionary__modal-add-container__locationType'>
            <TextField
              {...register(MODAL_ADD_FORM.CODE, {
                required: t('common__text__isRequired'),
                validate: (value: string) => checkOnlyEnglishCharacters(value, errorEnglishCharacters)
              })}
              error={Boolean(errors[MODAL_ADD_FORM.CODE])}
              helperText={errors[MODAL_ADD_FORM.CODE]?.message}
              variant='outlined'
              label={t('common__text__code')}
              InputLabelProps={{shrink: true}}
              fullWidth
              sx={{ maxWidth: '198px', marginRight: 3}}
              disabled={isEdit}
            />
            <TypeSelect />
          </div>
        </FormProvider>
      </div>
    </ModalAdd>
  );
}

export default Add
