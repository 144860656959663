import React from "react";
import {useTranslation} from "react-i18next";

import CustomsDeclaration from "./components/customs_declaration";
import Typography from "@mui/material/Typography";
import {COLORS} from "../../../../../../../config/constants";

type Props = {
  declaration: DeclarationsParamsOutput
  index: number
  setIsConfirmButtonDisable(value: boolean): void
  refetchSummary(): void
  refetchDeclaration(): void
}

const CustomsDetails = ({ declaration, index, setIsConfirmButtonDisable, refetchSummary, refetchDeclaration } : Props) => {

  const { t } = useTranslation()


  const onSave = () => {
    refetchDeclaration();
    refetchSummary();
  }

  return (
    <>
      <Typography variant='h6' className='customs__title' sx={{ marginBottom: 3 }}>
        {t('common__text__products')}&nbsp;
        <span className='customs__product-count'>{declaration.code_count}</span>
      </Typography>
      {declaration.codes.map((declarationByCode: Declaration, index) =>
        <CustomsDeclaration
          isCompleted={declaration.is_completed}
          declaration={declarationByCode}
          index={index}
          key={`${declarationByCode.code}-${declarationByCode.country_code}`}
          onSave={onSave}
          count={declaration.code_count}
          feesTotal={declaration.fees_total}
          setIsConfirmButtonDisable={setIsConfirmButtonDisable}
        />)}
    </>
  )
}

export default CustomsDetails
