import React from "react";

import Typography from "@mui/material/Typography";

import {useTranslation} from "react-i18next";

import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {COLORS} from "../../../../../../../config/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

type Props = {
  onClose(): void
}

const ordersList = [
  {
    title: 'Доставка. Заявка № DITA-123454321-001',
    link: '#',
  },
  {
    title: 'Доставка. Заявка № DITA-123454321-002',
    link: '#',
  },
  {
    title: 'Доставка. Заявка № DITA-123454321-003',
    link: '#',
  },
];

const SecondStep = ( {onClose}: Props) => {

  const [ isAgreed, setIsAgreed ] = React.useState(false);

  const { t } = useTranslation()

  return <>
    <Typography
      variant='body1'
    >
      {t('actions__message__removeAccount')}&nbsp;
      <b>«Константин Константинопольский»</b>&nbsp;
      {t('settings__message__leadToRemoveData')}
    </Typography>
    <ul className='modal-content__list'>
      {ordersList.map((order) => <li className='modal-content__link'><Link href={order.link}>{order.title}</Link></li>)}
    </ul>
    <Typography
      variant='body1'
    >
      {t('settings__message__balanceOfYourAccountProfile')}&nbsp;
      <b>USD 4045,45</b>&nbsp;
      {t('settings__message__willBeCancelled')}
    </Typography>
    <FormGroup sx={{ marginTop:4, marginBottom: 4}}>
      <FormControlLabel
        control={<Checkbox checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)}/>}
        label={<>{t('actions__text__iAccept')} <Link href='' className='auth__forget-password'>{t('actions__text__termsOrConditions')}</Link></>}
      />
    </FormGroup>
    <div className='modal-content__buttons'>
      <Button
        variant='outlined'
        onClick={onClose}
        sx={{ width: 198, marginRight: 3}}
      >{t('actions__text__cancel')}</Button>
      <Button
        variant='contained'
        sx={{ width: 418, background: COLORS.RED}}
        disabled={!isAgreed}
      ><DeleteIcon />{t('settings__message__deleteMyAccountForever')}</Button>
    </div>
  </>
}

export default SecondStep
