import React from "react";
import {useUserIsManager} from "../../../api/auth";
import ErrorBoundary from "../../additional/error-boundary";
import {Navigate, Route, Routes} from "react-router-dom";
import {DICTIONARIES_CABINET_ARRAY, ROUTES_CABINET_ARRAY, ROUTES_DEFAULT_ARRAY} from "../constants";
import {ROUTES} from "../../../config/constants";
import Cabinet from "../../cabinet";
import Dictionaries from "../../cabinet/dictionaries/pages";
import NotFound from "../../additional/not-found";
import AccessWrapper from "../access-wrapper";

const Navigator = () => {

  const userIsManager = useUserIsManager();

  return (
    <ErrorBoundary>
      <AccessWrapper>
        <Routes>
          <Route path='/'>
            {ROUTES_DEFAULT_ARRAY.map(route => <Route key={route.path} {...route} />)}
            <Route index element={<Navigate to={`${ROUTES.CABINET}`} />} />
            <Route path={ROUTES.CABINET} element={<Cabinet />}>
              {ROUTES_CABINET_ARRAY.map(route => <Route key={route.path} {...route} />)}
            </Route>
            {userIsManager && <Route path={ROUTES.DICTIONARIES} element={<Dictionaries />}>
              {DICTIONARIES_CABINET_ARRAY.map(route => <Route key={route.path} {...route} />)}
            </Route>}
            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
      </AccessWrapper>
    </ErrorBoundary>
  )
}

export default Navigator
