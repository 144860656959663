import React, { ReactNode } from 'react'
import Typography from "@mui/material/Typography";
import COLORS from 'styles/colors.scss'

import './completed.scss'
import MenuButton from "../menuButton";
import {useTranslation} from "react-i18next";
import cx from "classnames";

type CompletedParams = {
    info: {
        label: string
        value: ReactNode
    }[]
    onEdit?(id?: number): void,
    onRemove?(id?: number): void,
    caption?: ReactNode
    id?: number
    isFullRow?: boolean
    children?: React.ReactNode
    noPadding?: boolean
    noBackground?: boolean
    hasThreeRows?: boolean
    preInfo?: React.ReactNode
}

const Completed = (props: CompletedParams) => {

  const { t } = useTranslation()

  const { info, onRemove, onEdit, id, children, isFullRow, noPadding, noBackground, preInfo, hasThreeRows } = props;

  const menuOptions = [];

  if (onEdit){
    menuOptions.push({
      title: t('actions__text__edit'),
      onClick: () => onEdit(id),
    });
  }

  if (onRemove){
    menuOptions.push({
      title: t('actions__text__delete'),
      onClick: () => onRemove(id),
    });
  }

  if (!info) return <></>

  return <div className={cx('completed-new', {
    'completed-new_noPadding': noPadding,
    'completed-new_noBackground': noBackground,
  })}>
    <div className='completed-new__caption-row'>
      {props.caption && <Typography variant='subtitle1' sx={{color: `${COLORS.BLACK}`}}>{props.caption}</Typography>}
      {onEdit && <div className='completed-new__menu'>
        <MenuButton options={menuOptions}/>
      </div>}
    </div>
    {preInfo}
    {info.length > 0 && (
      <ul className={cx('completed-new__list', {
        'completed-new__list-oneRow': isFullRow,
        'completed-new__list-threeRows': hasThreeRows,
        'completed-new__list-indent': props.caption,
        'completed-new__list-small': Boolean(onEdit),
      })}>
        {info.map((item, index) => (
          <li className='completed-new__list-item' key={index}>
            <Typography variant='body2' sx={{color: COLORS.LIGHT_TEXT_SECONDARY}}>{item.label}</Typography>
            <Typography variant='body2' sx={{color: COLORS.BLACK}}>{item.value}</Typography>
          </li>
        ))}
        {children}
      </ul>
    )}
  </div>
}

export default Completed
