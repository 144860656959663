import Map, {NavigationControl, Marker, MapboxEvent}  from 'react-map-gl';
import {MAP_TOKEN} from "../../../../../../../../../../../../api/geocoding";
import './locationMap.scss'

type Props =  {
    lng: number
    lat: number
}

const LocationMap  = ({ lng, lat } : Props) => {

  const changeMapLanguage = (event: MapboxEvent) => {
    const map = event.target
    map.getStyle().layers.forEach((layer) => {
      if (layer.id.endsWith('-label')) {
        map.setLayoutProperty(layer.id, 'text-field', [
          'coalesce',
          [ 'get', 'name_ru' ],
          [ 'get', 'name' ],
        ]);
      }
    });
  };

  return (
    <div className='invoice-form__map'>
      <Map
        initialViewState={{
          zoom: 8
        }}
        longitude={lng}
        latitude={lat}
        onLoad={changeMapLanguage}
        style={{width: '100%', height: 300}}
        mapStyle='mapbox://styles/mapbox/streets-v11'
        mapboxAccessToken={MAP_TOKEN}>
        <NavigationControl/>
        <Marker longitude={lng} latitude={lat} anchor='bottom' />
      </Map>
    </div>
  )
}

export default LocationMap
