import React, {useEffect, useState} from "react";
import Accordion from "@mui/material/Accordion";
import {COLORS} from "../../../../../../../config/constants";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import {CertificationType} from "../../index";

type Props = {
  title: React.ReactNode
  value: CertificationType
  children: React.ReactNode
  isExpanded: boolean
  isActive: boolean
  handleChangeAccordion: (value: boolean) => void
}

const RadioTemplate = ({ title, value, isExpanded, handleChangeAccordion, children, isActive } : Props) => {

  useEffect(() => {
  }, [ isExpanded ])

  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    handleChangeAccordion(expanded);
    event.preventDefault()
  }

  return <Accordion
    sx={{
      boxShadow: "none",
      border: `1px solid ${isActive ? COLORS.BLUE : COLORS.SLIGHTLY_GRAY}`,
      marginBottom: 0,
      '&:before': {
        backgroundColor: 'transparent',
      },
      '&.Mui-expanded:last-of-type': {
        marginBottom: 0,
      }
    }}
    className='certification-auto__accordion'
    expanded={isExpanded}
    onChange={handleChange}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ width: 32, height: 32 }} />}
      sx={{ padding: '0 24px 0 12px' }}
    >
      <FormControlLabel
        value={value}
        control={<Radio />}
        sx={{ margin: '4px 0 8px', width: '98%' }}
        label={title} />
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '24px 24px 24px', }}>
      {children}
    </AccordionDetails>
  </Accordion>
}

export default RadioTemplate
