import React, { useEffect } from "react";

import {useTranslation} from "react-i18next";
import ModalRemove from '../../../components/modal_remove';
import {
  useIncotermById,
  useIncoterms,
  useRemoveIncotermMutation
} from "../../../../../../api/dictionary";
import {NOT_FOUND_STATUS} from "../../../../../../utils/constants";

type Props = {
    isOpen: boolean
    ids: string[]
    onClose(): void
    handleIncotermsRequest(params?: DictionaryParamsInput): Promise<any>
    onRemoved(isSuccess: boolean): void
}

const Remove = ({isOpen, onClose, ids, handleIncotermsRequest, onRemoved}: Props) => {

  const { t } = useTranslation()

  const [ removeIncotermsRequest ] = useRemoveIncotermMutation()

  const incotermsInfoByIds = ids.map((id: string) => useIncotermById(id))

  const incotermsParams = useIncoterms()?.params;

  useEffect(() => {
  }, [ isOpen ])

  if (!incotermsInfoByIds.length){
    return null;
  }

  const handleRemove = async () => {

    const response = await removeIncotermsRequest({incoterm_codes: ids});

    // @ts-ignore
    if (response?.error) {
      onRemoved(false);
    } else {
      onRemoved(true);
      handleIncotermsRequest().then((data) => {
        if (data?.error?.status === NOT_FOUND_STATUS) {
          // @ts-ignore
          handleIncotermsRequest({ page: incotermsParams ? incotermsParams.page - 1 : 1})
        }
      })
      onClose();
    }
  }

  return (<ModalRemove
    isOpen={isOpen}
    onClose={onClose}
    onRemoved={handleRemove}
    title={t('dictionaries__text__incoterms___removeIncotermsModal')}
    areYouSureText={t('dictionaries__text__incoterms___areYouSureToRemove')}
    // @ts-ignore
    items={incotermsInfoByIds}
  />
  );
}

export default Remove
