import React, { useEffect } from "react";

import {useTranslation} from "react-i18next";
import ModalRemove from '../../../components/modal_remove';
import {useCities, useCitiesById, useRemoveCityMutation} from "../../../../../../api/dictionary";
import {NOT_FOUND_STATUS} from "../../../../../../utils/constants";

type Props = {
    isOpen: boolean
    ids: string[]
    onClose(): void
    handleCitiesRequest(params?: DictionaryParamsInput): Promise<any>
    onRemoved(isSuccess: boolean): void
}

const Remove = ({isOpen, onClose, ids, handleCitiesRequest, onRemoved}: Props) => {

  const { t } = useTranslation()

  const [ removeCityRequest ] = useRemoveCityMutation()

  const cityInfoByIds = ids.map((id: string) => useCitiesById(id))

  const citiesParams = useCities()?.params;

  useEffect(() => {
  }, [ isOpen ])

  if (!cityInfoByIds.length){
    return null;
  }

  const handleRemove = async () => {

    const response = await removeCityRequest({city_ids: ids});

    // @ts-ignore
    if (response?.error) {
      onRemoved(false);
    } else {
      onRemoved(true);
      handleCitiesRequest().then((data) => {
        if (data?.error?.status === NOT_FOUND_STATUS) {
          // @ts-ignore
          handleCitiesRequest({ page: citiesParams ? citiesParams.page - 1 : 1})
        }
      })
      onClose();
    }
  }

  return (<ModalRemove
    isOpen={isOpen}
    onClose={onClose}
    onRemoved={handleRemove}
    title={t('dictionaries__text__city__removeCityModal')}
    areYouSureText={cityInfoByIds.length > 1 ? t('dictionaries__text__city__areYouSureToRemoveSeveral') : t('dictionaries__text__city__areYouSureToRemoveOne')}
    // @ts-ignore
    items={cityInfoByIds}
    additionalField='country'
  />
  );
}

export default Remove
