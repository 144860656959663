import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'
import {createSlice} from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import {informersApi} from "./informers";
import {calcApi} from "./calc";
import {useSelector} from "react-redux";
import {rootReducer} from "../store";

export const tnvedApi = createApi({
  baseQuery,
  reducerPath: 'tnvedApi',
  endpoints: (builder) => ({
    getTnved: builder.query<TnvedInfo, string>({
      query: (query) => `dictionary/alta/tnved/${query}/`,
    }),
  }),
})

export const { useLazyGetTnvedQuery } = tnvedApi

export const tnvedCodesSlice = createSlice({
  name: 'tnvedCodes',
  initialState: {
    codes: [] as TnvedInfoMap[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      tnvedApi.endpoints.getTnved.matchFulfilled,
      (state, { payload }) => {
        state.codes = [
          ...state.codes,
          { [payload.GoodInfo.Code]: payload }
        ];
      }
    )
  },
})

export const useTnvedCodes = () => {
  return useSelector(({ tnvedCodes }: ReturnType<typeof rootReducer>) => tnvedCodes.codes)
}
