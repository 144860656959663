import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const certificationSchemesApi = createApi({
  baseQuery,
  reducerPath: 'certificationSchemesApi',
  endpoints: (builder) => ({
    getCertificationSchemes: builder.query<any, string>({
      query: (query) => `dictionary/v4.0/certification_schemes/`,
    }),
  }),
})

export const { useLazyGetCertificationSchemesQuery } = certificationSchemesApi
