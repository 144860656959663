import React from "react";
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'

import { store } from 'store'
import AuthProvider from 'components/auth/auth-provider'
import {MUI_LICENSE_KEY} from 'config/constants'
import MUI_THEME from 'config/mui_theme'

import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LicenseInfo } from '@mui/x-license-pro';
import ScrollToHashElement from './components/common/scrollToHashElement';

import Navigator from './components/routes/navigator';

import './app.scss'
import './styles/common.scss'

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const App = () => {

  return (
    <ThemeProvider theme={MUI_THEME}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider store={store}>
          <AuthProvider>
            <div className='app'>
              <ScrollToHashElement />
              <Navigator />
            </div>
          </AuthProvider>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}


export default App
