import SearchForm from './../searchForm'
import React, {useEffect, useState, useRef} from "react";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FilterCost from './filterCost';
import DateArrival from './dateArrival';
import DeliveryTime from './deliveryTime';
import ShippingMethod from './shippingMethod';
import debounce from 'lodash/debounce'

import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
import RouteCard from '../card';
import Pagination from '@mui/material/Pagination';
import Divider from "@mui/material/Divider";

import './modalSearch.scss';
import {useCurrentRoute, useGetRoutesMutation, useParamsData} from "../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";

export const enum SORTING_OPTIONS {
    DELIVERY_DESK = 'shipping_time,id',
    DELIVERY_ASC = '-shipping_time,id',
}

export const enum RESULTS_COUNT {
    COUNT_10 = 10,
    COUNT_20 = 20,
    COUNT_45 = 45
}

type Props = {
  preloadRoutes?: {
    results: Route[]
    count: number
  }
  refetch(): void
  handleRemove(): void
  handleClose(): void
}

const ModalSearch = ({preloadRoutes, refetch, handleRemove, handleClose} : Props ) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ routes, setRoutes ] = React.useState<Route[]>(preloadRoutes?.results || []);
  const [ totalRoutes, setTotalRoutes ] = React.useState(preloadRoutes?.results.length);

  const filterData = useParamsData();

  const [ showProgressBar, setShowProgressBar ] = useState(true);

  const [ noFilterValues, setNoFilterValues ] = React.useState(true);

  const [ getRoutes ] = useGetRoutesMutation()

  const currentRoute = useCurrentRoute()

  const currentRouteRef: React.MutableRefObject<undefined> = useRef();

  useEffect(() => {
    if (preloadRoutes?.results){
      setRoutes(preloadRoutes?.results);
      setTotalRoutes(preloadRoutes?.results.length);
    }
  }, [ preloadRoutes?.results ])

  useEffect(() => {
    setTimeout(() => {
      setShowProgressBar(false)
    }, 1000);
  }, [ ])

  const routersRequest = (data?: DeliveryRouteParamsMain) => {
    getRoutes({
      orderId,
      ...filterData,
      ...data,
    }).then((data) => {
      // @ts-ignore
      setRoutes(data.data.results);
      // @ts-ignore
      setTotalRoutes(data.data.count);
    });
  }

  const handleChangeResultsCount = (value: string | RESULTS_COUNT ) => routersRequest({
    limit: Number(value)
  });

  const handleChangeCurrentPage = (event: React.ChangeEvent<unknown>, pageNumber: number) => routersRequest({
    page: pageNumber
  });

  const handleChangeDeliveryTime = (value: string) => {
    routersRequest({
      shipping_time: value
    });

    setNoFilterValues(false);
  }

  const handleChangeCost = (value: number[]) => {
    routersRequest({
      price_total_min: value[0],
      price_total_max: value[1],
    });
    setNoFilterValues(false);
  }

  const handleChangeDateArrival = (value: string[]) => {
    routersRequest({
      delivery_date_from: value[0],
      delivery_date_to: value[1],
    });
    setNoFilterValues(false);
  }

  const debouncedRequestChangeCost = useRef(debounce(handleChangeCost, 500)).current

  const handleSendSearch = (data: DeliveryRouteParamsMain) => routersRequest({
    ...data,
  });

  const renderCurrentRoute = () => {
    if (!currentRoute?.id) {
      return;
    }

    // @ts-ignore
    return (<div ref={currentRouteRef}>
      <Divider/>
      <Typography variant='h6' gutterBottom sx={{ marginTop: 3, marginBottom: 3}}>
        {t('calc__text__delivery__yourChoice')}
      </Typography>
      <RouteCard
        isActive
        route={currentRoute}
        refetch={refetch}
        currentRouteRef={currentRouteRef}
        handleRemove={handleRemove}
      />
      <Divider sx={{ marginTop: 3, marginBottom: 3}} />
    </div>)
  };

  const resultsCount = filterData.limit || RESULTS_COUNT.COUNT_20;

  const handleChangeShippingMethod = (value: string[]) => {
    routersRequest({
      shipping_method: value
    });
    setNoFilterValues(false);
  }

  const handleClearRequest = () => {
    routersRequest({
      shipping_time: '',
      shipping_method: undefined,
      price_total_min: undefined,
      price_total_max: undefined,
      delivery_date_from: undefined,
      delivery_date_to: undefined,
    });
    setNoFilterValues(true);
  }

  return (
    <div className='modal-search-container modal-search-container_padding'>
      <SearchForm isBigInputs onSend={handleSendSearch} />
      <div className='search-results'>
        <Typography variant='h6' gutterBottom sx={{ marginBottom: '20px' }}>
          {t('common__text__searchResults')}
        </Typography>
        {!showProgressBar && <div className='search-results__container'>
          {Boolean(totalRoutes) && <div className='search-results__results'>
            <div className='search-results__sorting'>
              <FormControl sx={{ width: '324px'}}>
                <InputLabel id='sorting-select'>{t('common__text__sort')}</InputLabel>
                <Select
                  labelId='sorting-select'
                  id='sorting-select'
                  value={filterData.order_by || SORTING_OPTIONS.DELIVERY_DESK}
                  label={t('common__text__sort')}
                  onChange={({ target: { value } }) => routersRequest({
                    order_by: value
                  })}
                  className='search-results__select-sorting'>
                  <MenuItem value={SORTING_OPTIONS.DELIVERY_DESK}>{t('common__message__sortShippingTimeAsc')}</MenuItem>
                  <MenuItem value={SORTING_OPTIONS.DELIVERY_ASC}>{t('common__message__sortShippingTimeDesc')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id='count-select'>{t('common__text__resultsCount')}</InputLabel>
                <Select
                  labelId='count-select'
                  id='count-select'
                  value={resultsCount}
                  label={t('common__text__resultsCount')}
                  onChange={({ target: { value } }) => handleChangeResultsCount(value)}
                  className='search-results__select-count'>
                  <MenuItem value={RESULTS_COUNT.COUNT_10}>10</MenuItem>
                  <MenuItem value={RESULTS_COUNT.COUNT_20}>20</MenuItem>
                  <MenuItem value={RESULTS_COUNT.COUNT_45}>45</MenuItem>
                </Select>
              </FormControl>
            </div>
            {renderCurrentRoute()}
            {routes.filter(route => route.id !== currentRoute?.id).map((route) =>
              <RouteCard
                route={route}
                key={route.id}
                refetch={refetch}
                currentRouteRef={currentRouteRef}
                handleRemove={handleRemove}
                handleClose={handleClose}
              />)}
            {Number(totalRoutes) > resultsCount && <Pagination
              count={Math.ceil(Number(totalRoutes) / resultsCount)}
              sx={{ marginTop: '48px' }}
              size='large'
              onChange={handleChangeCurrentPage}
            />}
          </div>}
          {!totalRoutes && <>
            <div className='search-results__results'>
              <Divider sx={{ marginBottom: 3, width: '100%' }} />
              <Typography variant='h6' gutterBottom sx={{ marginTop: 4}}>
                {t('actions__message__searchNotFound')}
              </Typography>
            </div>
          </>
          }
          <Card className='search-results__filters'>
            <CardContent sx={{ padding: 0 }}>
              <Typography variant='h6' gutterBottom sx={{ padding: '16px 24px 10px'}}>
                {t('common__text__filters')}
              </Typography>
              <FilterCost onChange={debouncedRequestChangeCost} noFilterValues={noFilterValues} />
              <DateArrival onChange={handleChangeDateArrival} noFilterValues={noFilterValues} />
              <DeliveryTime onChange={handleChangeDeliveryTime} noFilterValues={noFilterValues} />
              <ShippingMethod onChange={handleChangeShippingMethod}/>
              <Button
                startIcon={<ClearIcon />}
                // type='submit'
                variant='outlined'
                fullWidth
                sx={{
                  marginTop: 4,
                  marginBottom: 1,
                  marginLeft: 3,
                  maxWidth: '312px',
                }}
                onClick={handleClearRequest}
              >{t('actions__text__clearFilters')}</Button>
              {/*<Button*/}
              {/*  variant='contained'*/}
              {/*  fullWidth*/}
              {/*  type='submit'*/}
              {/*  startIcon={<CheckIcon />}*/}
              {/*  sx={{*/}
              {/*    marginTop: 1,*/}
              {/*    marginLeft: 3,*/}
              {/*    maxWidth: '312px',*/}
              {/*  }}*/}
              {/*>{t('actions__text__applyFilters')}</Button>*/}
            </CardContent>
          </Card>
        </div>}
      </div>
    </div>
  )
}

export default ModalSearch
