import React from "react";
import {useTranslation} from "react-i18next";

import Tab from "@mui/material/Tab";
import NewButton from "../new-button";
import Tabs from "@mui/material/Tabs";

import {ADD_PAYMENT_FORM} from "../../index";

import './payment-tabs.scss';

type Props = {
    tabValue: number
    fields: any[]
    append: any
    initialValues: any
    startFieldsIndex?: number
    shouldAppendItem?: boolean
    handleChange(event: React.SyntheticEvent, newValue: number): void
    getValues(s: string): any
    onClickNewButton?(): void
}

const PaymentTabs = ( {
  tabValue,
  handleChange,
  fields,
  getValues,
  append,
  initialValues,
  startFieldsIndex = 0,
  onClickNewButton,
  shouldAppendItem
} : Props ) => {

  const { t } = useTranslation()

  function a11yProps(index: number) {
    return {
      id: `bankCommission-tab-${index}`,
      'aria-controls': `bankCommission-tabpanel-${index}`,
    };
  }

  const handleClick = () => {
    if (shouldAppendItem) {
      append();
    }
    if (onClickNewButton){
      onClickNewButton();
    }
  }

  return (
    <Tabs
      value={tabValue}
      onChange={handleChange}
      sx={{ height: '60px' }}
      variant='scrollable'
      scrollButtons='auto'
    >
      {fields.map((field, index) => {

        let currentPercent = field.commission_percentage || '';

        let currentAmount = field.payment_amount || '';

        let paymentTitle = '';

        if (index >= startFieldsIndex)   {
          currentPercent = getValues(`payments.${index - startFieldsIndex}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`);
          currentAmount = getValues(`payments.${index - startFieldsIndex}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`);
        }

        paymentTitle = currentPercent && currentAmount ? `${currentPercent}%, ${currentAmount} USD` : '';

        return (
          <Tab key={index} label={<span className='payments-tab'>
            <span className='payments-tab__main'>
              {`${t('calc__text__bankCommissions__payment')}-${index + 1}`}
            </span>
            <span className='payments-tab__additional'>{paymentTitle}</span>
          </span>} {...a11yProps(index)}/>
        )
      })}
      <NewButton append={handleClick} initialValues={initialValues}/>
    </Tabs>
  );
}

export default PaymentTabs


