import React from "react";
import isEmpty from 'lodash/isEmpty'
import {FormProvider, useForm} from "react-hook-form";

import {
  useBankCommissionsByInvoiceId,
} from "../../../../../../../../../api/calc";

import NewPayments from "./components/new-payments";
import CompletedInfo from "./components/completed-info";

import './payments.scss';

type Props = {
  invoiceNumber: string
  invoiceAmount: number
  invoiceId: string
  refetch(): void
}

export enum ADD_PAYMENT_FORM {
  COMMISSION_PERCENTAGE = 'commission_percentage',
  PAYMENT_AMOUNT = 'payment_amount',
  BANK_TRANSFER_FEE = 'bank_transfer_fee',
  MIN_BANK_TRANSFER_FEE = 'min_bank_transfer_fee',
  MAX_BANK_TRANSFER_FEE = 'max_bank_transfer_fee',
  CURRENCY_CONTROL_FEE = 'currency_control_fee',
  MIN_CURRENCY_CONTROL_FEE = 'min_currency_control_fee',
  MAX_CURRENCY_CONTROL_FEE = 'max_currency_control_fee'
}

export type AddParamsPaymentForm = {
  [ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]: string
  [ADD_PAYMENT_FORM.PAYMENT_AMOUNT]: string
  [ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]: string
  [ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]: string
  [ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]: string
}

const INITIAL_VALUES_PAYMENT = {
  [ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]: '',
  [ADD_PAYMENT_FORM.PAYMENT_AMOUNT]: '',
  [ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]: '',
  [ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]: '',
  [ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]: '',
  [ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]: '',
  [ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]: '',
  [ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]: '',
};

export type PaymentsType = {
  payments: AddParamsPaymentForm[]
}

const InvoiceDetails = ({ invoiceNumber, invoiceAmount, invoiceId, refetch }: Props) => {

  const methods = useForm<PaymentsType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      payments: [ INITIAL_VALUES_PAYMENT ],
    },
  });

  const invoiceBankCommissions = useBankCommissionsByInvoiceId(invoiceNumber);

  const isCompletedInvoice = !isEmpty(invoiceBankCommissions?.banking_commissions);

  return <FormProvider {...methods}>
    <div className='payments-tabs'>
      {isCompletedInvoice && invoiceBankCommissions?.banking_commissions ? (
        <CompletedInfo
          bankCommissions={invoiceBankCommissions?.banking_commissions}
          initialValues={INITIAL_VALUES_PAYMENT}
          invoiceAmount={invoiceAmount}
          invoiceId={invoiceId}
          refetch={refetch}
          invoiceNumber={invoiceNumber}
        />
      ): (
        <NewPayments
          invoiceNumber={invoiceNumber}
          invoiceAmount={invoiceAmount}
          invoiceId={invoiceId}
          refetch={refetch}
          initialValues={INITIAL_VALUES_PAYMENT}
        />
      )}
    </div>
  </FormProvider>
}

export default InvoiceDetails
