import React, {ReactNode} from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { COLORS } from "../../../config/constants";

type Props = {
    title: string
    children: ReactNode
    hasSeveralValues?: boolean
}

const Template = ({ title, children, hasSeveralValues } : Props ) => <>
  <Divider sx={{ marginTop: 0 }} />
  <Accordion sx={{
    boxShadow: 'none',
    background: hasSeveralValues ? COLORS.LIME : COLORS.WHITE,
    margin: '0 !important'
  }} defaultExpanded>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{
        padding: '4px 24px 4px 16px',
        '&.Mui-expanded': {
          borderBottom: 'none',
        },
      }}
    >
      <Typography variant='body1' sx={{ marginLeft: 1, fontWeight: '500' }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '16px 24px 24px', }}>
      {children}
    </AccordionDetails>
  </Accordion>
</>

export default Template
