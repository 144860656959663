import React from "react";

import {useTranslation} from "react-i18next";

import ModalBig from "../../../../common/modalBig";

import './remove-account.scss';

type Props = {
    isOpen: boolean
    onClose(): void
}

import RemoveDisabled from './components/remove-disabled';
import FirstStep from './components/first-step';
import SecondStep from './components/second-step';

const ModalRemoveAccount = ( {isOpen, onClose}: Props) => {

  const { t } = useTranslation()

  return <ModalBig
    isOpen={isOpen}
    onClose={onClose}
    title={t('actions__message__removeAccount')}
  >
    <div className='modal-content'>
      <SecondStep onClose={onClose}/>
    </div>
  </ModalBig>
}

export default ModalRemoveAccount
