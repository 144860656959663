import React, { useState } from 'react'
import { useForm } from "react-hook-form"

import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputAdornment from "@mui/material/InputAdornment";

import {useTranslation} from "react-i18next";
import {useGetIdFromRoute} from "../../../utils/getIdFromRoute";
import { useUpdatePasswordMutation } from "../../../api/auth";
import Notification, {NOTIFICATION_TYPE} from "../../common/notification";

import './../auth.scss'
import Link from "@mui/material/Link";

enum RESET_PASSWORD_FORM {
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
}

type ConfirmPasswordForm = {
  password: string
  passwordConfirm: string
}

const ResetPassword = () => {

  const [ isVisiblePassword, setIsVisiblePassword ] = useState(false);
  const [ isVisiblePasswordConfirm, setIsVisiblePasswordConfirm ] = useState(false);

  const [ errorEmail, setErrorEmail ] = useState('');
  const [ isSuccess, setIsSuccess ] = useState(false);

  const token = useGetIdFromRoute()

  const { t } = useTranslation()

  const { register, getValues, trigger, handleSubmit, formState: { errors, isValid } } = useForm<ConfirmPasswordForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const validatePasswordConfirm = (value: string) => value === getValues(RESET_PASSWORD_FORM.PASSWORD) ? true: 'Пароли не совпадают'

  const [ updatePassword ] = useUpdatePasswordMutation()

  const onSubmit = handleSubmit (async (values) => {

    try {

      const response: any = await updatePassword({ token, password: values.password})

      if (response?.error) {
        throw response?.error;
      } else {
        setIsSuccess(true)
      }
    } catch (error) {
      // @ts-ignore
      setErrorEmail(error.data.detail)
    }
  })


  return (
    <form className='auth' onSubmit={onSubmit}>
      <Card>
        <CardContent className='auth__card'>
          <Typography variant='h5' gutterBottom>
            {t('actions__text__passwordReset')}
          </Typography>
          <Typography variant='body2' gutterBottom>
            {t('common__text__passwordRequirements')}
          </Typography>
          <TextField
            {...register(RESET_PASSWORD_FORM.PASSWORD, {
              required: t('common__text__isRequired'),
              minLength: { value: 6, message: t('common__text__minLength') },
            })}
            error={Boolean(errors[RESET_PASSWORD_FORM.PASSWORD])}
            helperText={errors[RESET_PASSWORD_FORM.PASSWORD]?.message}
            variant='outlined'
            label={t('common__text__newPassword')}
            type={isVisiblePassword? 'text' : 'password'}
            InputProps={{
              endAdornment: <InputAdornment position='start' onClick={() => setIsVisiblePassword(!isVisiblePassword)} className='auth__input-adornment'>{isVisiblePassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}</InputAdornment>,
            }}
            sx={{
              marginTop: '18px',
            }}
            onBlur={async () => {
              if (getValues(RESET_PASSWORD_FORM.PASSWORD_CONFIRM)) {
                await trigger([ RESET_PASSWORD_FORM.PASSWORD_CONFIRM ])
              }
            }}
          />
          <TextField
            {...register(RESET_PASSWORD_FORM.PASSWORD_CONFIRM, {
              required: t('common__text__isRequired'),
              minLength: { value: 6, message: t('common__text__minLength') },
              validate: value => validatePasswordConfirm(value)
            })}
            error={Boolean(errors[RESET_PASSWORD_FORM.PASSWORD_CONFIRM])}
            helperText={errors[RESET_PASSWORD_FORM.PASSWORD_CONFIRM]?.message}
            variant='outlined'
            label={t('common__text__confirmNewPassword')}
            type={isVisiblePasswordConfirm? 'text' : 'password'}
            InputProps={{
              endAdornment: <InputAdornment position='start' onClick={() => setIsVisiblePasswordConfirm(!isVisiblePasswordConfirm)} className='auth__input-adornment'>{isVisiblePasswordConfirm ? <VisibilityOffIcon/> : <VisibilityIcon/>}</InputAdornment>,
            }}
            sx={{
              marginTop: '22px',
            }}
          />
          <Button
            variant='contained'
            sx={{
              marginTop: '22px',
              marginBottom: '4px',
            }}
            type='submit'
            disabled={!isValid}
          >{t('actions__text__resetPassword')}</Button>
          {errorEmail && <Notification type={NOTIFICATION_TYPE.ERROR} title={errorEmail} />}
          {isSuccess && (
            <>
              <Notification type={NOTIFICATION_TYPE.SUCCESS} title={t('actions__message__updatePasswordSuccess')} sx={{
                marginTop: '22px',
              }} />
              <Typography variant='body2'>
                {t('auth__message__authNewPassword')}&nbsp;
                <Link href='/login'>Авторизоваться</Link>
                  &nbsp;{t('auth__message__withNewPassword')}
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </form>
  )
}

export default ResetPassword
