import React, {useState} from "react";

import './authorization.scss'
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TextField from '@mui/material/TextField'
import {COMPANY_FORM} from "../company";

enum AUTH_FORM {
    OLD_PASSWORD = 'old_password',
    NEW_PASSWORD = 'new_password',
    NEW_PASSWORD2 = 'new_password2',
}

type AuthForm = {
    old_password: string
    new_password: string
    new_password2: string
}

const Authorization = () => {

  const { t } = useTranslation()

  const [ isVisiblePassword, setIsVisiblePassword ] = useState(false);
  const [ showPasswordNotification, setShowPasswordNotification ] = useState(false);

  const { register, handleSubmit, getValues, trigger, formState: { errors, isValid } } = useForm<AuthForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const validatePasswordConfirm = (value: string) => value === getValues(AUTH_FORM.NEW_PASSWORD) ? true: 'Пароли не совпадают'

  return (
    <div className='settings-auth'>
      <Typography
        variant='h6'
        className='settings-auth__title'
      >
        {t('settings__text__loginPassword')}
      </Typography>
      <TextField
        {...register(AUTH_FORM.OLD_PASSWORD, {
          required: t('common__text__isRequired'),
          minLength: { value: 8, message: `8 ${t('common__text__minLength')}`},
        })}
        error={Boolean(errors[AUTH_FORM.OLD_PASSWORD])}
        variant='outlined'
        label={t('settings__text__currentPassword')}
        type={isVisiblePassword? 'text' : 'password'}
        helperText={errors[AUTH_FORM.OLD_PASSWORD]?.message}
        className={`settings-auth__${AUTH_FORM.OLD_PASSWORD}`}
        InputProps={{
          endAdornment: <InputAdornment position='start' onClick={() => setIsVisiblePassword(!isVisiblePassword)} className='auth__input-adornment'>{isVisiblePassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}</InputAdornment>,
        }}
      />

      <div className={`settings-auth__${AUTH_FORM.NEW_PASSWORD}`}>
        <TextField
          {...register(AUTH_FORM.NEW_PASSWORD, {
            required: t('common__text__isRequired'),
            minLength: { value: 8, message: `8 ${t('common__text__minLength')}`},
          })}
          error={Boolean(errors[AUTH_FORM.NEW_PASSWORD])}
          variant='outlined'
          fullWidth
          label={t('common__text__newPassword')}
          type='password'
          onBlur={async () => {
            setShowPasswordNotification(Boolean(getValues(AUTH_FORM.NEW_PASSWORD)));
            if (getValues(AUTH_FORM.NEW_PASSWORD2)) {
              await trigger([ AUTH_FORM.NEW_PASSWORD2 ])
            }
          }}
        />
        {showPasswordNotification && <Typography variant='caption' className='settings-auth__passwordRequirements'>
          {t('common__text__passwordRequirements')}
        </Typography>}
      </div>

      <TextField
        {...register(AUTH_FORM.NEW_PASSWORD2, {
          required: t('common__text__isRequired'),
          minLength: { value: 8, message: `8 ${t('common__text__minLength')}` },
          validate: value => validatePasswordConfirm(value)
        })}
        error={Boolean(errors[AUTH_FORM.NEW_PASSWORD2])}
        variant='outlined'
        label={t('common__text__newPasswordRepeat')}
        type='password'
        helperText={errors[AUTH_FORM.NEW_PASSWORD2]?.message}
        className={`settings-auth__${AUTH_FORM.NEW_PASSWORD2}`}
      />

      <Button
        className='settings-auth__button'
        variant='contained'
        fullWidth
        type='submit'
        startIcon={<CheckIcon />}
        disabled={!isValid}
      >{t('actions__text__saveNewPassword')}</Button>
    </div>
  )
}

export default Authorization
