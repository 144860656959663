import React from "react";
import {useTranslation} from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";

import {
  useBankCommissions,
  useGetBankCommissionsByOrderIdQuery,
  useInvoices, useLazyGetOrderSummaryByIdQuery, useSetCompletedByOrderIdMutation, useSummaryCompleted,
} from "../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";

import Invoice from './components/invoice';


type Props = {
  handleComplete(): void
}

const BankCommission = ({handleComplete} : Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const summaryCompleted = useSummaryCompleted()

  const bankCommissionsInvoice = useBankCommissions();

  const { refetch: fetchBankCommissions } = useGetBankCommissionsByOrderIdQuery({orderId})

  const invoices = useInvoices();

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()
  const [ getOrderSummaryRequest ] = useLazyGetOrderSummaryByIdQuery()

  if (!invoices){
    return null;
  }

  const handleClickCompleted = async () => {
    await setCompleted({
      orderId,
      banking_commissions: true,
      additional_services: true
    })
    getOrderSummaryRequest(orderId);
    handleComplete();
  }

  const shouldShowSummaryBankCommissions = bankCommissionsInvoice?.invoices.every(invoice => invoice.banking_commissions.length);

  return <>
    {invoices.map((invoice, index) => <Invoice invoice={invoice} key={invoice.id} index={index} refetch={fetchBankCommissions}/>)}
    {!summaryCompleted?.banking_commissions && shouldShowSummaryBankCommissions && <Button
      variant='contained'
      fullWidth
      startIcon={<CheckIcon />}
      onClick={handleClickCompleted}
    >{t('calc__text__bankCommissions__savePaymentAndCommissionInformation')}</Button>}
  </>
}

export default BankCommission
