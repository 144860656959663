import { createApi } from '@reduxjs/toolkit/query/react'
import {baseQuery} from 'api'
import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {rootReducer} from "../store";
import {SORTING_OPTIONS, INITIAL_DATA_REQUEST} from "../components/cabinet/dictionaries/constants";

export const dictionaryApi = createApi({
  baseQuery,
  reducerPath: 'dictionaryApi',
  endpoints: (builder) => ({
    getCurrencies: builder.query<{ results: CurrencyDictionary[], count: number }, DictionaryParamsInput>({
      query: ({ limit, page, ordering, search }) => ({ url: 'dictionary/currency/', params: { limit, page, ordering, search } }),
    }),
    createCurrency: builder.mutation<CurrencyDictionary, CurrencyDictionary>({
      query: (body) => ({ url: `dictionary/currency/`, method: 'POST', body }),
    }),
    changeCurrency: builder.mutation<CurrencyDictionary, CurrencyDictionary>({
      query: (body) => ({ url: `dictionary/currency/${body.code}/`, method: 'PATCH', body }),
    }),
    removeCurrency: builder.mutation<CurrencyDictionary, CurrencyCodesDictionary>({
      query: (body) => ({ url: `dictionary/currency/`, method: 'DELETE', body }),
    }),
    getIncoterms: builder.query<{ results: IncotermDictionary[], count: number }, DictionaryParamsInput>({
      query: ({ limit, page, ordering, search }) => ({ url: 'dictionary/incoterm/', params: { limit, page, ordering, search } }),
    }),
    createIncoterm: builder.mutation<CurrencyDictionary, CurrencyDictionary>({
      query: (body) => ({ url: `dictionary/incoterm/`, method: 'POST', body }),
    }),
    changeIncoterm: builder.mutation<CurrencyDictionary, CurrencyDictionary>({
      query: (body) => ({ url: `dictionary/incoterm/${body.code}/`, method: 'PATCH', body }),
    }),
    removeIncoterm: builder.mutation<CurrencyDictionary, IncotermCodesDictionary>({
      query: (body) => ({ url: 'dictionary/incoterm/', method: 'DELETE', body }),
    }),
    getCountry: builder.query<{ results: CountryDictionary[], count: number }, DictionaryParamsInput>({
      query: ({ limit, page, ordering, search }) => ({ url: 'dictionary/country/', params: { limit, page, ordering, search } }),
    }),
    createCountry: builder.mutation<CountryDictionary, CountryDictionary>({
      query: (body) => ({ url: `dictionary/country/`, method: 'POST', body }),
    }),
    changeCountry: builder.mutation<CountryDictionary, CountryDictionary>({
      query: (body) => ({ url: `dictionary/country/${body.code}/`, method: 'PATCH', body }),
    }),
    removeCountry: builder.mutation<CountryDictionary, CountryCodesDictionary>({
      query: (body) => ({ url: 'dictionary/country/', method: 'DELETE', body }),
    }),
    getCity: builder.query<{ results: CityDictionary[], count: number }, DictionaryParamsInput>({
      query: ({ limit, page, ordering, search }) => ({ url: 'dictionary/city/', params: { limit, page, ordering, search } }),
    }),
    createCity: builder.mutation<CityDictionary, CityDictionary>({
      query: (body) => ({ url: `dictionary/city/`, method: 'POST', body }),
    }),
    changeCity: builder.mutation<CityDictionary, CityDictionary>({
      query: (body) => ({ url: `dictionary/city/${body.id}/`, method: 'PATCH', body }),
    }),
    removeCity: builder.mutation<CountryDictionary, CityCodesDictionary>({
      query: (body) => ({ url: 'dictionary/city/', method: 'DELETE', body }),
    }),
    getLocation: builder.query<{ results: LocationDictionary[], count: number }, DictionaryParamsInput>({
      query: ({ limit, page, ordering, search, type__in }) => ({ url: 'dictionary/location/', params: { limit, page, ordering, search, type__in } }),
    }),
    createLocation: builder.mutation<LocationDictionary, LocationDictionary>({
      query: (body) => ({ url: `dictionary/location/`, method: 'POST', body }),
    }),
    changeLocation: builder.mutation<LocationDictionary, LocationDictionary>({
      query: (body) => ({ url: `dictionary/location/${body.id}/`, method: 'PATCH', body }),
    }),
    removeLocation: builder.mutation<LocationDictionary, LocationCodesDictionary>({
      query: (body) => ({ url: 'dictionary/location/', method: 'DELETE', body }),
    }),
    getLocationTypes: builder.query<LocationType[], string>({
      query: () => ({ url: 'dictionary/location-types/', method: 'GET' }),
    }),
    getTransportCompany: builder.query<{ results: TransportCompanyDictionary[], count: number }, DictionaryParamsInput>({
      query: ({ limit, page, ordering, search }) => ({ url: 'dictionary/transport-companies/', params: { limit, page, ordering, search } }),
    }),
    getTransportCompanyById: builder.query<{ results: TransportCompanyDictionary }, string>({
      query: (id) => ({ url: `dictionary/transport-companies/${id}/`}),
    }),
    createTransportCompany: builder.mutation<TransportCompanyDictionary, TransportCompanyDictionary>({
      query: (body) => ({ url: `dictionary/transport-companies/`, method: 'POST', body }),
    }),
    changeTransportCompany: builder.mutation<TransportCompanyDictionary, TransportCompanyDictionary>({
      query: (body) => ({ url: `dictionary/transport-companies/${body.id}/`, method: 'PATCH', body }),
    }),
    removeTransportCompany: builder.mutation<TransportCompanyDictionary, TransportCompanyCodesDictionary>({
      query: (body) => ({ url: 'dictionary/transport-companies/', method: 'DELETE', body }),
    }),
  }),
})

export const {
  useLazyGetCurrenciesQuery,
  useLazyGetIncotermsQuery,
  useLazyGetCountryQuery,
  useLazyGetCityQuery,
  useLazyGetLocationQuery,
  useLazyGetLocationTypesQuery,
  useLazyGetTransportCompanyQuery,
  useLazyGetTransportCompanyByIdQuery
} = dictionaryApi


export const useCreateCurrencyMutation = () => {
  return dictionaryApi.useCreateCurrencyMutation({ fixedCacheKey: 'createCurrency' })
}

export const useChangeCurrencyMutation = () => {
  return dictionaryApi.useChangeCurrencyMutation({ fixedCacheKey: 'changeCurrency' })
}

export const useRemoveCurrencyMutation = () => {
  return dictionaryApi.useRemoveCurrencyMutation({ fixedCacheKey: 'removeCurrency' })
}

export const useCreateIncotermMutation = () => {
  return dictionaryApi.useCreateIncotermMutation({ fixedCacheKey: 'createIncoterm' })
}

export const useChangeIncotermMutation = () => {
  return dictionaryApi.useChangeIncotermMutation({ fixedCacheKey: 'changeIncoterm' })
}

export const useRemoveIncotermMutation = () => {
  return dictionaryApi.useRemoveIncotermMutation({ fixedCacheKey: 'removeIncoterm' })
}

export const useCreateCountryMutation = () => {
  return dictionaryApi.useCreateCountryMutation({ fixedCacheKey: 'createCountry' })
}

export const useChangeCountryMutation = () => {
  return dictionaryApi.useChangeCountryMutation({ fixedCacheKey: 'changeCountry' })
}

export const useRemoveCountryMutation = () => {
  return dictionaryApi.useRemoveCountryMutation({ fixedCacheKey: 'removeCountry' })
}

export const useCreateCityMutation = () => {
  return dictionaryApi.useCreateCityMutation({ fixedCacheKey: 'createCity' })
}

export const useChangeCityMutation = () => {
  return dictionaryApi.useChangeCityMutation({ fixedCacheKey: 'changeCity' })
}

export const useRemoveCityMutation = () => {
  return dictionaryApi.useRemoveCityMutation({ fixedCacheKey: 'removeCity' })
}

export const useCreateLocationMutation = () => {
  return dictionaryApi.useCreateLocationMutation({ fixedCacheKey: 'createLocation' })
}

export const useChangeLocationMutation = () => {
  return dictionaryApi.useChangeLocationMutation({ fixedCacheKey: 'changeLocation' })
}

export const useRemoveLocationMutation = () => {
  return dictionaryApi.useRemoveLocationMutation({ fixedCacheKey: 'removeLocation' })
}

export const useCreateTransportCompanyMutation = () => {
  return dictionaryApi.useCreateTransportCompanyMutation({ fixedCacheKey: 'createTransportCompany' })
}

export const useChangeTransportCompanyMutation = () => {
  return dictionaryApi.useChangeTransportCompanyMutation({ fixedCacheKey: 'changeTransportCompany' })
}

export const useRemoveTransportCompanyMutation = () => {
  return dictionaryApi.useRemoveTransportCompanyMutation({ fixedCacheKey: 'removeTransportCompany' })
}

export const dictionarySlice = createSlice({
  name: 'dictionaryParams',
  initialState: {
    dictionary: {
      currencies: {
        data: {
          results: [] as CurrencyDictionary[],
          count: 0
        },
        params: {
          limit: 50,
          page: 1,
          ordering: SORTING_OPTIONS.CODE_DESC,
        } as DictionaryParamsInput,
      },
      incoterms: {
        data: {
          results: [] as IncotermDictionary[],
          count: 0
        },
        params: {
          limit: 50,
          page: 1,
          ordering: SORTING_OPTIONS.CODE_DESC,
        } as DictionaryParamsInput,
      },
      countries: {
        data: {
          results: [] as CountryDictionary[],
          count: 0
        },
        params: {
          limit: 50,
          page: 1,
          ordering: SORTING_OPTIONS.CODE_DESC,
        } as DictionaryParamsInput,
      },
      cities: {
        data: {
          results: [] as CityDictionary[],
          count: 0
        },
        params: {
          limit: 50,
          page: 1,
          ordering: SORTING_OPTIONS.CODE_DESC,
        } as DictionaryParamsInput,
      },
      locations: {
        data: {
          results: [] as LocationDictionary[],
          count: 0
        },
        params: {
          limit: 50,
          page: 1,
          ordering: SORTING_OPTIONS.CODE_DESC,
        } as DictionaryParamsInput,
      },
      locationTypes: {
        data: {
          results: [] as LocationType[],
        },
      },
      transportCompanies: {
        data: {
          results: [] as TransportCompanyDictionary[],
          count: 0
        },
        params: {
          limit: 50,
          page: 1,
          ordering: SORTING_OPTIONS.CODE_DESC,
        } as DictionaryParamsInput,
      },
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      dictionaryApi.endpoints.getCurrencies.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {
        const currencies = {
          ...state.dictionary.currencies,
          data: payload,
          params: originalArgs,
        };

        state.dictionary = {
          ...state.dictionary,
          currencies,
        };
      }
    )
    builder.addMatcher(
      dictionaryApi.endpoints.getIncoterms.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {
        const incoterms = {
          ...state.dictionary.incoterms,
          data: payload,
          params: originalArgs,
        };

        state.dictionary = {
          ...state.dictionary,
          incoterms,
        };
      }
    )
    builder.addMatcher(
      dictionaryApi.endpoints.getCountry.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {

        const countries = {
          ...state.dictionary.countries,
          data: payload,
          params: originalArgs,
        };

        state.dictionary = {
          ...state.dictionary,
          countries,
        };
      }
    )
    builder.addMatcher(
      dictionaryApi.endpoints.getCity.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {
        const cities = {
          ...state.dictionary.cities,
          data: payload,
          params: originalArgs,
        };

        state.dictionary = {
          ...state.dictionary,
          cities,
        };
      }
    )
    builder.addMatcher(
      dictionaryApi.endpoints.getLocation.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {
        const locations = {
          ...state.dictionary.locations,
          data: payload,
          params: originalArgs,
        };

        state.dictionary = {
          ...state.dictionary,
          locations,
        };
      }
    )
    builder.addMatcher(
      dictionaryApi.endpoints.getLocationTypes.matchFulfilled,
      (state, { payload }) => {
        const locationTypes = {
          ...state.dictionary.locationTypes,
          data: {results: payload},
        };

        state.dictionary = {
          ...state.dictionary,
          locationTypes,
        };
      }
    )
    builder.addMatcher(
      dictionaryApi.endpoints?.getTransportCompany.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {
        const transportCompanies = {
          ...state.dictionary.transportCompanies,
          data: payload,
          params: originalArgs,
        };

        state.dictionary = {
          ...state.dictionary,
          transportCompanies,
        };
      }
    )
  },
})

export const useCurrencies = () => {
  return useSelector(({ dictionaryParams }: ReturnType<typeof rootReducer>) => dictionaryParams.dictionary.currencies)
}

export const useCurrencyById = (id?: string) => {
  if (!id) return;
  const currencies = useCurrencies();
  return currencies?.data.results.find((item) => item.code === id);
}

export const useIncoterms = () => {
  return useSelector(({ dictionaryParams }: ReturnType<typeof rootReducer>) => dictionaryParams.dictionary.incoterms)
}

export const useIncotermById = (id?: string) => {
  if (!id) return;
  const incoterms = useIncoterms();
  return incoterms?.data.results.find((item) => item.code === id);
}

export const useCountries = () => {
  return useSelector(({ dictionaryParams }: ReturnType<typeof rootReducer>) => dictionaryParams.dictionary.countries)
}

export const useCountriesById = (id?: string) => {
  if (!id) return;
  const counties = useCountries();
  return counties?.data.results.find((item) => item.code === id);
}

export const useCities = () => {
  return useSelector(({ dictionaryParams }: ReturnType<typeof rootReducer>) => dictionaryParams.dictionary.cities)
}

export const useCitiesById = (id?: string) => {
  if (!id) return;
  const cities = useCities();
  return cities?.data.results.find((item) => item.id === id);
}

export const useLocations = () => {
  return useSelector(({ dictionaryParams }: ReturnType<typeof rootReducer>) => dictionaryParams.dictionary.locations)
}

export const useLocationsById = (id?: string) => {
  if (!id) return;
  const locations = useLocations();
  return locations?.data.results.find((item) => item.id === id);
}

export const useLocationTypes = () => {
  return useSelector(({ dictionaryParams }: ReturnType<typeof rootReducer>) => dictionaryParams.dictionary.locationTypes.data.results)
}

export const useTransportCompanies = () => {
  return useSelector(({ dictionaryParams }: ReturnType<typeof rootReducer>) => dictionaryParams.dictionary.transportCompanies)
}

export const useTransportCompaniesById = (id?: string) => {
  if (!id) return;
  const transportCompanies = useTransportCompanies();
  return transportCompanies?.data.results.find((item) => item.id === id);
}


export const useUpdateCurrencies = () => {
  return dictionaryApi.util.upsertQueryData('getCurrencies', INITIAL_DATA_REQUEST, {
    results: [],
    count: 0
  })
}

export const useUpdateIncoterms = () => {
  return dictionaryApi.util.upsertQueryData('getIncoterms', INITIAL_DATA_REQUEST, {
    results: [],
    count: 0
  })
}

export const useUpdateCountries = () => {
  return dictionaryApi.util.upsertQueryData('getCountry', INITIAL_DATA_REQUEST, {
    results: [],
    count: 0
  })
}

export const useUpdateCities = () => {
  return dictionaryApi.util.upsertQueryData('getCity', INITIAL_DATA_REQUEST, {
    results: [],
    count: 0
  })
}

export const useUpdateLocations = () => {
  return dictionaryApi.util.upsertQueryData('getLocation', INITIAL_DATA_REQUEST, {
    results: [],
    count: 0
  })
}

export const useUpdateTransportCompanies = () => {
  return dictionaryApi.util.upsertQueryData('getTransportCompany', INITIAL_DATA_REQUEST, {
    results: [],
    count: 0
  })
}
