import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const documentDurationsApi = createApi({
  baseQuery,
  reducerPath: 'documentDurationsApi',
  endpoints: (builder) => ({
    getDocumentDurations: builder.query<any, string>({
      query: (query) => `dictionary/v4.0/document_durations/`,
    }),
  }),
})

export const { useLazyGetDocumentDurationsQuery } = documentDurationsApi
