import React, {useState, useEffect} from "react";

import {useTranslation} from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import ModalBig from "../../../../common/modalBig";

import './modal_add.scss';
import Typography from "@mui/material/Typography";
import Notification, {NOTIFICATION_TYPE} from "../../../../common/notification";

type Props = {
    isOpen: boolean
    isEdit: boolean
    children: React.ReactNode
    saveButtonText?: string
    saveAndCloseButtonText?: string
    removeButtonText: string
    isValid: boolean
    title: string
    errorNotification: boolean
    noSaveAndContinueButton?: boolean
    modalRemove(handleClose: any): React.ReactNode
    handleSendData({isClosed}: PropsSendData): void
    handleClose(): void
    handleAddAndClose(): void
}

type PropsSendData = {
  isClosed?: boolean
}

const ModalAdd = ({
  isOpen,
  isEdit,
  title,
  isValid,
  children,
  handleSendData,
  saveButtonText,
  saveAndCloseButtonText,
  handleClose,
  handleAddAndClose,
  removeButtonText,
  modalRemove,
  errorNotification,
  noSaveAndContinueButton
}: Props) => {

  const { t } = useTranslation()
  const [ showModalRemove, setShowModalRemove ] = useState(false)

  useEffect(() => {
  }, [ isOpen ])

  const handleSaveAndContinue = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleSendData({})
  }

  const handleCloseRemove = () => {
    setShowModalRemove(false);
    handleClose();
  }

  return <><ModalBig
    isOpen={isOpen}
    onClose={handleClose}
    title={title}
  >
    <form className='dictionaries-addModal'>
      {children}
      {!isEdit && !noSaveAndContinueButton && <Button
        className='dictionaries-addModal__bigButton'
        variant='outlined'
        fullWidth
        type='submit'
        startIcon={<AddIcon />}
        disabled={!isValid}
        sx={{ marginTop: 4 }}
        onClick={handleSaveAndContinue}
      >{saveButtonText}</Button>}
      {errorNotification && <div className='dictionaries-addModal__notification'>
        <Notification
          type={NOTIFICATION_TYPE.ERROR}
          title={t('errors__message__save')}
        />
      </div> }
    </form>
    <div className='dictionaries-addModal__buttons'>
      <Button
        variant='outlined'
        sx={{ minWidth: '196px'}}
        onClick={handleClose}
      >{t('actions__text__cancel')}</Button>
      <Button
        variant='contained'
        sx={{ minWidth: '418px'}}
        startIcon={<CheckIcon />}
        disabled={!isValid}
        onClick={handleAddAndClose}
      >{saveAndCloseButtonText || t('actions__text__save')}</Button>
    </div>
    {isEdit && <IconButton
      size='medium'
      sx={{
        justifyContent: 'space-between',
        margin: '0 16px 32px',
        padding: '0 8px',
      }}
      onClick={() => setShowModalRemove(true)}
    >
      <DeleteIcon fontSize='inherit' />
      <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
        {removeButtonText}
      </Typography>
    </IconButton>}
  </ModalBig>
  {showModalRemove && modalRemove(handleCloseRemove)}
  </>
}

export default ModalAdd
