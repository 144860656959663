import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";
import AdditionalServices from "./components/additional_services";
import PossibleExpenses from "./components/possible_expenses";

import ModalSearch from "./components/modalSearch";
import ModalBig from "../../../../common/modalBig";

import './additional.scss';
import {
  useGetExtraServicesByOrderIdQuery,
  useGetExtraServicesSearchByOrderIdMutation
} from "../../../../../api/calc";

type Props = {
  handleComplete(): void
}

const Additional = ({handleComplete}: Props) => {

  const orderId = useGetCalcIdFromRoute()

  const { t } = useTranslation()

  const [ companies, setCompanies ] = React.useState();

  const [ shouldOpenModalSearchAddServices, setShouldOpenModalSearchAddServices ] = useState(false)

  const { data: userExtraServices, refetch: refetchGetExtraServices } = useGetExtraServicesByOrderIdQuery(orderId)

  const [ getExtraServices ] = useGetExtraServicesSearchByOrderIdMutation()

  const handleClose = () => {
    setShouldOpenModalSearchAddServices(false);
    refetchGetExtraServices();
  }

  const handleChoose = () => {
    handleClose();
    refetchGetExtraServices();
  }

  const handleSearch = async () => {
    await getExtraServices({orderId}).then((data) => {
      // @ts-ignore
      setCompanies(data?.data);
    });
    setShouldOpenModalSearchAddServices(true);
  }

  return <>
    <AdditionalServices
      handleOpenModal={handleSearch}
      refetchGetExtraServices={refetchGetExtraServices}
    />
    {/*<PossibleExpenses/>*/}
    <ModalBig
      isOpen={Boolean(shouldOpenModalSearchAddServices)}
      onClose={handleClose}
      title={t('calc__text__additional__searchForAdditionalServices')}
      isSearch
    >
      <ModalSearch
        preloadCompanies={companies}
        handleChoose={handleChoose}
        refetchGetExtraServices={refetchGetExtraServices}
      />
    </ModalBig>
  </>
}

export default Additional
