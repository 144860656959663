import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Template from '../../../../../../common/templateFilter';
import {SingleInputDateRangeField} from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import {ADORNMENTS} from "../../../../../../../config/constants";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment";

type Props = {
    onChange(value: (string | null)[]): void,
    noFilterValues?: boolean,
}

const FilterCost = ({onChange, noFilterValues}: Props) => {

  const { t } = useTranslation()

  useEffect(() => {
  }, [ noFilterValues ])

  const handleChange = (newValue: any) => {
    // @ts-ignore
    let from: string | null = newValue !== null ? moment(newValue?.[0]).format('YYYY-MM-DD') : null;
    if (from === 'Invalid date') {
      from = null
    }
    // @ts-ignore
    let to: string | null = newValue !== null ? moment(newValue?.[1]).format('YYYY-MM-DD') : null;
    if (to === 'Invalid date') {
      to = null
    }
    onChange([ from, to ])
  }

  return (
    <Template title={t('calc__text__delivery__arrivalDate')}>
      <DateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        sx={{ width: '312px'}}
        disablePast
        label={t('calc__text__delivery__arrivalDates')}
        slotProps={{
          textField: {
            InputProps: { endAdornment: ADORNMENTS.CALENDAR }
          }
        }}
        onChange={(newValue) => handleChange(newValue)}
      />
    </Template>
  )
}

export default FilterCost
