import React, { useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";

import CertificationManual from "./components/manual";
import CertificationAuto from "./components/auto";
import {
  useSummaryCustoms
} from "../../../../../api/calc";

export const enum CertificationType {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
  NONE = 'NONE'
}

type Props = {
  handleComplete(): void
}

const Certification = ({handleComplete}: Props) => {

  const [ value, setValue ] = React.useState<CertificationType>(CertificationType.NONE);
  const [ isManualExpanded, setIsManualExpanded ] = React.useState(false);
  const [ isAutoExpanded, setIsAutoExpanded ] = React.useState(false);

  const summaryCustoms = useSummaryCustoms();

  useEffect(() => {
    if (summaryCustoms?.cert_total_manual) {
      setValue(CertificationType.MANUAL);
      setIsManualExpanded(true);
    } else {
      setValue(CertificationType.AUTO);
      setIsAutoExpanded(true);
    }
  }, [ summaryCustoms ])

  const handleChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setValue((event.target as HTMLInputElement).value);
  };

  const handleChangeAccordion = (value: CertificationType, isExpanded: boolean) => {
    setValue(value);
    if (value === CertificationType.MANUAL) {
      setIsManualExpanded(isExpanded)
      setValue(CertificationType.MANUAL);
    } else if (value === CertificationType.AUTO) {
      setIsAutoExpanded(isExpanded);
      setValue(CertificationType.AUTO);
    }
  }

  return <FormControl sx={{ width: '100%' }}>
    <RadioGroup
      name='radio-buttons-certifications'
      value={value}
      onChange={handleChangeRadioButton}
    >
      <CertificationManual
        isExpanded={isManualExpanded}
        handleChangeAccordion={handleChangeAccordion}
        value={summaryCustoms?.cert_total_manual || undefined}
        handleComplete={handleComplete}
        handleChange={() => setValue(CertificationType.MANUAL)}
        isActive={value === CertificationType.MANUAL}
      />
      <CertificationAuto
        isExpanded={isAutoExpanded}
        handleChangeAccordion={handleChangeAccordion}
        value={!summaryCustoms?.cert_total_manual ? summaryCustoms?.cert_total : undefined}
        handleComplete={handleComplete}
        handleChange={() => setValue(CertificationType.AUTO)}
        isActive={value === CertificationType.AUTO}
      />
    </RadioGroup>
  </FormControl>
}

export default Certification
