import React from "react";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import './preferences.scss';
import {COLORS} from "../../../../../../../config/constants";
import {DECLARATION_FORM, DeclarationFormFields} from "../customsDetails/components/customs_declaration/components/add_form";
import {useFormContext} from "react-hook-form";

type PreferencesCheckboxProps = {
  defaultChecked: boolean
  handleChangeCheckbox(event: React.ChangeEvent, checked: boolean): void
}


const PreferencesCheckbox = ({handleChangeCheckbox, defaultChecked}: PreferencesCheckboxProps) => {

  const { register } = useFormContext<DeclarationFormFields>()

  const { t } = useTranslation()

  return <FormControlLabel
    {...register(DECLARATION_FORM.HAS_PREFERENCES)}
    control={<Checkbox defaultChecked={defaultChecked} onChange={(event: React.ChangeEvent, checked) => handleChangeCheckbox(event, checked)}/>}
    label={t('calc__text__customs__confirmPreferences')}
  />
}

const PreferencesText = () => {

  const { t } = useTranslation()

  return (
    <Typography variant='body2' sx={{ color: COLORS.BLACK, marginBottom: 2 }}>
      {`${t('calc__text__customs__areYouConfirmPreferences')}:`}
      <ul className='preferences__list'>
        <li>
          {t('calc__text__customs__theProductIsFullyManufacturedInTheSpecifiedCountryOfOrigin')}
        </li>
        <li>
          {t('calc__text__customs__directPurchase')}
        </li>
        <li>
          {t('calc__text__customs__directDelivery')}
        </li>
      </ul>
    </Typography>
  )
}

type PreferencesProps = {
  code: string
  defaultChecked: boolean
  handleChangeCheckbox(event: React.ChangeEvent, checked: boolean): void
}

const Preferences = ({code, handleChangeCheckbox, defaultChecked}: PreferencesProps) => {

  const { t } = useTranslation()

  return <div className='preferences'>
    <Typography variant='subtitle1' sx={{ fontWeight: 500, marginBottom: 2 }}>
      {`${t('calc__text__customs__preferencesForTheHSCode')} ${code}`}
    </Typography>
    <PreferencesText />
    <PreferencesCheckbox handleChangeCheckbox={handleChangeCheckbox} defaultChecked={defaultChecked} />
  </div>
}

export default Preferences
