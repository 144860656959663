import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import {useFormContext} from "react-hook-form";
import {useDispatch} from "react-redux";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

import Notification, {NOTIFICATION_TYPE} from "../../../../../../../../../../../../common/notification";
import { getProductById } from "../../../../../../../../helpers";
import {useInvoices, useUpdateProductById} from "../../../../../../../../../../../../../api/calc";
import ModalBig from "../../../../../../../../../../../../common/modalBig";
import Completed from "../../../../../../../../../../../../completed";

import {PACKAGE_FORM, PackageForm} from '../add_form/helpers';
import ExciseFields from "../excise_fields";

type Props = {
  data?: Excise[]
  id?: number
  invoiceId?: number
  insideModal?: boolean
}

const Excise = ({data, id, invoiceId, insideModal} : Props) => {

  const { t } = useTranslation()

  const { getValues } = useFormContext<PackageForm>()

  const invoices = useInvoices();

  const product = getProductById(invoices, invoiceId, id);

  const { getValues: getValuesPackageForm } = useFormContext<PackageForm>()

  const [ isCompletedAdditional, setIsCompletedAdditional ] = useState(Boolean(product?.excise_value) || Boolean(getValuesPackageForm(PACKAGE_FORM.EXCISE_VALUE) || product?.excise_value === 0));

  const [ openModalAddFields, setOpenModalAddFields ] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    setIsCompletedAdditional( Boolean(product?.excise_value) || Boolean(getValuesPackageForm(PACKAGE_FORM.EXCISE_VALUE)) || product?.excise_value === 0);
  }, [ product, getValuesPackageForm(PACKAGE_FORM.EXCISE_VALUE) ])

  const { setValue: setValuePackageForm } = useFormContext<PackageForm>()

  const getInfo = () => {
    if (!product && !getValuesPackageForm(PACKAGE_FORM.EXCISE_VALUE)) {
      return []
    }

    if (product) {
      return [
        {
          label: t('common__text__chosenValue'),
          value: `${product.excise_rate?.value} ${product.excise_rate?.description}. ${product.excise_rate?.document || ""}`,
          isStrong: true,
        },
        {
          label: t('calc__text__packaging__countsAndUnits'),
          value: `${product.excise_value} ${product.excise_rate?.unit || ""}`,
        },
      ]
    }

    return [
      {
        label: t('common__text__chosenValue'),
        // @ts-ignore
        value: `${getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.Value || getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.value} ${getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.Condition || getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.description}. ${getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.Document || getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.document || ""}`,
        isStrong: true,
      },
      {
        label: t('calc__text__packaging__countsAndUnits'),
        // @ts-ignore
        value: `${getValuesPackageForm(PACKAGE_FORM.EXCISE_VALUE)} ${getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.Measure?.Razm || getValuesPackageForm(PACKAGE_FORM.EXCISE_RATE)?.unit}`,
      },
    ]
  };

  const handleRemove = () => {

    const data = {
      ...product,
      [PACKAGE_FORM.EXCISE_VALUE]: "",
      [PACKAGE_FORM.EXCISE_RATE]: {}
    };

    // @ts-ignore
    dispatch(useUpdateProductById(data));
    setValuePackageForm(PACKAGE_FORM.EXCISE_VALUE, undefined);
    setValuePackageForm(PACKAGE_FORM.EXCISE_RATE, undefined);
    setIsCompletedAdditional(false);
  }

  return (
    <>
      {isCompletedAdditional ? (<div className='add-product__notification'>
        <Completed info={getInfo()} onEdit={() => setOpenModalAddFields(true)} hasTitle twoRows onRemove={handleRemove} chip={t('calc__text__packaging__addFieldsHsCode')} />
      </div>) : (<>
        <div className='add-product__notification'>
          <Notification type={NOTIFICATION_TYPE.WARNING} title={t('common__message__warning')} text={t('actions__message__fillAdditionalFieldsForHsCode')}/>
          <Button
            variant='contained'
            className='add-product__addFieldButton'
            fullWidth
            startIcon={<EditIcon />}
            onClick={() => setOpenModalAddFields(true)}
          >{t('actions__text__fillAdditionalFields')}</Button>
        </div>
      </>
      )}
      {data && <ModalBig
        isOpen={openModalAddFields}
        onClose={() => setOpenModalAddFields(false)}
        title={`${t('calc__text__packaging__addFieldsHsCode')} ${getValues(PACKAGE_FORM.CODE)}`}
        insideModal={insideModal}
      >
        <ExciseFields onClose={() => setOpenModalAddFields(false)} exciselist={data} product={product} />
      </ModalBig>}
    </>
  )
}

export default Excise
