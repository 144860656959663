import React from "react";

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from "react-i18next";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import {COLORS} from "../../../config/constants";
import LanguageIcon from '@mui/icons-material/Language';
import UserMenu from './components/userMenu';
import LanguageMenu from './components/languageMenu';

import './header.scss'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '144px',
      '&:focus': {
        width: '256px',
      },
    },
  },
}));

const Header = () => {

  const { t } = useTranslation()

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const [ languageEl, setLanguageEl ] = React.useState<null | HTMLElement>(null);

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseLanguageMenu = () => {
    setLanguageEl(null);
  };

  return (
    <AppBar position='static' className='app-bar' sx={{
      background: COLORS.BLACK,
      height: 64
    }}>
      <Toolbar className='app-bar__toolbar'>
        <Typography
          variant='h6'
          gutterBottom
          className='app-bar__logo'
          sx={{
            color: COLORS.WHITE,
            marginBottom: 0,
          }}
        >
          {t('common__text__projectName')}
        </Typography>
        <div className='app-bar__rightSide'>
          <Search>
            <SearchIconWrapper>
              <SearchIcon/>
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={t('common__message__search')}
              inputProps={{'aria-label': 'search'}}/>
          </Search>
          <Badge color='primary' sx={{ margin: '0 12px' }}>
            <NotificationsIcon/>
          </Badge>
          <IconButton
            size='large'
            aria-label='choose language'
            aria-controls='menu-language'
            aria-haspopup='true'
            color='inherit'
            onClick={handleLanguageMenu}
          >
            <LanguageIcon sx={{ color: languageEl ? '#42A5F5' : 'inherit'}}/>
          </IconButton>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            color='inherit'
            onClick={handleUserMenu}
          >
            <AccountCircle sx={{ color: anchorEl ? '#42A5F5' : 'inherit'}}/>
          </IconButton>
          <UserMenu anchorEl={anchorEl} handleCloseMenu={handleCloseUserMenu}/>
          <LanguageMenu anchorEl={languageEl} handleCloseMenu={handleCloseLanguageMenu}/>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
