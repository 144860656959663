import React from "react";

import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

import {COLORS} from "../../../../../../../config/constants";

import InvoiceTitle from "./components/invoiceTitle";
import InvoiceDetails from "./components/invoiceDetails";

import './invoice.scss';

type Props = {
  openByDefault?: boolean
  invoice?: InvoiceParamsOutput
  index: number
  onCloseInvoiceForm?(): void
}

const Invoice = ({openByDefault, invoice, index, onCloseInvoiceForm} : Props) => (
  <div className='invoice'>
    <Accordion sx={{
      marginBottom: 0,
      border: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
      boxShadow: 'none',
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        sx={{padding: '10px 28px 12px'}}
      >
        <InvoiceTitle invoice={invoice} index={index}/>
      </AccordionSummary>
      <AccordionDetails sx={{padding: 0}} className='invoice__details'>
        <InvoiceDetails openByDefault={openByDefault} invoice={invoice} onCloseInvoiceForm={onCloseInvoiceForm}/>
      </AccordionDetails>
    </Accordion>
  </div>
)

export default Invoice
