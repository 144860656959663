export const checkDecimal = (value: number, error: string) => value !== 0 && value % 1 === 0 ? true : error;

export const checkDecimalOrZero = (value: number, error: string) => value % 1 === 0 ? true : error;

export const checkTwoDecimalsAfterDot = (value: number | undefined, error: string) => {

  if (value === null || value === undefined) {
    return error;
  }

  const valueStr = value.toString();

  const validChars = /^[0-9]*\.?[0-9]*$/;
  if (!validChars.test(valueStr)) {
    return error;
  }

  if ((valueStr.match(/\./g) || []).length > 1) {
    return error;
  }

  if (valueStr.endsWith('.')) {
    return true;
  }

  const regex = /^\d+(\.\d{1,2})?$/;
  return regex.test(valueStr) ? true : error;

}

export const checkFloat = (value: number | undefined, error: string) => {
  if (!value) {
    return error
  }
  return /^(\d+\.)?\d+$/.test(value.toString()) ? true : error
}

type PluralForms = [string, string, string];

export const getPluralForm = (count: number, titles: PluralForms): string => {
  const cases = [ 2, 0, 1, 1, 1, 2 ];
  return (
    titles[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]] ??
        titles[2]
  );
};

const ENGLISH_SYMBOLS = /^[A-Za-z\-\s]*$/;

export const checkOnlyEnglishCharacters = (value: string, error: string) => ENGLISH_SYMBOLS.test(value) ? true : error

const RUSSIAN_SYMBOLS = /^[А-яа-яЁё\-\s]*$/;

export const checkOnlyRussianCharacters = (value: string, error: string) => RUSSIAN_SYMBOLS.test(value) ? true : error

export const EMAIL_PATTERN = /^[\w-.]+@([\w-.])+[\w-]{2,4}$/g;

export const checkEmail = (value: string, error: string) => EMAIL_PATTERN.test(value) ? true : error

export const RUSSIA_COUNTRY_NAME = 'Russia'
export const RUSSIA_PHONE_NUMBER_REGEXP = /7\d{10}/g
