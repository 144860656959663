import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CountriesTable from "./table";
import {useCountries, useLazyGetCountryQuery, useUpdateCountries} from "../../../../../api/dictionary";
import SearchField from "../../components/search_field";
import CountriesActions from "./actions";
import Header from './../../components/header';
import Preloader from "../../../../common/preloader";

import './countries.scss';
import {useDispatch} from "react-redux";
import {INITIAL_DATA_REQUEST} from "../../constants";

const Countries = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [ currentCountry, setCurrentCountry ] = useState<string[]>([])

  const [ showModalRemoveByDefault, setShowModalRemoveByDefault ] = useState(false)
  const [ showModalAddByDefault, setShowModalAddByDefault ] = useState(false)

  const [ hasLoadedFirstRequest, setHasLoadedFirstRequest ] = useState(false)

  const [ countryRequest, { isLoading: isLoadingCountries } ] = useLazyGetCountryQuery()

  const countriesParams = useCountries()?.params;

  const countriesRequestUpdate = (params?: DictionaryParamsInput) =>
    countryRequest({...countriesParams, ...params});

  useEffect(() => {
    countryRequest(INITIAL_DATA_REQUEST).then(() => {
      setHasLoadedFirstRequest(true)
    })
  }, [ ])

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(useUpdateCountries());
    };
  }, []);

  const countriesRequestSearch = (query: string) => countryRequest({
    ...countriesParams,
    search: query,
  })

  const handleEdit = () => {
    setShowModalAddByDefault(true);
  }

  const handleOpenRemoveModal = () => setShowModalRemoveByDefault(true)

  const handleCloseModal = () => {
    setCurrentCountry([]);
    setShowModalRemoveByDefault(false);
    setShowModalAddByDefault(false);
  }

  const isLoadingStatus = isLoadingCountries && !hasLoadedFirstRequest;

  return (
    <>
      <div>
        <Header menuText={t('menu__text__countries')}/>
        {isLoadingStatus ? <Preloader/> : <div className='countries-container'>
          <Card>
            <CardContent sx={{ padding: 0, '&:last-child' : { paddingBottom: 0 } }}>
              <div className='countries-container__search-container'>
                <SearchField onSearch={countriesRequestSearch}  />
              </div>
              <CountriesTable
                handleEdit={handleEdit}
                handleRemove={handleOpenRemoveModal}
                handleCountriesRequest={countriesRequestUpdate}
                changeCountry={setCurrentCountry}
                currentCountry={currentCountry}
              />
            </CardContent>
          </Card>
        </div>}
      </div>
      {!isLoadingStatus && (
        <div>
          <CountriesActions
            countriesRequestUpdate={countriesRequestUpdate}
            isOpenRemoveByDefault={showModalRemoveByDefault}
            isOpenAddByDefault={showModalAddByDefault}
            currentCountry={currentCountry}
            onCloseModal={handleCloseModal}
          />
        </div>
      )}
    </>
  )
};

export default Countries
