import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import Slider from '@mui/material/Slider';
import TextField from "@mui/material/TextField";

import Template from '../../../../../../common/templateFilter';
import { ADORNMENTS } from "../../../../../../../config/constants";

type Props = {
    onChange(value: number[]): void
    noFilterValues?: boolean
    searchParams?: SearchMeta
}

const FilterCost = ({onChange, noFilterValues, searchParams}: Props) => {

  const { t } = useTranslation()

  const [ cost, setCost ] = React.useState<number[]>([ 0, 1000 ]);

  useEffect(() => {
    if (noFilterValues) {
      setCost([ searchParams?.min_cost || 0, searchParams?.max_cost || 1000 ])
    }
  }, [ noFilterValues ])

  const handleChangePrice = (value: number[]) => {
    setCost(value);
    onChange(value);
  }

  return (
    <Template title={t('dictionaries__text__certification__costOfServices')} hasSeveralValues>
      <Slider
        value={cost}
        onChange={(event: Event, newValue: number | number[]) => handleChangePrice(newValue as number[])}
        valueLabelDisplay='auto'
        min={searchParams?.min_cost || 0}
        max={searchParams?.max_cost || 1000}
        aria-labelledby='input-slider'
      />
      <div className='search-results__slider-data'>
        <Typography variant='body2'>
          {`USD ${cost[0]}`}
        </Typography>
        <Typography variant='body2'>
          {`USD ${cost[1]}`}
        </Typography>
      </div>
      <TextField
        value={cost[0]}
        onChange={({ target: { value } }) => Number(value) > 0 ? handleChangePrice([ Number(value), cost[1] ]) : undefined}
        type='number'
        variant='outlined'
        label={t('calc__text__priceFrom')}
        fullWidth
        InputProps={{
          'aria-labelledby': 'input-slider',
          endAdornment: ADORNMENTS.PRICE,
        }}
        InputLabelProps={{ shrink: true }}
        className='search-results__input'
        sx={{ marginTop: 3}}
      />
      <TextField
        value={cost[1]}
        onChange={({ target: { value } }) => Number(value) > 0 ? handleChangePrice([ cost[0], Number(value) ]) : undefined}
        type='number'
        variant='outlined'
        label={t('calc__text__priceTo')}
        fullWidth
        InputProps={{
          'aria-labelledby': 'input-slider',
          endAdornment: ADORNMENTS.PRICE,
        }}
        InputLabelProps={{ shrink: true }}
        className='search-results__input'
        sx={{ marginTop: 3}}
      />
    </Template>
  )
}

export default FilterCost
