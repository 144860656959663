import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

import {COLORS} from "../../../../../../../config/constants";
import { PersonFilled } from "../../../../../../../assets/svg";
import CompanyCard from "../company-card";
import {
  useGetBrokerServicesSearchByOrderIdMutation,
  useGetUserBrokerServicesByOrderIdQuery,
  useRemoveBrokerServicesByOrderIdMutation,
} from "../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import ModalSearch from "../modalSearch";
import ModalBig from "../../../../../../common/modalBig";
import DeleteIcon from "@mui/icons-material/Delete";

const BrokerServices = () => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ hasCompleted, setHasCompleted ] = React.useState(false);

  const { data: userBrokerService, refetch: refetchGetUserBrokerServices } = useGetUserBrokerServicesByOrderIdQuery(orderId)

  const [ getBrokerServices ] = useGetBrokerServicesSearchByOrderIdMutation()
  const [ removeBrokerServices ] = useRemoveBrokerServicesByOrderIdMutation()

  const [ companies, setCompanies ] = React.useState();
  const [ shouldOpenModal, setShouldOpenModal ] = useState(false)

  useEffect(() => {
    setHasCompleted(Boolean(userBrokerService));
  }, [ userBrokerService ])

  const handleOpenModal = () => setShouldOpenModal(true)

  const handleCloseModal = () => setShouldOpenModal(false)

  const handleChoose = () => {
    refetchGetUserBrokerServices();
    handleCloseModal();
  }

  const handleSearch = async () => {
    await getBrokerServices({orderId}).then((data) => {
      // @ts-ignore
      setCompanies(data?.data);
    });
    handleOpenModal();
  }

  const handleRemove = async () => {
    await removeBrokerServices(orderId);
    setHasCompleted(false);
  }

  return (
    <>
      <div className='customs'>
        <Accordion sx={{
          marginBottom: 0,
          border: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
          boxShadow: 'none',
        }}
        defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            sx={{padding: '10px 28px 12px'}}
          >
            <div className='customs-title'>
              <div className='customs-title__left'>
                <PersonFilled color={COLORS.SLIGHTLY_GRAY}/>
                <Typography variant='h6' className='customs-title__name'>
                  {t('calc__text__customs__brokerServices')}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className='customs__details'>
            {hasCompleted && userBrokerService ? <>
              <Typography variant='h6' className='customs-title__name' sx={{marginBottom: 3}}>
                {t('calc__text__delivery__yourChoice')}
              </Typography>
              <CompanyCard isActive company={userBrokerService}/>
              <Button
                variant='outlined'
                startIcon={<SearchIcon/>}
                fullWidth
                onClick={handleSearch}
              >
                {t('actions__message__showOtherOptions')}
              </Button>
            </> : <Button
              variant='contained'
              startIcon={<SearchIcon/>}
              fullWidth
              onClick={handleSearch}
            >
              {t('calc__text__customs__findOutTheCostOfCustomsBrokerServices')}
            </Button>}
            {hasCompleted && <Button
              variant='contained'
              fullWidth
              startIcon={<DeleteIcon/>}
              onClick={handleRemove}
              sx={{ marginTop: 2 }}
            >{t('calc__text__customs__removeTheCustomsBroker')}</Button>}
          </AccordionDetails>
        </Accordion>
      </div>
      <ModalBig
        isOpen={Boolean(shouldOpenModal)}
        onClose={handleCloseModal}
        title={t('calc__text__customs__searchForBrokerageServices')}
        isSearch
      >
        <ModalSearch
          handleChoose={handleChoose}
          preloadCompanies={companies}
        />
      </ModalBig>
    </>
  )
}

export default BrokerServices
