import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';

import Add from "./../add";
import Remove from "./../remove";
import AlertBottom from "../../../../../common/alertBottom";

type Props = {
  isOpenRemoveByDefault?: boolean
  isOpenAddByDefault?: boolean
  currentCountry: string[]
  countriesRequestUpdate(data?: DictionaryParamsInput): Promise<any>
  onCloseModal(): void
}

const CountriesActions = ({
  countriesRequestUpdate,
  isOpenRemoveByDefault,
  isOpenAddByDefault,
  currentCountry,
  onCloseModal
} : Props) => {

  const [ showModalAdd, setShowModalAdd ] = useState(false)
  const [ modalEditMode, setModalEditMode ] = useState(false)
  const [ showModalRemove, setShowModalRemove ] = useState(false)

  const [ openAlertAdded, setOpenAlertAdded ] = useState(false);
  const [ openAlertRemoveNotification, setOpenAlertRemoveNotification ] = useState(false);
  const [ hasSuccessRemove, setHasSuccessRemove ] = useState<boolean>();

  const { t } = useTranslation()

  useEffect(() => {
    if (isOpenAddByDefault){
      setShowModalAdd(true);
      setModalEditMode(true);
    }

    isOpenRemoveByDefault && setShowModalRemove(true);

  }, [ isOpenAddByDefault, isOpenRemoveByDefault, currentCountry ])

  const handleCloseAdd = () => {
    setShowModalAdd(false);
    onCloseModal();
    setTimeout(() => {
      setModalEditMode(false);
    }, 300);
  }

  const handleCloseRemove = () => {
    onCloseModal();
    setShowModalRemove(false);
  }

  const handleOnRemoved = (isSuccess: boolean) => {
    setOpenAlertRemoveNotification(true);
    setHasSuccessRemove(isSuccess);
  }

  return (
    <>
      <Button
        variant='contained'
        type='submit'
        sx={{ width: '360px', marginLeft: 4, marginTop: 6 }}
        startIcon={<AddIcon />}
        onClick={() => setShowModalAdd(true)}
      >{t('common__text__add')}</Button>
      {showModalAdd && <Add
        isOpen={modalEditMode ? Boolean(showModalAdd && currentCountry) : showModalAdd}
        onClose={handleCloseAdd}
        onAddedSuccess={() => setOpenAlertAdded(true)}
        isEdit={modalEditMode}
        id={currentCountry[0]}
        handleCountriesRequest={countriesRequestUpdate}
        onRemoved={handleOnRemoved}
      />}
      {showModalRemove &&
                <Remove
                  isOpen={showModalRemove}
                  onClose={handleCloseRemove}
                  ids={currentCountry}
                  handleCountriesRequest={countriesRequestUpdate}
                  onRemoved={handleOnRemoved}
                />}
      <AlertBottom
        open={openAlertAdded}
        onClose={() => setOpenAlertAdded(false)}
        message={t('actions__message__countryWasAddedSuccessfully')}
      />
      <AlertBottom
        isError={!hasSuccessRemove}
        open={openAlertRemoveNotification}
        onClose={() => setOpenAlertRemoveNotification(false)}
        message={hasSuccessRemove ? t('actions__message__countryWasRemovedSuccessfully') : t('errors__message__tryLater5Min')}
      />
    </>
  )
};

export default CountriesActions
