import { useForm } from "react-hook-form";

import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import './../auth.scss'
import {useTranslation} from "react-i18next";
import { useResetPasswordMutation } from "../../../api/auth";
import Notification, {NOTIFICATION_TYPE} from "../../common/notification";
import React, {useState} from "react";

enum FORGOT_PASSWORD_FORM {
  EMAIL = 'email',
}

type ForgotPasswordForm = {
  email: string
}

const ForgetPassword = () => {

  const { t } = useTranslation()

  const [ errorEmail, setErrorEmail ] = useState('');
  const [ isSuccess, setIsSuccess ] = useState(false);

  const { register, handleSubmit, formState: { errors, isValid } } = useForm<ForgotPasswordForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const [ resetPassword ] = useResetPasswordMutation()

  const onSubmit = handleSubmit (async (values) => {

    try {

      const response: any = await resetPassword({ email: values.email})

      if (response?.error) {
        throw response?.error;
      } else {
        setIsSuccess(true)
      }
    } catch (error) {
      // @ts-ignore
      setErrorEmail(error.data.email[0])
    }
  })

  return (
    <form className='auth' onSubmit={onSubmit}>
      <Card>
        <CardContent className='auth__card'>
          <Typography variant='h5' gutterBottom>
            {t('actions__message__forgotPassword')}
          </Typography>
          <Typography variant='body2' gutterBottom>
            {t('actions__message__linkNewPassword')}
          </Typography>
          <TextField
            {...register(FORGOT_PASSWORD_FORM.EMAIL, { required: 'Поля обязательно для заполнения', pattern: { value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: 'Некорректный e-mail' } })}
            error={Boolean(errors[FORGOT_PASSWORD_FORM.EMAIL])}
            helperText={errors[FORGOT_PASSWORD_FORM.EMAIL]?.message}
            variant='outlined'
            label={t('common__text__email')}
            sx={{
              marginTop: '16px',
            }}
          />
          <Button
            variant='contained'
            sx={{
              marginTop: '20px',
              marginBottom: '4px',
            }}
            disabled={!isValid}
            type='submit'

          >{t('actions__message__resetLink')}</Button>
          {errorEmail && <Notification type={NOTIFICATION_TYPE.ERROR} title={errorEmail} />}
          {isSuccess && <Notification type={NOTIFICATION_TYPE.SUCCESS} title={t('actions__text__passwordResetSuccess')} />}
        </CardContent>
      </Card>
    </form>
  )
}

export default ForgetPassword
